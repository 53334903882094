import { useResendVerificationMail } from 'services/auth-services'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from 'redux/authentication'
import { Button, Alert } from 'antd'
import utils from 'utils'
import { useHistory } from 'react-router-dom'

function Banners() {
  const history = useHistory()
  const { user, isOwner } = useSelector((state) => state.authentication)
  const { isSubscribed, isTrailActive } = useSelector((state) => state.payments)

  const { mutate, isLoading } = useResendVerificationMail()
  const { email, timezoneHint } = user
  const dispatch = useDispatch()

  const onChangeTimezone = () => {
    // changeTimezone(_id);
  }

  // todo have api
  const resendEmail = () => {
    mutate({ email })
  }

  const onKeyDown = (event) => {
    if (event.keycode === 13) {
      resendEmail()
    }
  }

  const banners = [
    {
      id: 0,
      message: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '0px',
            fontSize: '16px',
          }}
        >
          <p style={{ fontWeight: '400', color: '#203251', margin: '0px' }}>
            Your email is not verified. Get onboard by completing the steps.
          </p>
          <div
            style={{
              color: '#2E9B72',
              border: 'none',
              background: 'transparent',
              fontWeight: 500,
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            size="small"
            role="button"
            loading={isLoading}
            onClick={resendEmail}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            Send verification link
          </div>
        </div>
      ),
      show: !user.emailVerifiedAt,
      type: 'warning',
    },
    {
      id: 4,
      message: (
        <div style={{ textAlign: 'center', color: 'white' }}>
          {/* <strong> */}
          <i>
            Dialworks wishes you and your team a very Happy New Year 🎉. Our
            agents won&apos;t be available on 30 December for taking the calls.
            We&apos;ll back as usual on 2nd Jan,2023
          </i>
          {/* </strong> */}
        </div>
      ),
      show:
        utils.isHoliday() &&
        history.location.pathname.includes('/app/training/call'),
      type: 'info',
      style: {
        margin: '-25px -25px 25px',
        border: 'none',
        height: '47px',
        background: '#2e9b72',
        borderRadius: '0px',
      },
    },
    {
      id: 1,
      message:
        'Our agents are available between 9AM EST to 6PM EST on weekdays. Please try calling between these times.',
      show:
        !utils.isTimeValid(user?.companyId) &&
        history.location.pathname.includes('/app/training/call'),
      type: 'info',
    },
    {
      id: 2,
      message: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            Timezone can be modified in profile. Default timezone was set to EST
            <Button
              style={{ marginLeft: 12 }}
              size="small"
              type="text"
              onClick={() => {
                onChangeTimezone()
                window.localStorage.setItem('timezone_hint', 0)
                dispatch(updateUser({ ...user, timezoneHint: 0 }))
                history.replace(history.location.pathname)
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginLeft: 12, padding: '18px !important' }}
              size="small"
              type="primary"
              onClick={() => {
                onChangeTimezone()
                window.localStorage.setItem('timezone_hint', 0)
                dispatch(updateUser({ ...user, timezoneHint: 0 }))
                history.push('/app/profile')
              }}
            >
              Change
            </Button>
          </div>
        </div>
      ),
      show:
        timezoneHint === 1 &&
        (history.location.pathname.includes('/app/dashboard') ||
          history.location.pathname.includes('/app/training/campaigns')),
      type: 'success',
    },
    {
      id: 3,
      message: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Your trial has expired!!
          <div>
            {isOwner ? (
              <Button
                style={{ marginLeft: 12 }}
                size="small"
                onClick={() => history.push('/app/billing/plans')}
              >
                See plans
              </Button>
            ) : (
              <>Contact your owner to upgrade your plan</>
            )}
          </div>
        </div>
      ),
      show: isSubscribed === false && isTrailActive === false,
      type: 'error',
    },
  ]

  return (
    <>
      {banners.map((b) => {
        let flag = true
        if (flag && b.show) {
          flag = false
          return (
            <div key={b.id} style={{ marginBottom: '10px' }}>
              <Alert
                style={
                  b.style
                    ? b.style
                    : {
                        margin: '-25px -25px 25px',
                        border: 'none',
                        height: '47px',
                        background: '#FFFADB',
                        borderRadius: '0px',
                      }
                }
                type={b.type ? b.type : 'info'}
                message={b.message}
              />
            </div>
          )
        }
        return null
      })}
    </>
  )
}

export default Banners
