import { useMutation, useQuery } from 'react-query'
import request from 'auth/FetchInterceptor'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetCallStates, setToken } from 'redux/calling'

const getTwilioToken = () =>
  request({
    url: `${process.env.REACT_APP_V2_API}/call-detail/sales-rep/token`,
  })

export const useGetTwilioToken = () => {
  const { accessToken } = useSelector((state) => state.authentication)
  const { token } = useSelector((state) => state.calling)
  const dispatch = useDispatch()

  return useQuery('twilio-token', getTwilioToken, {
    retry: 3,
    enabled: !!(accessToken && !token),
    onSuccess: (data) => {
      dispatch(setToken(data?.data?.token))
    },
  })
}

const ratePartner = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/partner-rating`,
    method: 'POST',
    data,
  })

export const useRatePartner = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  return useMutation(ratePartner, {
    retry: false,
    onSuccess: (data) => {
      dispatch(resetCallStates())
      if (data?.data?.callCompleted) {
        history.push(`/app/training/campaigns`)
      }
    },
  })
}

const getCallStatus = (twilioSid) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/call-detail/status?twilioSid=${twilioSid}`,
  })

export const useGetCallStatus = (twilioSid) =>
  useQuery(['call-status', twilioSid], () => getCallStatus(twilioSid), {
    retry: false,
    refetchInterval: 1000,
    enabled: !!twilioSid,
  })

const getCallVerify = (twilioSid) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/call-detail/verify?twilioSid=${twilioSid}`,
  })

export const useGetCallVerify = (twilioSid, timer, setStep) =>
  useQuery(['call-verify', twilioSid], () => getCallVerify(twilioSid), {
    retry: false,
    enabled: timer === 0,
    onSuccess: (data) => {
      if (data?.data?.status === 1) {
        setStep('successful')
      } else {
        setStep('unsuccessful')
      }
    },
  })
