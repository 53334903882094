import { Route, Switch, Redirect } from 'react-router-dom'
import AppLayout from 'layout/AppLayout'
import AuthLayout from 'layout/AuthLayout'
import InvoiceLayout from 'layout/InvoiceLayout'
// import OnboardingView from 'views/onboarding-view'
// import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useSelector } from 'react-redux'
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  // ONBOARD_PREFIX_PATH,
  INVOICE_PREFIX_PATH,
} from 'configs/AppConfig'
import { LayoutProvider } from 'contexts/LayoutContext'
import { useGetUpdatedUserAndToken } from 'services/auth-services'
import Loading from 'components/shared-components/Loading'
import JciqLayout from 'layout/JciqLayout'
import PropTypes from 'prop-types'
import InviteJustCallTeamMembers from './auth-views/InviteJustCallTeamMembers'

// function OnBoardingLayout() {
//   const { status } = useThemeSwitcher()

//   if (status === 'loading') {
//     return <Loading cover="page" />
//   }

//   return (
//     <div className="auth-container">
//       <OnboardingView />
//     </div>
//   )
// }

function RouteInterceptor({ children, isAuthenticated, isLoading, ...rest }) {
  if (isLoading) {
    return <Route {...rest} render={() => <Loading cover="page" />} />
  }

  if (isAuthenticated) {
    return <Route {...rest} render={() => children} />
  }

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: `${AUTH_PREFIX_PATH}/login`,
            state: { from: location },
          }}
        />
      )}
    />
  )
}

RouteInterceptor.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
}

RouteInterceptor.defaultProps = {
  isAuthenticated: false,
  isLoading: false,
}

function Pages() {
  const { isLoading } = useGetUpdatedUserAndToken()
  const { accessToken, user } = useSelector((state) => state.authentication)

  return (
    <Switch>
      <Route exact path={`${APP_PREFIX_PATH}/invite-jc-team-members`}>
        <InviteJustCallTeamMembers />
      </Route>

      <Route exact path="/">
        <Redirect to={AUTH_PREFIX_PATH} />
      </Route>
      <Route path={AUTH_PREFIX_PATH}>
        <AuthLayout />
      </Route>
      <RouteInterceptor
        path={APP_PREFIX_PATH}
        isAuthenticated={!!(accessToken && user)}
        isLoading={isLoading}
      >
        <LayoutProvider>
          <AppLayout />
        </LayoutProvider>
      </RouteInterceptor>
      {/* <RouteInterceptor
        path={ONBOARD_PREFIX_PATH}
        isAuthenticated={!!(accessToken && user && user.role === 0)}
        isLoading={isLoading}
      >
        <OnBoardingLayout />
      </RouteInterceptor> */}

      <RouteInterceptor
        path={INVOICE_PREFIX_PATH}
        isAuthenticated={!!(accessToken && user && user.role === 0)}
        isLoading={isLoading}
      >
        <InvoiceLayout />
      </RouteInterceptor>
      {/* <RouteInterceptor
        path="/jciq"
        isAuthenticated={!!(accessToken && user)}
        isLoading={isLoading}
      >
        <LayoutProvider>
          <JciqLayout />
        </LayoutProvider>
      </RouteInterceptor> */}
    </Switch>
  )
}

export default Pages
