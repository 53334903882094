import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import userflow from 'userflow.js'

userflow.init(process.env.REACT_APP_USERFLOW_TOKEN)

export default function useUserflow() {
  const { user } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (user) {
      userflow.identify(`DIALWORKS-SALESREP-${user.id}`, {
        role: user.role,
        dialworksCompanyId: user.companyId,
        dialworksCompanyName: user.companyName,
        dialworksEmail: user.email,
        fName: user.fName,
        lName: user.lName,
      })
    }
  }, [user])
}
