import { createContext, useContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

const LayoutContext = createContext()

export const useLayout = () => useContext(LayoutContext)

export function LayoutProvider({ children }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(() =>
    localStorage.getItem('nav-collapsed')
      ? JSON.parse(localStorage.getItem('nav-collapsed'))
      : false
  )

  const toggleNav = () => {
    setIsNavCollapsed((currStatus) => {
      localStorage.setItem('nav-collapsed', !currStatus)
      return !currStatus
    })
  }

  const value = useMemo(
    () => ({
      isNavCollapsed,
      toggleNav,
    }),
    [isNavCollapsed]
  )

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  )
}

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
