import { useEffect } from 'react'
import { useSelector } from 'react-redux'

function unMountHelpwiseWidget() {
  const launcher = document.getElementById('helpwise-widget-launcher')
  if (launcher) {
    document.body.removeChild(launcher)
  }
}

function loadHelpwiseWidget() {
  const script = document.createElement('script')
  script.src = 'https://cdn.helpwise.io/assets/js/livechat.js'
  script.id = 'helpwise-widget'
  script.defer = true

  document.body.appendChild(script)
}

export default function useHelpwiseScript() {
  const { user } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (!user) {
      const helpwiseSettings = {
        widget_id: '61cb22be8c577',
        align: 'right',
      }
      window.helpwiseSettings = helpwiseSettings
    } else {
      const helpwiseSettings = {
        widget_id: '61cb22be8c577',
        align: 'right',
        user_id: user?.id,
        firstname: user?.fName,
        lastname: user?.lName,
        email: user?.email,
      }

      window.helpwiseSettings = helpwiseSettings

      const frame = document.getElementById('hw_widget-frame')
      if (frame) {
        document.body.removeChild(frame)
        window.HelpwiseLoadLiveChatWidget()
      }
    }

    loadHelpwiseWidget()

    return () => unMountHelpwiseWidget()
  }, [user])
}
