import { useEffect, useState } from 'react'

export default function useMicrophone() {
  const [isMicrophoneGranted, setIsMicrophoneGranted] = useState(false)

  const updateMicPermissions = (status) => {
    if (status === 'granted') setIsMicrophoneGranted(true)
    else setIsMicrophoneGranted(false)
  }

  function getLocalAudioStream() {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(() => {
        setIsMicrophoneGranted(true)
      })
      .catch(() => {
        setIsMicrophoneGranted(false)
      })
  }

  useEffect(() => {
    navigator.permissions
      .query({
        name: 'microphone',
      })
      .then((permissionStatus) => {
        updateMicPermissions(permissionStatus.state)
      })
  }, [])

  return { isMicrophoneGranted, getLocalAudioStream }
}
