import { lazy, Suspense, memo } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from 'react-redux'
import { AdminRoute } from 'components/Routes'
import TrailOverModal from './TrailOverModal'

export function AppViews() {
  const { isAdmin, user } = useSelector((state) => state.authentication)

  const emailVerified = user?.emailVerifiedAt

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <TrailOverModal />
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/email-not-verified`}
          component={lazy(() => import('./EmailNotVerified'))}
        />

        {!emailVerified && user?.id && (
          <Redirect to={`${APP_PREFIX_PATH}/email-not-verified`} />
        )}

        <Route
          exact
          path={`${APP_PREFIX_PATH}/training/campaigns`}
          component={lazy(() =>
            import(`./salesrep-views/campaign-view/all-campaign-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/campaigns/new-campaign`}
          component={lazy(() =>
            import(`./salesrep-views/campaign-view/new-campaign-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/campaigns/edit-campaign/:id`}
          component={lazy(() =>
            import(`./salesrep-views/campaign-view/edit-campaign-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/call/:campaignId`}
          component={lazy(() => import(`./salesrep-views/call-view`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/campaigns/all-analytics/:id`}
          component={lazy(() =>
            import(`./salesrep-views/campaign-view/single-campaign-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/campaigns/my-analytics/:id`}
          component={lazy(() =>
            import(`./salesrep-views/campaign-view/single-campaign-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./salesrep-views/profile-view`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/change-password`}
          component={lazy(() =>
            import(`./salesrep-views/change-password-view`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/help-guide`}
          component={lazy(() => import(`./salesrep-views/help-videos-view`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/notifications`}
          component={lazy(() => import(`./salesrep-views/notifications-view`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/training/campaigns/transcription/:id`}
          component={lazy(() => import(`./salesrep-views/jciq-view`))}
        />

        <AdminRoute
          exact
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./admin-views/dashboard-view`))}
        />
        <AdminRoute
          exact
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/onboarding`}
          component={lazy(() => import(`./admin-views/onboard-view`))}
        />
        <AdminRoute
          exact
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/integrations`}
          component={lazy(() =>
            import(`./admin-views/integrations-view/all-integrations-view`)
          )}
        />
        <AdminRoute
          exact
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/integrations/:id`}
          component={lazy(() =>
            import(`./admin-views/integrations-view/config-integration-view`)
          )}
        />

        <AdminRoute
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/training/analytics`}
          component={lazy(() => import(`./admin-views/analytics-view`))}
        />
        <AdminRoute
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/team`}
          component={lazy(() => import(`./admin-views/team-member-view`))}
        />
        <AdminRoute
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/billing`}
          component={lazy(() => import(`./admin-views/payment-view`))}
        />
        <AdminRoute
          isAdmin={isAdmin}
          exact
          path={`${APP_PREFIX_PATH}/hiring/assessments`}
          component={lazy(() =>
            import(`./admin-views/assessment-view/all-assessment-view`)
          )}
        />
        <AdminRoute
          isAdmin={isAdmin}
          exact
          path={`${APP_PREFIX_PATH}/hiring/assessments/analytics/:id`}
          component={lazy(() =>
            import(`./admin-views/assessment-view/single-assessment-view`)
          )}
        />
        <AdminRoute
          isAdmin={isAdmin}
          exact
          path={`${APP_PREFIX_PATH}/hiring/assessments/new-assessment`}
          component={lazy(() =>
            import(`./admin-views/assessment-view/new-assessment-view`)
          )}
        />
        <AdminRoute
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/hiring/assessments/edit-assessment/:id`}
          component={lazy(() =>
            import(`./admin-views/assessment-view/edit-assessment-view`)
          )}
        />
        <AdminRoute
          isAdmin={isAdmin}
          path={`${APP_PREFIX_PATH}/hiring/assessments/manage-candidate/:id`}
          component={lazy(() =>
            import(`./admin-views/assessment-view/manage-candidate-view`)
          )}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/invite-jc-team-members`}
          component={lazy(() =>
            import(`../auth-views/InviteJustCallTeamMembers`)
          )}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  )
}

export default memo(AppViews)
