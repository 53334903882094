import { lazy, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { INVOICE_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from 'react-redux'
import { AdminRoute } from 'components/Routes'

function InvoiceView() {
  const { isAdmin } = useSelector((state) => state.authentication)

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <AdminRoute
          isAdmin={isAdmin}
          exact
          path={`${INVOICE_PREFIX_PATH}/:id`}
          component={lazy(() => import(`./Invoice`))}
        />
      </Switch>
    </Suspense>
  )
}

export default InvoiceView
