/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import RocketIcon from '../../assets/images/onboarding/rocketicon.svg'

function TrialCallButton(props) {
  const { callsLeft, trialCampaignId, trialCallCampaign, enableTextBelow } =
    props
  const history = useHistory()
  function showAnalytics() {
    // console.log(trialCampaignId)
    history.push(`campaigns/my-analytics/${trialCampaignId}`)
  }
  return (
    <>
      <div
        className="mb-2"
        style={{
          padding: '1px',
          borderRadius: '4px',
          background: 'linear-gradient(91.89deg, #DB4E82 0%, #FF9777 100%)',
          width: 'max-content',
          margin: '0px auto',
        }}
      >
        <Button
          type="default"
          disabled={callsLeft === 0}
          style={{
            // color: props.callsLeft === 0 ? "#E3E6EB" : "#FF6B93",
            // padding: "10px 20px 10px 15px",
            borderRadius: '4px',
            borderImage:
              callsLeft === 0
                ? '#E3E6EB'
                : 'linear-gradient(91.89deg, #DB4E82 0%, #FF9777 100%)',
            // borderColor: props.callsLeft === 0 ? "#E3E6EB" : "#FF6B93",
            display: 'flex',
            alignItems: 'center',
            // width: "100%",
          }}
          onClick={() => trialCallCampaign()}
        >
          {/* <IoRocketOutline style={{ fontSize: "16px", marginRight: "10px" }} /> */}
          <img
            src={RocketIcon}
            style={{ marginRight: '5px' }}
            alt="rocketicon"
          />
          <span
            style={{ fontSize: '12px', fontWeight: '600', color: '#FF6B93' }}
          >
            See how training call works
          </span>
        </Button>
      </div>
      {enableTextBelow && (
        <>
          <p
            style={{
              color: 'black',
              marginBottom: '0px',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {callsLeft === 0
              ? `Your trial calls have exhausted`
              : `You have ${callsLeft} trial calls left 🔥`}
          </p>
          {callsLeft < 5 && (
            <p
              style={{
                color: '#5A6FE4',
                marginBottom: '0px',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer',
              }}
              onClick={() => showAnalytics()}
            >
              View trial call assesment
            </p>
          )}
        </>
      )}
    </>
  )
}

export default TrialCallButton
TrialCallButton.propTypes = {
  trialCampaignId: PropTypes.number.isRequired,
  callsLeft: PropTypes.number.isRequired,
  enableTextBelow: PropTypes.bool.isRequired,
  trialCallCampaign: PropTypes.func.isRequired,
}
