// TODO check for deletion from state object
import React from 'react'
import { Form, Select, Button, Input, Row, Col } from 'antd'
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  useAddTeamMember,
  useGetJustCallMembers,
} from 'services/team-members-services'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const { Option } = Select

const formatValues = (values) => {
  const data = []
  values?.forEach((v) => {
    if (v.fulldata) {
      const temp = v.fulldata?.split(',')
      const x = {
        fName: temp[0],
        lName: temp[1],
        email: temp[2],
        isAdmin: false,
        timezoneId: 1,
      }
      data.push(x)
    }
  })
  return data
}

export function JustCallForm3() {
  const { user } = useSelector((state) => state.authentication)
  const { data, isLoading } = useGetJustCallMembers()
  const history = useHistory()
  const { mutate, isLoading: addTeamMemberLoading } =
    useAddTeamMember('justcall')
  const [disabled, setDisabled] = React.useState(true)
  const [count, setCount] = React.useState(0)
  const [selectedNames, setSelectedNames] = React.useState({})
  const [form] = Form.useForm()

  const onFinish = (values) => {
    if (data?.data?.length === 0) {
      history.push('/app/dashboard')
    } else {
      const result = []
      result.push({ fulldata: values.fulldata })
      if (values.members) {
        result.push(...values.members)
      }
      mutate(formatValues(result))
    }
  }

  const checkOption = (d) =>
    Object.values(selectedNames).includes(`${d.fName},${d.lName},${d.email}`)

  React.useEffect(() => {
    if (user && user.id && user.source !== 2) {
      history.push('/app/dashboard')
    }
  }, [user, history])

  React.useEffect(() => {
    if (data?.data?.length === 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [data])

  const disableCheck = () => {
    setDisabled(false)
    if (form && form.getFieldValue()?.members?.length > 0) {
      form.getFieldValue()?.members?.forEach((obj) => {
        if (!obj?.fulldata) {
          setDisabled(true)
        }
      })
    } else {
      setDisabled(true)
    }
  }

  // console.log("form", form.getFieldValue());

  return (
    <>
      <Col span={20} flex="auto" style={{ height: 'calc(100% - 280px)' }}>
        <h1 style={{ fontSize: '32px', marginBottom: 10 }}>
          Import team members from JustCall
        </h1>
        <div style={{ fontSize: 14 }}>
          Add up to 5 members. You can add all of them later
        </div>
        <Form form={form} name="form3" onFinish={onFinish} size="middle">
          <div
            style={{
              overflow: 'scroll',
              height: '300px',
              maxHeight: '300px',
              marginTop: 64,
            }}
          >
            <Row align="middle" style={{ marginBottom: 16 }} gutter={24}>
              <Col>
                <p style={{ marginBottom: '0px' }}>TEAM MEMBER #1</p>
              </Col>
              <Col flex="auto">
                <Input value={user.email} disabled />
              </Col>
            </Row>
            <Form.List name="members" style={{ marginTop: 16 }}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row
                      key={key}
                      align="middle"
                      style={{ marginBottom: 16 }}
                      gutter={24}
                    >
                      <Col>
                        <p style={{ marginBottom: '0px' }}>
                          TEAM MEMBER #{name + 2}
                        </p>
                      </Col>
                      <Col flex="auto">
                        <Row align="middle" gutter={16}>
                          <Col flex={1}>
                            <Form.Item
                              name={[name, 'fulldata']}
                              style={{ marginBottom: 0 }}
                              hasFeedback
                            >
                              <Select
                                showSearch
                                showArrow={false}
                                size="middle"
                                // style={{ width: "calc(90%)" }}
                                loading={isLoading}
                                onSelect={(e) => {
                                  setSelectedNames((prev) => {
                                    const temp = prev
                                    if (temp[key]) delete temp[key]
                                    temp[key] = e
                                    return temp
                                  })
                                  disableCheck()
                                }}
                              >
                                {data?.data?.length !== 0 &&
                                  data?.data?.map((d) => (
                                    <Option
                                      key={d.email}
                                      disabled={checkOption(d)}
                                      value={`${d.fName},${d.lName},${d.email}`}
                                    >
                                      {`${d.fName} ${d.lName} (${d.email})`}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col>
                            <div className="text-center" style={{ width: 24 }}>
                              <DeleteTwoTone
                                style={{ cursor: 'pointer' }}
                                twoToneColor="#FF0000"
                                onClick={() => {
                                  remove(name)
                                  setSelectedNames((prev) => {
                                    const temp = prev
                                    if (temp[key]) delete temp[key]
                                    return temp
                                  })
                                  setCount((prev) => prev - 1)
                                  disableCheck()
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                  {count !== 2 && (
                    <Row
                      align="middle"
                      style={{ marginBottom: 16 }}
                      gutter={24}
                    >
                      <Col>
                        <p
                          style={{
                            marginBottom: '0px',
                            minWidth: 120,
                          }}
                        />
                      </Col>
                      <Col flex="auto">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button
                            onClick={() => {
                              add()
                              disableCheck()
                              setCount((prev) => prev + 1)
                            }}
                            icon={<PlusOutlined />}
                            style={{ color: '#2e9b72' }}
                          >
                            Add member
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Form.List>
          </div>
        </Form>
      </Col>
      <Col span={20} style={{ marginBottom: 100 }}>
        <Button
          onClick={() => form.submit()}
          disabled={disabled}
          type="primary"
          style={{ marginRight: '24px' }}
          htmlType="submit"
          loading={addTeamMemberLoading}
        >
          {data?.data?.length > 0
            ? `Add team member & Get started`
            : 'Get started'}
        </Button>
        <Button onClick={() => history.push(`${APP_PREFIX_PATH}/dashboard`)}>
          I&apos;ll do this later
        </Button>
        {/* <a href={`${APP_PREFIX_PATH}/dashboard`}>I&apos;ll do this later</a> */}
      </Col>
    </>
  )
}
