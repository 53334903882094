import Loading from 'components/shared-components/Loading'
import PageHeader from 'components/layout-components/Header'
import JciqView from 'views/app-views/salesrep-views/jciq-view'

import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'

import navigationConfig from 'configs/NavigationConfig'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import utils from 'utils'

function AppLayout() {
  const location = useLocation()
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  )
  const { status } = useThemeSwitcher()

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  return (
    <Layout>
      <PageHeader
        display={currentRouteInfo?.breadcrumb}
        title={currentRouteInfo?.title}
      />
      <Layout
        style={{ marginTop: '70px', height: '100%' }}
        //   className="auth-container"
      >
        <JciqView />
      </Layout>
    </Layout>
  )
}

export default AppLayout
