/* eslint-disable default-param-last */
import request from 'auth/FetchInterceptor'
import { useMutation, useQuery } from 'react-query'
import { setTrail, setSubscription, setAvailable } from 'redux/payment'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAssessmentExpiredPopup,
  updateTrainingExpiredPopup,
  updateAssessmentFeatureAd,
  updateTrainingFeatureAd,
  updateTrainingCreditsExpiredPopup,
  updateAssessmentCreditsExpiredPopup,
} from 'redux/preferences'
import moment from 'moment'

const getAvailableCredits = () =>
  request({ url: `${process.env.REACT_APP_V2_API}/credit-log/available` })

export const useGetAvailableCredits = () => {
  const dispatch = useDispatch()

  const { user, accessToken } = useSelector((state) => state.authentication)

  return useQuery('available-credits', getAvailableCredits, {
    retry: false,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      switch (data?.data?.subscriptionStatus) {
        case 'trial': {
          dispatch(setTrail(true))
          break
        }
        case 'active': {
          dispatch(setSubscription(true))
          break
        }
        case 'expired': {
          dispatch(setSubscription(false))
          dispatch(setTrail(false))
          break
        }
        default:
          throw new Error(`Unknown plan type ${data?.data?.subscriptionStatus}`)
      }

      if (data?.data) {
        dispatch(setAvailable(data?.data))
      }
      const endDate = data?.data?.endDate
      const trainingCredits = data?.data?.available
      const hiringEndDate = data?.data?.hiringEndDate
      const hiringCredits = data?.data?.assessmentCandidatesCount

      if (endDate) {
        dispatch(updateTrainingFeatureAd(false))
        if (moment(endDate).isBefore()) {
          dispatch(updateTrainingExpiredPopup(true))
          if (hiringCredits > 0) {
            dispatch(updateAssessmentCreditsExpiredPopup(false))
          } else {
            dispatch(updateAssessmentCreditsExpiredPopup(true))
          }
        } else {
          dispatch(updateTrainingExpiredPopup(false))
          if (trainingCredits > 0) {
            dispatch(updateTrainingCreditsExpiredPopup(false))
          } else {
            dispatch(updateTrainingCreditsExpiredPopup(true))
          }
        }
      } else {
        dispatch(updateTrainingFeatureAd(true))
      }

      if (hiringEndDate) {
        dispatch(updateAssessmentFeatureAd(false))
        if (moment(hiringEndDate).isBefore()) {
          dispatch(updateAssessmentExpiredPopup(true))
          if (hiringCredits > 0) {
            dispatch(updateAssessmentCreditsExpiredPopup(false))
          } else {
            dispatch(updateAssessmentCreditsExpiredPopup(true))
          }
        } else {
          dispatch(updateAssessmentExpiredPopup(false))
          if (hiringCredits > 0) {
            dispatch(updateAssessmentCreditsExpiredPopup(false))
          } else {
            dispatch(updateAssessmentCreditsExpiredPopup(true))
          }
        }
      } else {
        dispatch(updateAssessmentFeatureAd(true))
      }
    },
    enabled: !!(user && accessToken),
  })
}

const getAllCreditLogs = (from, to, page, limit) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/credit-log?from=${from}&to=${to}&page=${page}&limit=${limit}`,
  })

export const useGetAllCreditLogs = (
  from,
  to,
  page = 1,
  limit = 10,
  setTableParamsCallback
) =>
  useQuery(
    `all-credits-from:${from}-to:${to}-page:${page}-limit:${limit}`,
    () => getAllCreditLogs(from, to, page, limit),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setTableParamsCallback(data?.data?.meta?.totalCount)
      },
    }
  )

const getAllAssessmentCreditLogs = (from, to, page, limit) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment-credit-log?from=${from}&to=${to}&page=${page}&limit=${limit}`,
  })

export const useGetAllAssessmentCreditLogs = (
  from,
  to,
  page = 1,
  limit = 10,
  setTableParamsCallback
) =>
  useQuery(
    `all-assessment-credits-from:${from}-to:${to}-page:${page}-limit:${limit}`,
    () => getAllAssessmentCreditLogs(from, to, page, limit),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setTableParamsCallback(data?.data?.meta?.totalCount)
      },
    }
  )

const purchaseCredits = (data) => {
  if (data.minutes)
    return request({
      url: `${process.env.REACT_APP_V2_API}/plan/training/buy-credits`,
      data,
      method: 'POST',
    })
  if (data.candidates)
    return request({
      url: `${process.env.REACT_APP_V2_API}/plan/hiring/buy-credits`,
      data,
      method: 'POST',
    })
  return new Promise((_resolve, reject) => {
    reject(new Error('Oops something went wrong at our end!!'))
  })
}

export const usePurchaseCredits = () =>
  useMutation(purchaseCredits, {
    retry: false,
    onSuccess: (data) => {
      const url = data?.data?.url
      if (url) window.location.replace(url)
    },
  })
