import axios from 'axios'
import { login, logout } from 'redux/authentication'
import { notification } from 'antd'
import store, { dispatch } from 'redux/store'

const client = axios.create({ baseURL: process.env.REACT_APP_V2_API })

const request = async ({
  isNotificationDisabled = false,
  successMessage = null,
  errorMessage = null,
  tokenForThisApi = null,
  ...options
}) => {
  const accessToken =
    tokenForThisApi || store.getState().authentication.accessToken

  const { isImpersonating } = store.getState().authentication

  client.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  client.defaults.headers.post.ContentType = 'application/json'
  client.defaults.withCredentials = true

  const onSuccess = (response) => {
    const notificationParam = {
      message: 'Success',
      description: successMessage,
      placement: 'bottomLeft',
    }

    if (successMessage) {
      notification.success(notificationParam)
    }

    return response
  }

  const onError = (error) => {
    const notificationParam = {
      message: 'Error',
      description: 'Oops! Something went wrong.',
      placement: 'bottomLeft',
    }

    if (error.response.status === 401) {
      if (isImpersonating) {
        dispatch(logout())
      }
      console.log('token expired here making request to get updated token')
      client
        .get(`${process.env.REACT_APP_V2_API}/token/sales-rep`)
        .then(({ data }) => {
          dispatch(login(data))
          return request({
            isNotificationDisabled,
            successMessage,
            errorMessage,
            ...options,
          })
        })
        .catch(() => {
          notificationParam.description = 'Please login again'
          dispatch(logout())
        })
    }

    if (error.response.status === 400) {
      notificationParam.description = error.response?.data?.message
        ? error.response.data.message
        : 'Something went wrong'
    }

    if (error.response.status === 404) {
      notificationParam.description = error.response?.data?.message
        ? error.response.data.message
        : 'Resource not available'
    }

    if (error.response.status === 406) {
      notificationParam.description = error.response?.data?.message
        ? error.response.data.message
        : 'Something went wrong'
    }
    if (error.response.status === 422) {
      notificationParam.description = error.response?.data?.message
        ? error.response.data.message
        : 'Something went wrong'
    }

    if (error.response.status === 500) {
      notificationParam.description = 'Internal Server Error'
    }

    if (error.response.status === 508) {
      notificationParam.description = 'Time Out'
    }

    if (errorMessage) {
      notificationParam.description = errorMessage
    }

    if (
      !isNotificationDisabled &&
      error.response.status !== 401 &&
      accessToken
    ) {
      // console.log({ notificationParam })
      notification.error(notificationParam)
    }

    return Promise.reject(error.response.data)
  }
  return client(options).then(onSuccess).catch(onError)
}

export default request
