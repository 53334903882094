import request from 'auth/FetchInterceptor'
import { useMutation, useQuery } from 'react-query'
import {
  login as loginAction,
  logout as logoutAction,
  selectCurrentUser,
} from 'redux/authentication'
import { dispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { changeStepCount, resetPreferences } from 'redux/preferences'

const login = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/sign-in`,
    method: 'POST',
    data,
  })

export const useLogin = () =>
  useMutation(login, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
    },
  })

const signup = (data) =>
  request({
    url: `sales-rep/sign-up-direct`,
    data,
    method: 'POST',
    isNotificationDisabled: true,
  })

export const useSignup = () =>
  useMutation(signup, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
    },
  })

const verifyEmail = (data) =>
  request({
    url: `sales-rep/verify-email`,
    method: 'POST',
    data,
    showSuccessNotification: true,
    successMessage: 'Successfully verified email',
  })

export const useVerifyEmail = () =>
  useMutation(verifyEmail, {
    retry: false,
  })

const resendVerificationMail = (data) =>
  request({
    url: `sales-rep/resend/email-verification`,
    method: 'POST',
    data,
    retry: false,
    showSuccessNotification: true,
    successMessage: 'Verification mail sent to your email address',
  })

export const useResendVerificationMail = () =>
  useMutation(resendVerificationMail, {
    retry: false,
  })

const registerViaInvite = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/sign-up`,
    method: 'POST',
    data,
  })

export const useRegisterViaInvite = () =>
  useMutation(registerViaInvite, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
    },
  })

const justCallRegister = (data) =>
  request({
    url: `sales-rep/signup-third-party`,
    method: 'POST',
    data,
  })

export const useJustCallRegister = () => {
  const history = useHistory()
  return useMutation(justCallRegister, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
      dispatch(changeStepCount(2))
      history.push(`${APP_PREFIX_PATH}/invite-jc-team-members`)
    },
  })
}

// to update user every time app is refreshed or opened

const getUpdatedUserAndToken = () =>
  request({
    url: `${process.env.REACT_APP_V2_API}/token/sales-rep`,
    method: 'GET',
  })

export const useGetUpdatedUserAndToken = () => {
  const { wasLoggedIn, isImpersonating } = useSelector(
    (state) => state.authentication
  )
  const location = useLocation()

  return useQuery('update-user-and-token', () => getUpdatedUserAndToken(), {
    retry: false,
    enabled: !!(
      wasLoggedIn &&
      !location.pathname.includes('verify-email') &&
      !isImpersonating
    ),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
    },
    onError: () => {
      localStorage.setItem('was-logged-in', false)
      localStorage.setItem('is-admin', false)
    },
  })
}

// logout

const logout = ({ userHash, allDevice = false }) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/logout`,
    method: 'POST',
    data: {
      userHash,
      allDevice,
    },
  })

export const useLogout = () => {
  const user = useSelector(selectCurrentUser)
  return useMutation(() => logout({ userHash: user?.userHash }), {
    retry: false,
    onSuccess: () => {
      dispatch(logoutAction())
      dispatch(resetPreferences())
    },
  })
}

// update profile

const updateProfile = (userHash, data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/${userHash}`,
    method: 'PUT',
    data,
    showSuccessNotification: true,
    successMessage: 'Profile updated successfully',
  })

export const useUpdateProfile = () => {
  const user = useSelector(selectCurrentUser)
  return useMutation((data) => updateProfile(user.userHash, data), {
    retry: false,
  })
}

// change password from profile

const changePassword = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/change-password`,
    data,
    method: 'PUT',
    // showSuccessNotification: true,
    // successMessage: "Password successfully updated"
  })

export const useChangePassword = () =>
  useMutation(changePassword, {
    retry: false,
  })

// forgot password

const forgotPassword = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/forgot-password`,
    data,
    method: 'POST',
  })

export const useForgotPassword = () =>
  useMutation(forgotPassword, {
    retry: false,
  })

// change password after forgot password

const changePasswordAfterForgot = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/reset-password`,
    data,
    method: 'POST',
    showSuccessNotification: true,
    successMessage: 'Password updated successfully',
  })

export const useChangePasswordAfterForgot = () =>
  useMutation(changePasswordAfterForgot, {
    retry: false,
  })

// login for inivited user

const loginInvitedUser = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/team-member/create-profile`,
    method: 'post',
    data,
  })

export const useLoginInvitedUser = () =>
  useMutation(loginInvitedUser, {
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction(data.data))
    },
  })

const getSelfDetails = () => request({ url: `sales-rep/self` })

export const useGetSelfDetails = (isImpersonating = false) => {
  const accessToken = useSelector((state) => state.authentication.accessToken)

  return useQuery(['self-detail'], getSelfDetails, {
    enabled: !accessToken,
    retry: false,
    onSuccess: (data) => {
      dispatch(loginAction({ ...data.data, isImpersonating }))
    },
  })
}

// email validation check
const emailValidation = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/email/verification`,
    method: 'post',
    data,
  })

export const useEmailValidation = () =>
  useMutation(emailValidation, {
    retry: false,
  })
