import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Menu, Button, Tag } from 'antd'
import navigationConfig from 'configs/NavigationConfig'
import { useSelector } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  useGetAllCampaigns,
  useGetUserSpecificCampaigns,
} from 'services/campaign-services'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  useGetActiveAssessments,
  useGetExpiredAssessments,
} from 'services/assessment-services'
import Icon from '../../Icon'

const { SubMenu } = Menu

function MenuContent() {
  const { isAdmin, isOwner } = useSelector((state) => state.authentication)
  const { role } = useSelector((state) => state.authentication.user)

  const [analyticsView, setAnalyticsView] = React.useState('')
  const [selected, setSelected] = React.useState([])
  const history = useHistory()

  const { data: campaignData } = useGetAllCampaigns(role)
  const { data: salesrepSpecificData } = useGetUserSpecificCampaigns()

  const { data: activeAssessments } = useGetActiveAssessments(role)
  const { data: expiredAssessments } = useGetExpiredAssessments(role)

  React.useEffect(() => {
    if (history.location.pathname.includes('-analytics')) {
      const selectedId = history.location.pathname.split('/')[5]
      setSelected([selectedId.trim()])
      setAnalyticsView(
        history.location.pathname.includes('/all-analytics/') ? 'admin' : 'user'
      )
    } else if (history.location.pathname.includes('assessments/analytics/')) {
      const selectedId = history.location.pathname.split(
        '/assessments/analytics/'
      )[1]
      setSelected([selectedId.trim()])
      setAnalyticsView('assessment')
    } else if (history.location.pathname.includes('credit-logs')) {
      const path = history.location.pathname
      if (path.includes('training')) {
        setSelected(['training-credit-logs'])
      } else if (path.includes('hiring')) {
        setSelected(['hiring-credit-logs'])
      }
    } else {
      const selectedKey = history.location.pathname.split('/').slice(-1)
      setSelected([...selectedKey])
      setAnalyticsView('')
    }
  }, [history.location.pathname])

  const assessmentTitleRenderer = () => {
    if (analyticsView === 'user') {
      return 'My Campaigns'
    }
    if (analyticsView === 'admin') {
      return 'All Campaigns'
    }
    return ''
  }

  const analyticsMenuRenderer = () => {
    if (analyticsView === 'assessment') {
      return (
        <div>
          {(activeAssessments?.data?.length > 0 ||
            expiredAssessments?.data?.length > 0) && (
            <Menu selectedKeys={selected} style={{ marginBottom: 120 }}>
              {activeAssessments?.data?.length > 0 && (
                <Menu.ItemGroup title="Active Assessments">
                  {activeAssessments?.data?.map((assessment) => (
                    <Menu.Item key={assessment.id}>
                      <span>{assessment.title}</span>
                      <Link
                        replace
                        to={{
                          pathname: `${APP_PREFIX_PATH}/hiring/assessments/analytics/${assessment.id}`,
                        }}
                      />
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              )}
              {expiredAssessments?.data?.length > 0 && (
                <Menu.ItemGroup title="Completed Assessments">
                  {expiredAssessments?.data?.map((assessment) => (
                    <Menu.Item key={assessment.id}>
                      <span>{assessment.title}</span>
                      <Link
                        replace
                        to={{
                          pathname: `${APP_PREFIX_PATH}/hiring/assessments/analytics/${assessment.id}`,
                        }}
                      />
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              )}
            </Menu>
          )}
        </div>
      )
    }
    if (analyticsView === 'admin') {
      return (
        <div>
          {campaignData?.data?.length > 0 && (
            <Menu selectedKeys={selected} style={{ marginBottom: 120 }}>
              {campaignData?.data?.map((camp) => (
                <Menu.Item key={camp.id}>
                  <span>{camp.name}</span>
                  <Link
                    replace
                    to={`${APP_PREFIX_PATH}/training/campaigns/all-analytics/${camp.id}`}
                  />
                </Menu.Item>
              ))}
            </Menu>
          )}
        </div>
      )
    }
    return (
      <div>
        {salesrepSpecificData?.data?.length > 0 && (
          <Menu selectedKeys={selected} style={{ marginBottom: 120 }}>
            {salesrepSpecificData?.data?.map((camp) => (
              <Menu.Item key={camp.id}>
                <span>{camp.name}</span>
                <Link
                  replace
                  to={`${APP_PREFIX_PATH}/training/campaigns/my-analytics/${camp.id}`}
                />
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
    )
  }

  const accessChecker = (access) =>
    (access === 'admin' && isAdmin) ||
    (access === 'owner' && isOwner) ||
    access === 'salesrep'

  const navigationConfigRenderer = (menu) => {
    if (menu.submenu.length > 0 && accessChecker(menu.access)) {
      return (
        <SubMenu
          key={menu.key}
          title={
            <>
              {menu.title}{' '}
              {menu.title === 'Hiring' ? (
                <Tag
                  color="orange"
                  style={{
                    lineHeight: '16px',
                    fontSize: 12,
                    marginLeft: 4,
                    padding: '0px 4px',
                  }}
                >
                  NEW
                </Tag>
              ) : null}
            </>
          }
        >
          {menu.submenu.map((subMenuFirst) =>
            accessChecker(subMenuFirst.access) ? (
              <Menu.Item
                key={subMenuFirst.key}
                icon={
                  subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null
                }
              >
                <span>{subMenuFirst.title}</span>
                <Link to={subMenuFirst.path} />
              </Menu.Item>
            ) : null
          )}
        </SubMenu>
      )
    }
    if (accessChecker(menu.access)) {
      return (
        <Menu.Item
          key={menu.key}
          icon={menu.icon ? <Icon type={menu?.icon} /> : null}
        >
          <span>{menu?.title}</span>
          {menu?.title === 'Integrations' && (
            <Tag
              color="orange"
              style={{
                lineHeight: '16px',
                fontSize: 12,
                marginLeft: 4,
                padding: '0px 4px',
              }}
            >
              NEW
            </Tag>
          )}
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      )
    }
    return null
  }

  return (
    <Menu
      mode="inline"
      style={{ borderRight: 0 }}
      selectedKeys={selected}
      defaultSelectedKeys={selected}
      defaultOpenKeys={['hiring', 'training']}
    >
      {analyticsView ? (
        <>
          <div style={{ padding: '8px 8px 0' }}>
            {/* <Tooltip title="All Campaigns"> */}
            <Button
              className="text-muted"
              onClick={() =>
                history.location.pathname.includes('campaigns')
                  ? history.push(`${APP_PREFIX_PATH}/training/campaigns`)
                  : history.push(`${APP_PREFIX_PATH}/hiring/assessments`)
              }
              icon={<ArrowLeftOutlined />}
              type="text"
              shape="circle"
              size="small"
            />
            {/* </Tooltip> */}
            {analyticsView !== 'assessment' && (
              <h4
                className="text-muted mt-2"
                style={{
                  fontSize: '12px',
                  lineHeight: '1.5',
                  fontWeight: '600',
                  textTransform: 'uppercase',
                }}
              >
                <span style={{ marginLeft: 8 }}>
                  {assessmentTitleRenderer()}
                </span>
              </h4>
            )}
          </div>
          {analyticsMenuRenderer()}
        </>
      ) : (
        <>{navigationConfig.map((menu) => navigationConfigRenderer(menu))}</>
      )}
    </Menu>
  )
}

export default MenuContent

MenuContent.defaultProps = { routeInfo: {} }
