import Icon, {
  ReadOutlined,
  TeamOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { MdOutlineDashboardCustomize } from 'react-icons/md'
import { BiNotepad } from 'react-icons/bi'
import { IoGitMerge } from 'react-icons/io5'

function HelpIconBase() {
  return (
    <svg
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M18,2H6C4.9,2,4,2.9,4,4v11c0,0.63,0.3,1.22,0.8,1.6L12,22l7.2-5.4c0.5-0.38,0.8-0.97,0.8-1.6V4C20,2.9,19.1,2,18,2z M13,15 h-2v-2h2V15z M13,12h-2c0-2.552,1.993-2.096,1.993-4.197c0-0.412-0.178-1.328-1.05-1.328C11.679,6.475,10.958,7,10.958,8H9.015 c0,0-0.43-2.992,2.929-2.992C14.529,5.008,15,6.934,15,7.793C15,10.144,13,10.297,13,12z" />
    </svg>
  )
}

export function HelpIcon(props) {
  return <Icon component={HelpIconBase} {...props} />
}

function DashboardIcon(props) {
  return <Icon component={MdOutlineDashboardCustomize} {...props} />
}
function AssessmentIcon(props) {
  return <Icon component={BiNotepad} {...props} />
}
function IntegrationIcon(props) {
  return <Icon component={IoGitMerge} {...props} />
}

const adminDashboard = [
  {
    access: 'admin',
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: ' Dashboard',
    icon: DashboardIcon,
    breadcrumb: false,
    submenu: [],
  },
]

const assessmentsTree = [
  {
    access: 'admin',
    key: 'hiring',
    title: 'Hiring',
    breadcrumb: false,
    submenu: [
      {
        access: 'admin',
        icon: AssessmentIcon,
        key: 'assessments',
        title: 'Assessments',
        path: `${APP_PREFIX_PATH}/hiring/assessments`,
      },
    ],
  },
]
const campaignsTree = [
  {
    access: 'salesrep',
    key: 'training',
    title: 'Training',
    breadcrumb: false,
    submenu: [
      {
        access: 'salesrep',
        icon: ReadOutlined,
        key: 'campaigns',
        title: 'Campaigns',
        path: `${APP_PREFIX_PATH}/training/campaigns`,
      },
      {
        access: 'admin',
        icon: LineChartOutlined,
        key: 'analytics',
        title: 'Analytics',
        path: `${APP_PREFIX_PATH}/training/analytics`,
      },
    ],
  },
]

const teamMembersTree = [
  {
    access: 'admin',
    key: 'team',
    path: `${APP_PREFIX_PATH}/team`,
    title: ' Team',
    icon: TeamOutlined,
    breadcrumb: false,
    submenu: [],
  },
]

const integrationsTree = [
  {
    access: 'admin',
    key: 'integrations',
    path: `${APP_PREFIX_PATH}/integrations`,
    title: 'Integrations',
    icon: IntegrationIcon,
    breadcrumb: false,
    submenu: [],
  },
]

const helpGuideTree = [
  {
    access: 'salesrep',
    key: 'help-guide',
    path: `${APP_PREFIX_PATH}/help-guide`,
    title: ' Help Guides',
    icon: HelpIcon,
    breadcrumb: false,
    submenu: [],
  },
]

const navigationConfig = [
  ...adminDashboard,
  ...campaignsTree,
  ...assessmentsTree,
  ...teamMembersTree,
  ...integrationsTree,
  ...helpGuideTree,
]

export default navigationConfig
