import { Menu, Dropdown, Avatar, Row, Col } from 'antd'
import {
  LogoutOutlined,
  ToolOutlined,
  UserOutlined,
  LoadingOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import gravatarUrl from 'gravatar-url'
import { useSelector } from 'react-redux'
import { useLogout } from 'services/auth-services'

function NavProfile() {
  const { user, isOwner } = useSelector((state) => state.authentication)
  const { mutate, isLoading } = useLogout()

  const history = useHistory()
  const truncate = (text) => {
    if (text.length > 27) {
      return `${text.substr(0, 27)}...`
    }
    return text
  }

  const handleSignOut = () => {
    mutate()
  }

  const link = gravatarUrl(user.email, {
    size: 200,
    default: '404',
  })

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Row gutter={8}>
            <Col>
              <Avatar
                src={link}
                style={{ backgroundColor: '#2E9B72', fontSize: '20px' }}
              >
                {user.fName[0]}
              </Avatar>
            </Col>
            <Col>
              <div className="ml-3">
                <h4 className="mb-0">{`${user?.fName} ${user?.lName}`}</h4>
                <span className="text-muted">{truncate(user?.email)}</span>
                <br />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu selectedKeys={[]}>
          <Menu.Item
            key={1}
            onClick={() => {
              history.push(`${APP_PREFIX_PATH}/profile`)
            }}
            icon={<UserOutlined />}
          >
            <span>
              <span className="font-weight-normal">Profile</span>
            </span>
          </Menu.Item>
          <Menu.Item
            key={2}
            onClick={() => {
              history.push(`${APP_PREFIX_PATH}/change-password`)
            }}
            icon={<ToolOutlined />}
          >
            <span>
              <span className="font-weight-normal">Change password</span>
            </span>
          </Menu.Item>
          {isOwner && (
            <Menu.Item
              key={3}
              onClick={() => {
                history.push(`${APP_PREFIX_PATH}/billing/plans`)
              }}
              icon={<DollarCircleOutlined />}
            >
              <span>
                <span className="font-weight-normal">Billing</span>
              </span>
            </Menu.Item>
          )}
          <Menu.Item key={5} onClick={handleSignOut} disabled={isLoading}>
            <span>
              {isLoading ? <LoadingOutlined /> : <LogoutOutlined />}
              <span className="font-weight-normal">Sign out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  return (
    <div className="fixed right-20">
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={['click']}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <Avatar
              src={link}
              style={{ backgroundColor: '#2E9B72', fontSize: '20px' }}
            >
              {user.fName[0]}
            </Avatar>
          </Menu.Item>
        </Menu>
      </Dropdown>
    </div>
  )
}

export default NavProfile
