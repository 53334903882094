import { Route, Redirect } from 'react-router-dom'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import PropTypes from 'prop-types'

function AdminRoute({ isAdmin, ...path }) {
  return isAdmin ? <Route {...path} /> : <Redirect to={AUTH_PREFIX_PATH} />
}

export default AdminRoute
AdminRoute.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
}
