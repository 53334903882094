import request from 'auth/FetchInterceptor'
import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'react-query-client'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// notification function
const openNotification = (type, desc) => {
  notification[type]({
    message: type.charAt(0).toUpperCase() + type.slice(1),
    description: desc,
    placement: 'bottomLeft',
  })
}

// all team members

const getAllTeamMembers = () =>
  request({ url: `${process.env.REACT_APP_V2_API}/sales-rep` })

export const useGetAllTeamMembers = () =>
  useQuery('all-team-members', getAllTeamMembers, {
    retry: false,
    refetchOnWindowFocus: false,
  })

// add team members

const addTeamMember = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/team-member`,
    method: 'POST',
    data: data,
  })

export const useAddTeamMember = (from, handleAfterAdding) => {
  const history = useHistory()
  return useMutation(addTeamMember, {
    retry: false,
    onSuccess: (data) => {
      queryClient.invalidateQueries('onboarding-status')
      if (from === 'justcall') {
        const errors = []
        const limit = []
        const succ = []
        data?.data.forEach((d) => {
          if (d.error !== null && d.error.includes('Maximum')) {
            limit.push(d.email)
          } else if (d.error) {
            errors.push(d.error)
          } else {
            succ.push(d.email)
          }
        })
        succ.forEach((s) => {
          openNotification('success', `An invite request has been sent to ${s}`)
        })
        errors.map((e) => openNotification('error', e))
        if (limit.length !== 0) {
          openNotification(
            'error',
            'Maximum user limit for the trial plan already reached. Upgrade your plan to add more users'
          )
        }
        if (errors.length === 0 && handleAfterAdding) {
          handleAfterAdding(true)
        }
        if (history.location.pathname === '/app/invite-jc-team-members') {
          history.push('/app/dashboard')
        }
      } else {
        if (data?.data[0].error) {
          openNotification('error', data?.data[0].error)
          queryClient.setQueryData(
            ['error-add-team-members'],
            data?.data[0].error
          )
        }
        if (data?.data[0].success) {
          openNotification('success', data?.data[0].success)
          queryClient.removeQueries(['error-add-team-members'])
          handleAfterAdding(true)
        }
      }
      queryClient.invalidateQueries('all-team-members')
      queryClient.invalidateQueries('justcall-members')
      queryClient.invalidateQueries('assigned-user-to-campaign')
      queryClient.invalidateQueries('unassigned-user-to-campaign')
    },
  })
}

// query to get errors while adding team member

export const useGetErrorAddTeamMembers = () =>
  useQuery(['error-add-team-members'])

// remove team member

const removeTeamMember = (hash) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/${hash}`,
    method: 'delete',
    showSuccessNotification: true,
    successMessage: 'User successfully removed from team',
  })

export const useRemoveTeamMember = (handleCancel) =>
  useMutation(removeTeamMember, {
    retry: false,
    onSuccess: () => {
      handleCancel()
      queryClient.invalidateQueries('all-team-members')
      queryClient.invalidateQueries('justcall-members')
    },
  })

// get justcall agents
const getJustCallMembers = () =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/justcall-team-members`,
  })

export const useGetJustCallMembers = () => {
  const { accessToken, user } = useSelector((state) => state.authentication)
  return useQuery(['justcall-members'], () => getJustCallMembers(), {
    enabled: Boolean(accessToken) && user.role !== 2,
  })
}

// change mode of team member
const changeModeTeamMember = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/switch-mode/${data.id}`,
    data,
    method: 'PUT',
    showSuccessNotification: true,
    successMessage: 'Updated Successfully',
  })

export const useChangeModeTeamMember = (callback, resetFields) =>
  useMutation(changeModeTeamMember, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('all-team-members')
      queryClient.invalidateQueries('update-user-and-token')
      callback()
      resetFields()
    },
  })

// change role of team member
const changeRoleTeamMember = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/sales-rep/switch-role/${data.id}`,
    data,
    method: 'PUT',
    showSuccessNotification: true,
    successMessage: 'Updated Successfully',
  })

export const useChangeRoleTeamMember = () =>
  useMutation(changeRoleTeamMember, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('all-team-members')
    },
  })
