import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Pages from 'views'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { ErrorBoundary } from 'react-error-boundary'
import { CallProvider } from 'contexts/CallContext'
import { NotificationProvider } from 'contexts/NotificationContext'
import ErrorView from 'views/error-view'
import { useHelpwiseScript } from 'hooks'
import PropTypes from 'prop-types'
import useUserflow from 'hooks/useUserflow'

const myErrorHandler = (error, info) => {
  console.error('error', error)
  console.error('info', info)
}

const history = createBrowserHistory()

const themes = {
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return <ErrorView error={error} resetErrorBoundary={resetErrorBoundary} />
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

const resetErrorBoundary = () => {
  window.location.reload()
}

if (process.env.REACT_APP_TYPE === 'production') console.log = () => {}
function App() {
  useHelpwiseScript()
  useUserflow()

  // const [phbar, setphbar] = useState(() => !localStorage.getItem('ph-bar-1'))
  return (
    <Router history={history}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
        onReset={resetErrorBoundary}
      >
        <CallProvider>
          <div className="App">
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme="light"
              insertionPoint="styles-insertion-point"
            >
              <NotificationProvider>
                {/* {phbar && <ProductHunt setphbar={setphbar} />} */}
                <Switch>
                  <Route path="/" component={Pages} />
                </Switch>
              </NotificationProvider>
            </ThemeSwitcherProvider>
          </div>
        </CallProvider>
      </ErrorBoundary>
    </Router>
  )
}
export default App
