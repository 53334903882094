import {
  useEffect,
  useCallback,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react'
import { Device } from '@twilio/voice-sdk'
import { useSelector, useDispatch } from 'react-redux'
import { useGetCallStatus, useGetTwilioToken } from 'services/call-services'

import {
  setDevice,
  deviceRegistered,
  deviceUnregistered,
  deviceConfigurationStarted,
  deviceConfigured,
  deviceError,
  callInitiated,
  callStarted,
  callIntrupted,
  callDisconnected,
  callError,
  updateCallMuteStatus,
  setInputDeviceId,
  setOutputDeviceId,
  setInputDevices,
  setOutputDevices,
} from 'redux/calling'

import PropTypes from 'prop-types'

const CallContext = createContext()

export const useCall = () => useContext(CallContext)

// TODO network issues

export function CallProvider({ children }) {
  const { user } = useSelector((state) => state.authentication)
  const callStates = useSelector((state) => state.calling)
  const dispatch = useDispatch()

  const [sid, setSid] = useState(null)

  const { data: callStatus } = useGetCallStatus(sid)
  useGetTwilioToken()

  const {
    token,
    call,
    device,
    isDeviceRegistered,
    isDeviceConfiguring,
    isDeviceConfigured,
    selectedCampaign,
    isCallLoading,
    isCallOngoing,
  } = callStates

  const salesRepId = user?.id

  const getRemoteStream = useCallback(() => {
    if (call) {
      return call.getRemoteStream()
    }
    return null
  }, [call])

  function getAvailableOutputDevices() {
    if (device) {
      const devices = []
      device.audio.availableOutputDevices.forEach((d, i) => {
        devices.push({ id: i, device: d })
      })
      return devices
    }
    return []
  }

  function getAvailableInputDevices() {
    if (device) {
      const devices = []
      device.audio.availableInputDevices.forEach((d, i) => {
        devices.push({ id: i, device: d })
      })
      return devices
    }
    return []
  }

  function unsetInputDevice() {
    if (device) {
      device.audio
        .unsetInputDevice()
        .then(() => {
          console.info('Successfully unset Input')
        })
        .catch((e) => {
          console.log('Error unsetting device constraints', e)
        })
    }
  }

  const setInputDevice = useCallback(
    (id) => {
      if (device) {
        device.audio
          .setInputDevice(id)
          .then(() => {
            console.log({ id })
            dispatch(setInputDeviceId(id))
            console.info('Successfully set input device', id)
            localStorage.setItem('input-device-id', JSON.stringify(id))
          })
          .catch((e) => {
            console.log('error', e)
          })
      }
    },
    [device, dispatch]
  )

  const setOutputDevice = useCallback(
    (id) => {
      if (device) {
        device.audio.ringtoneDevices.set(id)
        device.audio.speakerDevices.set(id)
        dispatch(setOutputDeviceId(id))
        localStorage.setItem('output-device-id', JSON.stringify(id))
      }
    },
    [device, dispatch]
  )

  const initializeDevice = useCallback(() => {
    console.log('Initializing device...')
    const newDevice = new Device(String(token), {
      debug: true,
      answerOnBridge: true,
      codecPreferences: ['pcmu', 'opus'],
      tokenRefreshMs: '10000',
    })
    dispatch(setDevice(newDevice))
  }, [dispatch, token])

  const configureDevice = useCallback(() => {
    console.log('configuring device...')
    dispatch(deviceConfigurationStarted())
    device.on('registered', () => {
      dispatch(deviceRegistered())
    })

    if (!isDeviceRegistered && device.state !== 'registering') {
      device.register()
    }

    device.on('unregistered', () => {
      dispatch(deviceUnregistered())
    })

    device.on('error', (error) => {
      const { code } = error
      console.error(`code >>> ${code}: ${error.message}`)
      if (
        [31202, 31204, 31205, 31206, 31207, 20101, 20104].indexOf(code) > -1
      ) {
        dispatch(
          deviceError(
            'Token expired please refresh app to regenerate new token'
          )
        )
      } else {
        dispatch(deviceError(error.message))
      }
    })

    // this will run only when available device changes
    device.audio.on('deviceChange', () => {
      // const callStatus = localStorage.getItem("is-call-ongoing")
      if (JSON.parse(localStorage.getItem('is-call-ongoing'))) {
        const inputDevices = getAvailableInputDevices()
        const outputDevices = getAvailableOutputDevices()

        dispatch(setInputDevices(inputDevices))
        dispatch(setOutputDevices(outputDevices))

        const inputDeviceId = JSON.parse(
          localStorage.getItem('input-device-id')
        )
        const outputDeviceId = JSON.parse(
          localStorage.getItem('output-device-id')
        )

        localStorage.setItem('is-input-set', JSON.stringify(false))
        localStorage.setItem('is-output-set', JSON.stringify(false))

        inputDevices.forEach((d) => {
          if (d.id === inputDeviceId) {
            setInputDevice(inputDeviceId)
            localStorage.setItem('is-input-set', JSON.stringify(true))
          }
        })

        outputDevices.forEach((d) => {
          if (d.id === outputDeviceId) {
            setOutputDevice(outputDeviceId)
            localStorage.setItem('is-output-set', JSON.stringify(true))
          }
        })

        const isInputSet = JSON.parse(localStorage.getItem('is-input-set'))
        const isOutputSet = JSON.parse(localStorage.getItem('is-output-set'))

        if (!isInputSet) {
          setInputDevice('default')
        }

        if (!isOutputSet) {
          setOutputDevice('default')
        }
      }
    })

    dispatch(deviceConfigured())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, isDeviceRegistered, isCallOngoing, isCallLoading])

  const configureCall = useCallback(() => {
    call.on('error', (error) => {
      dispatch(callError(error.message))
    })

    call.on('mute', (isMuted) => {
      if (isMuted) {
        dispatch(updateCallMuteStatus(true))
      } else {
        dispatch(updateCallMuteStatus(false))
      }
    })

    call.on('accept', (c) => {
      const callSid = c.parameters.CallSid

      setSid(callSid)

      const inputDevices = getAvailableInputDevices()
      const outputDevices = getAvailableOutputDevices()

      dispatch(setInputDevices(inputDevices))
      dispatch(setOutputDevices(outputDevices))

      const inputDeviceId = JSON.parse(localStorage.getItem('input-device-id'))
      const outputDeviceId = JSON.parse(
        localStorage.getItem('output-device-id')
      )

      console.log('here >>>> ', { inputDeviceId, outputDeviceId })

      // TODO check if this inputdeviceid is available in current list of devices
      if (inputDeviceId) {
        let isDeviceAvailable = false

        inputDevices.forEach((d) => {
          if (d.id === inputDeviceId) {
            isDeviceAvailable = true
            setInputDevice(inputDeviceId)
            localStorage.setItem('is-input-set', JSON.stringify(true))
          }
        })

        if (!isDeviceAvailable) {
          setInputDevice('default')
        }
      } else {
        console.log('setting input device')
        setInputDevice('default')
      }

      if (outputDeviceId) {
        let isDeviceAvailable = false

        outputDevices.forEach((d) => {
          if (d.id === outputDeviceId) {
            isDeviceAvailable = true
            setOutputDevice(outputDeviceId)
            localStorage.setItem('is-output-set', JSON.stringify(true))
          }
        })

        if (!isDeviceAvailable) {
          setOutputDevice('default')
        }
      } else {
        setOutputDevice('default')
      }

      // const callStatusInterval = setInterval(async () => {
      //     const { data } = await axios.get(`${process.env.REACT_APP_V2_API}/v1/calls/getStatus?callSid=${callSid}`)

      //     if (data.status === "in-progress") {
      //         const callDetails = { dcName: data?.name, dcGender: data?.gender }
      //         dispatch(callStarted(callDetails))
      //         clearInterval(callStatusInterval)
      //     }

      //     if (data.status === "busy" || data.status === "no-answer") {
      //         dispatch(callDisconnected());
      //         clearInterval(callStatusInterval)
      //     }
      // }, 2000)
      // window.callStatusInterval = callStatusInterval;
    })

    call.on('disconnect', () => {
      unsetInputDevice()
      console.log('disconnected event from twilio')
      dispatch(callDisconnected())
      // clearInterval(window.callStatusInterval)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call])

  // check here if credits are valid
  const connectCall = useCallback(async () => {
    if (device) {
      const initiatedCall = await device.connect({
        params: { salesRepId, campaignMappingId: selectedCampaign },
      })
      dispatch(callInitiated(initiatedCall))
    }
  }, [device, dispatch, salesRepId, selectedCampaign])

  const disconnectCall = useCallback(() => {
    try {
      if (call) {
        call.disconnect()
        // device.destroy()
      }
    } catch (error) {
      dispatch(callError(error.message))
    }
  }, [call, dispatch])

  // call this function to disconnected when user is not connected
  const intruptCall = useCallback(() => {
    try {
      if (call) {
        dispatch(callIntrupted())
        call.disconnect()
      }
    } catch (error) {
      dispatch(callError(error.message))
    }
  }, [call, dispatch])

  const muteCall = useCallback(() => {
    try {
      if (call) {
        call.mute()
      }
    } catch (error) {
      dispatch(callError(error.message))
    }
  }, [call, dispatch])

  const unMuteCall = useCallback(() => {
    try {
      if (call) {
        call.mute(false)
      }
    } catch (error) {
      dispatch(callError(error.message))
    }
  }, [call, dispatch])

  useEffect(() => {
    if (callStatus?.data?.status === 'in-progress') {
      setSid(null)
      const callDetails = {
        dcName: callStatus?.data?.name,
        dcGender: callStatus?.data?.gender,
      }
      dispatch(callStarted(callDetails))
    } else if (
      callStatus?.data?.status === 'busy' ||
      callStatus?.data?.status === 'no-answer'
    ) {
      setSid(null)
      dispatch(callDisconnected())
    }
  }, [callStatus, dispatch])

  useEffect(() => {
    if (token && !device) {
      initializeDevice()
    } else if (device && !isDeviceConfigured && !isDeviceConfiguring) {
      configureDevice()
    } else if (call) {
      configureCall()
    }
  }, [
    configureDevice,
    device,
    initializeDevice,
    isDeviceConfigured,
    token,
    call,
    configureCall,
    isDeviceConfiguring,
  ])

  const value = useMemo(
    () => ({
      connectCall,
      disconnectCall,
      intruptCall,
      muteCall,
      unMuteCall,
      getRemoteStream,
      setInputDevice,
      setOutputDevice,
    }),
    [
      connectCall,
      disconnectCall,
      getRemoteStream,
      intruptCall,
      muteCall,
      setInputDevice,
      setOutputDevice,
      unMuteCall,
    ]
  )

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>
}

CallProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
