import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Tooltip } from 'antd'
import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons'
// import CommentBox from './CommentBox/CommentBox'
// import AllComments from './AllComments/AllComments'
// import jsonData from './comments.json'
// import CommentHeader from './CommentHeader'
// import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const RightSidebar = forwardRef((props) => {
  const { searchArray, playRecording, handleClose } = props
  // const [comments, setComments] = useState({})
  // const [replies, setReplies] = useState({})
  // const [utilData, setUtilData] = useState({})

  // const { commentBoxRef, audioPlayerRef } = ref.current
  // const commentBoxContainerRef = useRef(null)
  // const commentListRef = useRef(null)

  // const { fName, lName } = useSelector((state) => state.authentication.user);
  // let currently_logged_in =
  // jsonData.comments[keys[0]].fName + " " + jsonData.comments[keys[0]].lName;
  // const masterhash = '943313a74aeb20989ee1a37a33b85090f05690f4'

  // const commentComponentRef = useRef({
  //   commentBoxRef,
  //   commentBoxContainerRef,
  // })
  // const commentBoxContainerAudioplayerRef = useRef({
  //   commentBoxContainerRef,
  //   audioPlayerRef,
  //   commentListRef,
  // })

  // const getComments = () => {
  //   console.log('comments api', jsonData.status)
  //   const wordsMentioned = []
  //   const keys = Object.keys(jsonData.comments)
  //   if (keys.length > 0) {
  //     for (const id in jsonData.comments) {
  //       if (
  //         jsonData.comments[id] &&
  //         jsonData.comments[id].time_mentioned.length
  //       ) {
  //         wordsMentioned.push(parseFloat(jsonData.comments[id].time_mentioned))
  //       }
  //     }
  //     setUtilData({
  //       callSid: jsonData.comments[keys[0]].callSid,
  //       callernumber: jsonData.comments[keys[0]].callernumber,
  //       clientnumber: jsonData.comments[keys[0]].clientnumber,
  //       companyid: jsonData.comments[keys[0]].companyid,
  //       transcriptionid: jsonData.comments[keys[0]].transcriptionid,
  //     })
  //   }
  //   props.getTimeMentioned(wordsMentioned)
  //   setComments(jsonData.comments)
  //   setReplies(jsonData.reply)
  // }

  // useEffect(() => {
  //   console.log('local state changed in comments section')
  //   // console.log("comments", comments);
  //   // console.log("replies", replies);
  // }, [comments, replies])

  // useEffect(() => {
  //   console.log('comments api called')
  //   getComments()
  //   // props.showNotification();
  // }, [])

  // function modifyInnerText(
  //   node,
  //   innerText,
  //   mentionArray,
  //   mentionedTime,
  //   mentionedElement,
  //   pos
  // ) {
  //   if (node.nodeName === 'SPAN') {
  //     // console.log("span");
  //     if (node.classList.contains('mention_person')) {
  //       //   console.log("mention_person", node.textContent);
  //       let name = node.textContent

  //       if (name.substr(0, 1) === '@') {
  //         name = name.substr(1)
  //       }
  //       mentionArray.push(masterhash)
  //       pos = innerText.indexOf(name, pos)
  //       if (pos !== 0)
  //         innerText = `${innerText.slice(
  //           0,
  //           pos - 1
  //         )}@{{${name}}}@${innerText.slice(pos + name.length)}`
  //       else innerText = `@{{${name}}}@${innerText.slice(pos + name.length)}`
  //       pos += name.length
  //     } else if (node.classList.contains('time_mentioned')) {
  //       //   console.log("time_mention", node.textContent);
  //       mentionedTime = node.dataset.time
  //       mentionedElement = node.dataset.mentionedElement
  //       const pos1 = innerText.indexOf(node.textContent)
  //       innerText = `${innerText.slice(0, pos1)}#{{${
  //         node.textContent
  //       }}}#${innerText.slice(pos + node.textContent.length)}`
  //     }
  //   } else if (node.nodeName === 'DIV' || node.nodeName === 'P') {
  //     // console.log("#text", node.textContent);
  //     // body += node.textContent;
  //     const divChildListOfNodes = node.childNodes
  //     divChildListOfNodes.forEach((childNode) => {
  //       if (childNode.nodeName === 'SPAN') {
  //         if (childNode.classList.contains('mention_person')) {
  //           //   console.log("mention_person", node.textContent);
  //           let name = childNode.textContent

  //           if (name.substr(0, 1) === '@') {
  //             name = name.substr(1)
  //           }
  //           mentionArray.push(masterhash)

  //           pos = innerText.indexOf(name, pos)
  //           if (pos !== 0)
  //             innerText = `${innerText.slice(
  //               0,
  //               pos - 1
  //             )}@{{${name}}}@${innerText.slice(pos + name.length)}`
  //           else
  //             innerText = `@{{${name}}}@${innerText.slice(pos + name.length)}`
  //           pos += name.length
  //         } else if (childNode.classList.contains('time_mentioned')) {
  //           //   console.log("time_mention", node.textContent);

  //           mentionedTime = childNode.dataset.time
  //           mentionedElement = childNode.dataset.mentionedElement
  //           const pos1 = innerText.indexOf(childNode.textContent)
  //           innerText = `${innerText.slice(0, pos1)}#{{${
  //             childNode.textContent
  //           }}}#${innerText.slice(pos + childNode.textContent.length)}`
  //         }
  //       }
  //     })
  //   }
  //   return {
  //     innerText: innerText,
  //     mention_array: mentionArray,
  //     mentioned_element: mentionedElement,
  //     mentioned_time: mentionedTime,
  //     pos: pos,
  //   }
  // }

  // function setMentionDetails(mentionedElement, mentionedTime, mentionArray) {
  //   const mentionDetails = {}
  //   mentionDetails.mentioned_element = mentionedElement
  //   mentionDetails.time_mentioned = mentionedTime
  //   mentionDetails.mention_hash = mentionArray
  //   return mentionDetails
  // }

  // -------------function for submitting new comment-------------//
  // function commentSubmitHandler(e) {
  //   // console.log("handler", e);
  //   // e.stopPropagation();
  //   // let listOfNodes = e.target.previousElementSibling.childNodes;
  //   // let innerText = e.target.previousElementSibling.innerText;
  //   const listOfNodes = e.target.parentElement.previousElementSibling.childNodes
  //   let { innerText } = e.target.parentElement.previousElementSibling
  //   console.log('new comment body', innerText)
  //   let mentionArray = []
  //   let mentionedTime = ''
  //   let mentionedElement = ''
  //   console.log('listofnodes', listOfNodes)

  //   if (innerText === '') {
  //     props.showNotification('error', 'Comment can not be empty')
  //     return
  //   }
  //   // console.log(comment_box_ref)

  //   let pos = 0
  //   listOfNodes.forEach((node) => {
  //     console.log(node)

  //     const result = modifyInnerText(
  //       node,
  //       innerText,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos
  //     )
  //     ;({ innerText, mentionArray, mentionedTime, mentionedElement, pos } =
  //       result)
  //   })
  //   console.log(
  //     `mentioned_time-${mentionedTime}, mentioned_element-${mentionedElement}, mention_array-${mentionArray}`
  //   )
  //   console.log('new innerText', innerText)
  //   commentBoxRef.current.textContent = ''
  //   const mentionDetails = setMentionDetails(
  //     mentionedElement,
  //     mentionedTime,
  //     mentionArray
  //   )

  //   console.log('going to change the state')
  //   setComments((prevState) => ({
  //     ...prevState,
  //     new_comment: {
  //       ...utilData,
  //       ...mentionDetails,
  //       body: innerText,
  //       fname: 'Deep',
  //       lname: 'Shah',
  //       name_initials: 'DS',
  //       userid_masterhash: masterhash,
  //       _id: {
  //         $oid: 'new_comment',
  //       },
  //     },
  //   }))

  //   // here when we add a new comment through post api, we can run the function for highlighting the newly comment added in response to a success of post operation
  // }
  // --------------------------------//

  // -------------function for submitting new reply on a comment--------------//
  // function replySubmitHandler(id, e) {
  //   // console.log(e);
  //   const listOfNodes = e.target.parentElement.previousElementSibling.childNodes
  //   let newReplyBody = e.target.parentElement.previousElementSibling.innerText
  //   console.log('new reply body', newReplyBody)
  //   let mentionArray = []
  //   let mentionedTime = ''
  //   let mentionedElement = ''
  //   console.log(listOfNodes)
  //   // console.log(newReplyBody)
  //   if (!newReplyBody || newReplyBody.length === 0) {
  //     props.showNotification('error', 'Reply Body can not be empty')
  //     return
  //   }
  //   let pos = 0
  //   listOfNodes.forEach((node) => {
  //     console.log(node)

  //     const result = modifyInnerText(
  //       node,
  //       newReplyBody,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos
  //     )
  //     ;({
  //       innerText: newReplyBody,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos,
  //     } = result)
  //   })
  //   console.log('new encoded body', newReplyBody)
  //   e.target.parentElement.parentElement.style.display = 'none'
  //   e.target.parentElement.parentElement.nextElementSibling.firstChild.style.display =
  //     'block'
  //   commentBoxContainerRef.current.style.display = 'block'
  //   commentListRef.current.className = 'comments'

  //   const mentionDetails = setMentionDetails(
  //     mentionedElement,
  //     mentionedTime,
  //     mentionArray
  //   )

  //   setReplies((prevState) => {
  //     // console.log(id);
  //     if (id in prevState) {
  //       return {
  //         ...prevState,
  //         [id]: [
  //           ...prevState[id],
  //           {
  //             ...utilData,
  //             ...mentionDetails,
  //             body: newReplyBody,
  //             fname: 'Deep',
  //             lname: 'Shah',
  //             name_initials: 'DS',
  //             userid_masterhash: masterhash,
  //           },
  //         ],
  //       }
  //     }
  //     return {
  //       ...prevState,
  //       [id]: [
  //         {
  //           ...utilData,
  //           ...mentionDetails,
  //           body: newReplyBody,
  //           fname: 'Deep',
  //           lname: 'Shah',
  //           name_initials: 'DS',
  //           userid_masterhash: masterhash,
  //         },
  //       ],
  //     }
  //   })
  // }
  // ------------------------------//

  // -------------function for saving edits to a comment--------------//
  // function saveEditedComment(id, event) {
  //   // console.log(event.target.parentElement.previousElementSibling);
  //   const listOfNodes =
  //     event.target.parentElement.previousElementSibling.childNodes
  //   let newComment = event.target.parentElement.previousElementSibling.innerText
  //   console.log('new reply body', newComment)
  //   let mentionArray = []
  //   let mentionedTime = ''
  //   let mentionedElement = ''
  //   console.log(listOfNodes)

  //   if (newComment === '') {
  //     props.showNotification('error', 'Comment can not be empty')
  //     return
  //   }
  //   let pos = 0
  //   listOfNodes.forEach((node) => {
  //     console.log(node)

  //     const result = modifyInnerText(
  //       node,
  //       newComment,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos
  //     )
  //     ;({
  //       innerText: newComment,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos,
  //     } = result)
  //   })
  //   console.log('new encoded body', newComment)
  //   // here while editing a comment, we need to take care of the mention_hash property and mention
  //   // when there are ids in mention_array, that means that there will be mention_has property and no mention property
  //   // but if there is no one in mention_array then there will be no mention_hash property and there will be mention assigned to null
  //   setComments((prevState) => ({
  //     ...prevState,
  //     [id]: {
  //       ...prevState[id],
  //       body: newComment,
  //       time_mentioned: mentionedTime,
  //       mentioned_element: mentionedElement,
  //       mention_hash: mentionArray,
  //     },
  //   }))
  //   props.showNotification('success', 'Comment Updated')
  //   // createNotification('success',"Comment edited")
  //   const commentEditContainer = document.querySelector(
  //     `.comment_edit_container-${id}`
  //   )
  //   const commentBody = document.querySelector(`.comment_body-${id}`)
  //   commentBody.style.display = 'block'
  //   commentEditContainer.style.display = 'none'
  //   commentBoxContainerRef.current.style.display = 'block'
  //   commentListRef.current.className = 'comments'
  // }
  // ---------------------------//

  // ---------------function for saving edits to a reply-----------------//
  // function saveEditedReply(id, index, event) {
  //   // console.log(event.target.parentElement.previousElementSibling.childNodes);
  //   const listOfNodes =
  //     event.target.parentElement.previousElementSibling.childNodes
  //   let newReplyBody =
  //     event.target.parentElement.previousElementSibling.innerText
  //   console.log('new reply body', newReplyBody)
  //   let mentionArray = []
  //   let mentionedTime = ''
  //   let mentionedElement = ''
  //   console.log(listOfNodes)

  //   if (newReplyBody === '') {
  //     props.showNotification('error', 'Reply can not be empty')
  //     return
  //   }
  //   let pos = 0
  //   listOfNodes.forEach((node) => {
  //     console.log(node)

  //     const result = modifyInnerText(
  //       node,
  //       newReplyBody,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos
  //     )
  //     ;({
  //       innerText: newReplyBody,
  //       mentionArray,
  //       mentionedTime,
  //       mentionedElement,
  //       pos,
  //     } = result)
  //   })

  //   console.log('new encoded reply', newReplyBody)

  //   setReplies((prevState) => ({
  //     ...prevState,
  //     [id]: [
  //       ...prevState[id].slice(0, index),
  //       {
  //         ...prevState[id][index],
  //         body: newReplyBody,
  //         time_mentioned: mentionedTime,
  //         mentioned_element: mentionedElement,
  //         mention_hash: mentionArray,
  //       },
  //       ...prevState[id].slice(index + 1),
  //     ],
  //   }))
  //   const replyEditContainer = document.querySelector(
  //     `.reply_edit_container-${id}-${index}`
  //   )
  //   const commentBody = document.querySelector(`.comment_body-${id}-${index}`)
  //   commentBody.style.display = 'block'
  //   replyEditContainer.style.display = 'none'
  //   commentBoxContainerRef.current.style.display = 'block'
  //   commentListRef.current.className = 'comments'
  // }
  // ------------------------//

  // function confirmDelete(actionType, data, event) {
  // confirmAlert({
  //   customUI: ({ onClose }) => {
  //     return (
  //       <div className="custom-ui">
  //         <h1>Are you sure?</h1>
  //         <p>You want to delete this file?</p>
  //         <button onClick={onClose}>No</button>
  //         <button
  //           onClick={() => {
  //             deleteComment(action_type, data, event);
  //             onClose();
  //           }}
  //         >
  //           Yes, Delete it!
  //         </button>
  //       </div>
  //     );
  //   },
  // });
  // }

  // ----------------function for deleting a comment--------------------//
  // function deleteComment(actionType, data, event) {
  //   event.target.parentElement.style.display = 'none'

  //   if (actionType === 'comment') {
  //     const id = data[0]
  //     setComments((prevState) => ({
  //       ...prevState,
  //       [id]: null,
  //     }))
  //     setReplies((prevState) => ({
  //       ...prevState,
  //       [id]: null,
  //     }))
  //   } else {
  //     const [id, index] = data
  //     setReplies((prevState) => ({
  //       ...prevState,
  //       [id]: [...prevState[id], (prevState[id][index] = null)],
  //     }))
  //   }

  //   props.showNotification('success', 'Comment Deleted')
  // }

  // function ackComment(commentId, mHash, event) {
  //   setComments((prevState) => {
  //     if ('acklist_hash' in prevState[commentId]) {
  //       return {
  //         ...prevState,
  //         [commentId]: {
  //           ...prevState[commentId],
  //           acklist_hash: [...prevState[commentId].acklist_hash, masterhash],
  //           acknowledgment:
  //             prevState[commentId].acklist_hash.length + 1 ===
  //             prevState[commentId].mention_hash.length
  //               ? 1
  //               : 0,
  //         },
  //       }
  //     }
  //     return {
  //       ...prevState,
  //       [commentId]: {
  //         ...prevState[commentId],
  //         acklist_hash: [mHash],
  //         acknowledgment:
  //           prevState[commentId].mention_hash.length === 1 ? 1 : 0,
  //       },
  //     }
  //   })

  //   props.showNotification('success', 'Comment Acknowledged')
  // }

  return (
    <div
      className="d-flex flex-column bg-white shadow-sm rounded-sm border mb-4"
      style={{ height: '525px', maxHeight: '525px' }}
    >
      <div className="border-bottom d-flex align-items-center p-2 justify-content-between">
        <div style={{ marginLeft: '6px', fontSize: '14px' }}>
          Search results
        </div>
        <Tooltip title="Close">
          <Button
            className="text-muted"
            onClick={() => {
              handleClose()
            }}
            icon={<CloseOutlined />}
            type="text"
            shape="circle"
            size="small"
          />
        </Tooltip>
      </div>
      <div className="p-3" style={{ overflowY: 'auto' }}>
        {searchArray.map((s) => {
          console.log(s)
          const bufferWords = 5
          const startEllipsis = s.wordIndex !== 0
          const endEllipsis = s.monologue.length - 1 - s.wordIndex > bufferWords
          let displaySentence = ``

          for (
            let i =
              s.wordIndex - bufferWords < 0 ? 0 : s.wordIndex - bufferWords;
            i < s.monologue.length && i <= s.wordIndex + bufferWords;
            i += 1
          ) {
            if (i === s.wordIndex) {
              displaySentence += `<span style=background-color:yellow>${`${s.monologue[i].word} `}</span>`
            } else {
              displaySentence += `<span>${`${s.monologue[i].word} `}</span>`
            }
          }
          if (startEllipsis) {
            displaySentence = `...${displaySentence}`
          }
          if (endEllipsis) {
            displaySentence = `${displaySentence}...`
          }
          return (
            <Row
              gutter={[8, 8]}
              className="cursor-pointer mb-3"
              justify="space-between"
              onClick={() => {
                const hms = s.timeStamp.split(':')
                const seconds =
                  parseInt(hms[0], 10) * 60 * 60 +
                  parseInt(hms[1], 10) * 60 +
                  parseInt(hms[2], 10)
                playRecording(seconds)
              }}
            >
              <Col
                xs={12}
                style={{
                  color: s.person === 'Customer Agent' ? ' #f090d2' : '#d2b9fa',
                }}
              >
                {s.person}
              </Col>
              <Col xs={8} className="text-muted">
                <ClockCircleOutlined /> {s.timeStamp}
              </Col>
              <Col
                xs={24}
                dangerouslySetInnerHTML={{ __html: `"${displaySentence}"` }}
              />
            </Row>
          )
        })}
      </div>
      {/* <CommentHeader />
      <AllComments
        comments={comments}
        replies={replies}
        replySubmitHandler={() => replySubmitHandler()}
        saveEditedComment={() => saveEditedComment()}
        saveEditedReply={() => saveEditedReply()}
        deleteComment={() => confirmDelete()}
        ref={commentBoxContainerAudioplayerRef}
        playRecording={(d) => props.playRecording(d)}
        peopleToShow={props.people}
        setCursor={(d) => props.setCursor(d)}
        ackComment={() => ackComment()}
      />
      <CommentBox
        commentSubmitHandler={() => commentSubmitHandler()}
        peopleToShow={props.people}
        ref={commentComponentRef}
      /> */}
    </div>
  )
})

export default RightSidebar
RightSidebar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  // getTimeMentioned: PropTypes.func.isRequired,
  // showNotification: PropTypes.func.isRequired,
  searchArray: PropTypes.array.isRequired,
  playRecording: PropTypes.func.isRequired,
  // setCursor: PropTypes.func.isRequired,
  // people: PropTypes.array.isRequired,
}
