/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { APP_NAME, APP_PREFIX_PATH } from 'configs/AppConfig'
import logo from 'assets/images/Logo/dialworks-logo.png'
import { Tag } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

function Logo() {
  const { isImpersonating } = useSelector((state) => state.authentication)
  const history = useHistory()
  return (
    <div
      className="logo"
      style={{ width: '250px' }}
      onClick={() => history.push(`${APP_PREFIX_PATH}`)}
    >
      <img
        src={logo}
        alt={`${APP_NAME} logo`}
        style={{ width: '200px', cursor: 'pointer' }}
      />
      <Tag
        color="#87d068"
        style={{ marginLeft: '10px' }}
        className={`${isImpersonating ? '' : 'invisible'}`}
      >
        Impersonating Profile
      </Tag>
    </div>
  )
}

export default Logo
