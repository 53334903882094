import { useEffect, useReducer, useCallback } from 'react'

// TODO useRef
const initialState = {
  isRunning: false,
  seconds: 0,
  minutes: 0,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return { ...state, isRunning: true }
    case 'increment':
      return {
        ...state,
        seconds: action.payload.seconds,
        minutes: action.payload.minutes,
      }
    case 'stop':
      return { ...state, isRunning: false }
    case 'reset':
      return { ...initialState }
    default:
      throw new Error(`Invalid action type ${action.type} in useTimer`)
  }
}

function useTimer() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { isRunning, seconds, minutes } = state

  const startTimer = useCallback(() => {
    dispatch({ type: 'start' })
  }, [])

  const stopTimer = useCallback(() => {
    dispatch({ type: 'stop' })
  }, [])

  const resetTimer = useCallback(() => {
    dispatch({ type: 'reset' })
  }, [])

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        if (seconds >= 59) {
          dispatch({
            type: 'increment',
            payload: { seconds: 0, minutes: minutes + 1 },
          })
        } else {
          dispatch({
            type: 'increment',
            payload: { seconds: seconds + 1, minutes: minutes },
          })
        }
      }, [1000])
      return () => clearInterval(interval)
    }

    return undefined
  }, [isRunning, minutes, seconds])

  return {
    startTimer,
    stopTimer,
    resetTimer,
    totalSeconds: minutes * 60 + seconds,
    numSeconds: seconds,
    numMinutes: minutes,
    seconds: `0${seconds}`.slice(-2),
    minutes: `0${minutes}`.slice(-2),
  }
}

export default useTimer
