/* eslint-disable no-unsafe-optional-chaining */
import { useState, memo, useEffect, useCallback } from 'react'
import { Collapse, Drawer, Button, Progress } from 'antd'
import {
  useGetOnboardingStatus,
  useGetOnboardingStepperStatus,
} from 'services/personlised-services'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CheckCircleFilled,
  CloseOutlined,
  BulbFilled,
  // DownOutlined,
} from '@ant-design/icons'
// import { RiTimerLine } from 'react-icons/ri'
import OnboardingSideCard from 'components/onboarding-components/OnboardingSideCard'
import { changeOnboardingMenuStatus } from 'redux/preferences'
import { useSelector, useDispatch } from 'react-redux'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import CreateProfileStep from 'components/onboarding-components/CreateProfileStep'
import FillFormStep from 'components/onboarding-components/FillFormStep'
import CampaignStep from 'components/onboarding-components/CampaignStep'
import EmailVerfiedStep from 'components/onboarding-components/EmailVerfiedStep'
import FirstCallStep from 'components/onboarding-components/FirstCallStep'
import { motion } from 'framer-motion'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const { Panel } = Collapse

function OnboardingStatus() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { onboardingMenuStatus } = useSelector(
    (state) => state.preferences.userExperiencePreferences
  )
  // dispatch(changeOnboardingMenuStatus(true));

  const { data, isLoading } = useGetOnboardingStatus()
  const { data: stepperData, isLoading: isStepperLoading } =
    useGetOnboardingStepperStatus()

  const [loadingColapse, setLoadingColapse] = useState(true)

  // const [visible, setVisible] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true)
  const [panelState, setPanelState] = useState()

  const getBadgeCount = (list) => {
    let count = 0
    if (list) {
      Object.entries(list).forEach((d) => {
        if (d[1] === false) {
          count += 1
        }
      })
      if (data.fillForm === true && stepperData?.data?.steps?.length !== 3) {
        count += 1
      }
    }
    return count
  }
  useEffect(() => {
    if (data?.data) {
      if (!data.data.emailVerified) {
        setPanelState('emailVerified')
      } else if (
        !data?.data.fillForm ||
        localStorage.getItem('open-fillform')
      ) {
        setPanelState('fillForm')
        if (localStorage.getItem('open-fillform')) {
          localStorage.removeItem('open-fillform')
        }
      } else if (!data.data.campaign) {
        setPanelState('campaign')
      } else if (!data.data.call) {
        setPanelState('call')
      }
    }
    // console.log(panelState);
  }, [data, dispatch, location])

  function trialCallCampaign() {
    history.push(
      `${APP_PREFIX_PATH}/training/call/${data?.data?.trialCampaign?.campaignId}?mapping=${data?.data?.trialCampaign?.id}`
    )
  }

  const steps = data?.data
  const badgeCount = getBadgeCount(steps)
  const panelHeaderIcon = (s) => {
    if (data?.data[s.name]) {
      // return s.name === 'fillForm' && stepperData?.data?.steps?.length !== 3 ? (
      //   <RiTimerLine style={{ fontSize: '22px' }} />
      // ) : (
      return (
        <CheckCircleFilled style={{ color: '#44C13C', fontSize: '22px' }} />
      )
      // )
    }
    return <CheckCircleFilled style={{ color: '#E3E6EB', fontSize: '22px' }} />
  }
  const panelHeaderTextDecor = (s) => {
    if (data?.data[s.name]) {
      // return s.name === 'fillForm' && stepperData?.data?.steps?.length !== 3
      //   ? null
      return 'line-through'
    }
    return null
  }

  useEffect(() => {
    const test = window.setTimeout(() => {
      setLoadingColapse(false)
    }, 2000)
    return () => {
      clearInterval(test)
    }
  }, [loadingColapse])

  const showDrawer = useCallback(() => {
    setLoadingColapse(true)
    setButtonVisible(false)
    dispatch(changeOnboardingMenuStatus(true))
  }, [dispatch])

  const onClose = useCallback(() => {
    dispatch(changeOnboardingMenuStatus(false))
    setTimeout(() => setButtonVisible(true), 1000)
  }, [dispatch])

  const timeShower = (activeValue) => {
    if (activeValue?.timeQuantity === 'hr') {
      return activeValue?.timeLeft > 1
        ? `${activeValue?.timeLeft} hours`
        : `${activeValue?.timeLeft} hour`
    }
    return activeValue?.timeLeft > 1
      ? `${activeValue?.timeLeft} minutes`
      : '1 minute'
  }

  useEffect(() => {
    if (onboardingMenuStatus) {
      if (!location?.state?.openDrawer) {
        return () => onClose()
      }
      setPanelState('fillForm')
      history.push({ state: { openDrawer: false } })
    }
    return null
  }, [onClose, location, onboardingMenuStatus, history])

  const onboardingSteps = [
    {
      name: 'createProfile',
      title: 'Create an account',
      content: <CreateProfileStep />,
    },
    {
      name: 'emailVerified',
      title: 'Verify your email',
      content: <EmailVerfiedStep emailVerified={data?.data.emailVerified} />,
    },
    {
      name: 'fillForm',
      title: 'Complete questionnaire',
      content: (
        <FillFormStep
          fillForm={data?.data.fillForm}
          // createdCampaign={data?.data.campaign}
          // callsLeft={data?.data?.trialCallLeft}
          // trialCampaignId={data?.data?.trialCampaign?.id}
          // trialCallCampaign={() => trialCallCampaign()}
          // timeLeft={timeShower(stepperData?.data?.activeValue)}
          stepperData={stepperData?.data}
          // isStepperLoading={isStepperLoading}
        />
      ),
    },
    {
      name: 'campaign',
      title: 'Create campaign',
      content: <CampaignStep createdCampaign={data?.data.campaign} />,
    },
    {
      name: 'call',
      title: 'Make your first training call',
      content: (
        <FirstCallStep
          fillForm={data?.data?.fillForm}
          createdCampaign={data?.data?.campaign}
          callsLeft={data?.data?.trialCallLeft}
          trialCampaignId={data?.data?.trialCampaign?.id}
          trialCallCampaign={() => trialCallCampaign()}
          // timeLeft={timeShower(stepperData?.data?.activeValue)}
          stepperData={stepperData?.data}
          stepperDataLength={stepperData?.data?.steps?.length}
        />
      ),
    },
  ]

  return (
    badgeCount > 0 && (
      <>
        {!location.pathname.includes('analytics') && (
          <OnboardingSideCard
            data={data?.data}
            stepperData={stepperData?.data}
            timeShower={timeShower}
            callsLeft={data?.data?.trialCallLeft}
            showDrawer={() => showDrawer()}
            setPanelState={setPanelState}
            isLoading={isLoading}
            isStepperLoading={isStepperLoading}
            trialCallCampaign={() => trialCallCampaign()}
          />
        )}
        <motion.div animate={{ opacity: buttonVisible ? 1 : 0 }}>
          <Button
            type="primary"
            className="position-fixed mb-2"
            icon={<BulbFilled style={{ fontSize: '16px' }} />}
            style={{
              right: 100,
              bottom: 20,
              borderRadius: '32px',
              boxShadow: ' 0px 4px 15px rgba(0, 0, 0, 0.31)',
              display: 'flex',
              alignItems: 'center',
              padding: '16px 20px',
            }}
            onClick={() => showDrawer()}
          >
            <span style={{ fontSize: '16px', fontWeight: '600px' }}>
              Dialworks Quickstart
            </span>
            {badgeCount > 0 && (
              <span
                style={{
                  position: 'absolute',
                  backgroundColor: 'red',
                  borderRadius: '50%',
                  width: '17px',
                  fontSize: '12px',
                  right: '-5px',
                  top: '-5px',
                }}
              >
                {badgeCount}
              </span>
            )}
          </Button>
        </motion.div>
        <Drawer
          autoFocus
          title={
            <div
              style={{
                color: 'white',
                textAlign: 'center',
              }}
            >
              <CloseOutlined
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '20px',
                  fontSize: '18px',
                }}
                onClick={() => onClose()}
              />
              <p
                className="text-white font-weight-bolder mb-0"
                style={{ fontSize: '25px' }}
              >
                Dialworks Quickstart
              </p>

              {loadingColapse ? null : (
                <>
                  <span style={{ fontSize: '14px' }}>
                    {stepperData?.data?.steps?.length === 3
                      ? (5 - badgeCount) * 20
                      : (5 - badgeCount) * 20 +
                        (stepperData?.data?.activeValue?.percentageCompleted *
                          20) /
                          100}
                    %
                  </span>
                  <Progress
                    style={{ width: '65%', margin: '10px' }}
                    percent={
                      stepperData?.data?.steps?.length === 3
                        ? (5 - badgeCount) * 20
                        : (5 - badgeCount) * 20 +
                          (stepperData?.data.activeValue.percentageCompleted *
                            20) /
                            100
                    }
                    strokeColor="#DB4E82"
                    showInfo={false}
                  />
                </>
              )}
            </div>
          }
          closable={false}
          placement="right"
          mask={false}
          // maskClosable={true}
          // getContainer={false}
          keyboard
          headerStyle={{
            background: 'linear-gradient(100deg, #1D30B5 0%, #5A6FE4 100%)',
            borderRadius: 0,
            height: '150px',
            padding: 0,
          }}
          style={{
            top: '70px',
          }}
          bodyStyle={{ padding: 0 }}
          visible={onboardingMenuStatus && badgeCount > 0}
        >
          <Collapse
            expandIconPosition="right"
            accordion
            ghost
            activeKey={panelState}
            onChange={(e) => setPanelState(e)}
            style={{
              position: 'absolute',
              top: '130px',
              margin: '0px 28px 18px 32px',
              overflow: 'visible',
              height: '100%',
            }}
          >
            {onboardingSteps.map((s) =>
              loadingColapse ? (
                <div
                  style={{
                    width: 316,
                    height: 32,
                    margin: '0px 0px 50px 0px',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                  }}
                >
                  <Skeleton
                    style={{ height: 62, top: '-4px', borderRadius: '8px' }}
                    baseColor="#f3f4f7"
                    highlightColor="#e8e9eb"
                  />
                </div>
              ) : (
                <Panel
                  key={s.name}
                  header={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {panelHeaderIcon(s)}
                      <span
                        style={{
                          fontWeight: '600',
                          marginLeft: '10px',
                          textDecoration: panelHeaderTextDecor(s),
                        }}
                      >
                        {s.title}
                      </span>
                    </div>
                  }
                  style={{
                    margin: '0px 0px 18px',
                    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.12)',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #E3E6EB',
                    width: 316,
                  }}
                >
                  <div className="text-center" style={{ padding: '0px 16px' }}>
                    {s.content}
                  </div>
                </Panel>
              )
            )}
          </Collapse>
        </Drawer>
      </>
    )
  )
}

export default memo(OnboardingStatus)
