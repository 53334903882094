import { ClockCircleFilled } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useLogout } from 'services/auth-services'
import { useSelector } from 'react-redux'
import {
  changeOnboardingMenuStatus,
  selectPaymentPopupsState,
} from 'redux/preferences'
import { useState, useEffect } from 'react'
import { dispatch } from 'redux/store'

function TrialOverModal() {
  const location = useLocation()
  const history = useHistory()
  const { mutate, isLoading } = useLogout()
  const { isOwner, isAdmin } = useSelector((state) => state.authentication)
  const {
    showAssessmentExpiredPopup,
    showTrainingExpiredPopup,
    showTrainingCreditsExpiredPopup,
    showAssessmentCreditsExpiredPopup,
  } = useSelector((state) => selectPaymentPopupsState(state))
  const [visible, setVisible] = useState(false)
  const [creditsExpired, setCreditsExpired] = useState('')

  useEffect(() => {
    if (location.pathname.includes('/dashboard')) {
      if (showAssessmentExpiredPopup && showTrainingExpiredPopup) {
        dispatch(changeOnboardingMenuStatus(false))
        setVisible(true)
        setCreditsExpired('')
      } else if (
        showAssessmentCreditsExpiredPopup &&
        showTrainingCreditsExpiredPopup
      ) {
        setVisible(true)
        dispatch(changeOnboardingMenuStatus(false))
        setCreditsExpired('both')
      }
    } else if (location.pathname.includes('assessments')) {
      if (showAssessmentExpiredPopup) {
        setVisible(true)
        dispatch(changeOnboardingMenuStatus(false))
        setCreditsExpired('')
      } else if (showAssessmentCreditsExpiredPopup) {
        setVisible(true)
        dispatch(changeOnboardingMenuStatus(false))
        setCreditsExpired('assessments')
      }
    } else if (
      location.pathname.includes('training') ||
      location.pathname.includes('team')
    ) {
      if (showTrainingExpiredPopup) {
        setVisible(true)
        dispatch(changeOnboardingMenuStatus(false))
        setCreditsExpired('')
      } else if (showTrainingCreditsExpiredPopup) {
        setVisible(true)
        dispatch(changeOnboardingMenuStatus(false))
        setCreditsExpired('training')
      }
    }
    return () => setVisible(false)
  }, [
    location.pathname,
    showAssessmentCreditsExpiredPopup,
    showAssessmentExpiredPopup,
    showTrainingCreditsExpiredPopup,
    showTrainingExpiredPopup,
  ])

  const subTitleText = () => {
    if (isOwner) {
      if (creditsExpired !== '') {
        if (creditsExpired === 'assessments') {
          return 'Unfortunately, this means your candidates can no longer place assessment calls from Dialworks. Head out to the Billing page to renew your subscription.'
        }
        if (creditsExpired === 'training') {
          return 'Unfortunately, this means you can no longer place training calls from Dialworks. Head out to the Billing page to renew your subscription.'
        }
        return 'Unfortunately, this means your candidates can no longer place training and assessment calls from Dialworks. Head out to the Billing page to renew your subscription.'
      }
      return 'Unfortunately, this means you can no longer access your Dialworks account. Feel free to reach out to renew your subscription.'
    }
    if (creditsExpired !== '') {
      return creditsExpired === 'assessments'
        ? 'Unfortunately, this means your candidates can no longer place assessment calls from Dialworks.Please contact your Owner to renew subscription and continue hiring'
        : 'Unfortunately, this means you can no longer place training calls from Dialworks. Please contact your Owner to renew subscription and continue training.'
    }
    return 'Unfortunately, this means you can no longer access your Dialworks account. Please contact your Owner to renew subscription and continue training.'
  }
  const titleText = () => {
    if (creditsExpired !== '') {
      if (creditsExpired === 'assessments') {
        return 'Your free hiring credits have been exhausted'
      }
      if (creditsExpired === 'training') {
        return 'Your free trial minutes have been exhausted'
      }
      return 'Your free trial credits have been exhausted'
    }
    return 'Your subscription has ended'
  }

  const redirectButton = () => {
    if (isOwner) {
      return (
        <>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => {
              history.push(`${APP_PREFIX_PATH}/billing/plans`)
            }}
          >
            Proceed to payment page
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            onClick={() => {
              setVisible(false)
              // window.Helpwise('show')
              window.Helpwise(
                'typeNewMessage',
                'Hi team, I would like to explore more. So please extend my trial'
              )
            }}
            type="primary"
          >
            Renew trial
          </Button>
        </>
      )
    }
    if (
      isAdmin &&
      !location.pathname.includes('/dashboard') &&
      creditsExpired === ''
    ) {
      return (
        <Button
          type="primary"
          onClick={() => history.push(`${APP_PREFIX_PATH}/dashboard`)}
          loading={isLoading}
        >
          Home
        </Button>
      )
    }
    return (
      creditsExpired === '' && (
        <Button type="primary" onClick={() => mutate()} loading={isLoading}>
          Sign out
        </Button>
      )
    )
  }

  return (
    <Modal
      visible={visible}
      // closable={creditsExpired !== ''}
      footer={null}
      maskClosable={false}
      onCancel={() => {
        // if (creditsExpired !== '') {
        // if (creditsExpired === 'assessments') {
        //   localStorage.setItem('assessments-credits-expired-popup', 'done')
        // } else if (creditsExpired === 'training') {
        //   localStorage.setItem('training-credits-expired-popup', 'done')
        // } else {
        //   localStorage.setItem('assessments-credits-expired-popup', 'done')
        //   localStorage.setItem('training-credits-expired-popup', 'done')
        // }
        // }
        setVisible(false)
      }}
      className="text-center"
      centered
      maskStyle={{ backdropFilter: 'blur(4px)' }}
    >
      <Avatar
        icon={<ClockCircleFilled />}
        style={{ backgroundColor: '#2e9b72' }}
      />
      <h2 className="mt-2">{titleText()}</h2>
      <div className="my-3">{subTitleText()}</div>
      {redirectButton()}
    </Modal>
  )
}

export default TrialOverModal
