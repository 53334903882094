/* eslint-disable no-nested-ternary */
import { Button } from 'antd'
// import { CheckCircleFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import moment from 'moment'
import PropTypes from 'prop-types'
// import TrialCallButton from './TrialCallButton'
import questionnaire from '../../assets/images/onboarding/questionnaire.svg'

// const { Step } = Steps

// const defsteps = [
//   {
//     key: 0,
//     // date: "2022-07-19T13:09:30.838Z",
//     title: 'Form submitted',
//   },
//   {
//     key: 1,
//     // date: "2022-07-20T13:09:30.838Z",
//     title: 'Form under review',
//   },
//   {
//     key: 2,
//     // date: "2022-07-21T01:09:30.838Z",
//     title: 'Ready for call 🎉',
//   },
// ]

// const getFormattedData = (data) => {
//   const formattedData = defsteps.map((d, i) => {
//     if (i < data?.length) {
//       const x = {
//         ...d,
//         date: data[i].date,
//       }
//       return x
//     }
//     const x = {
//       ...d,
//     }
//     return x
//   })
//   return formattedData
// }

function FillFormStep(props) {
  const {
    fillForm,
    stepperData,
    // isStepperLoading,
    // callsLeft,
    // trialCampaignId,
    // trialCallCampaign,
    // createdCampaign,
  } = props
  const history = useHistory()

  return (
    <>
      <img src={questionnaire} alt="questionnaire" />
      <p className="text-left" style={{ margin: '1em 0px' }}>
        {!fillForm ? (
          <>
            The context you provide as part of your responses will help our
            agents deliver a custom experience on your training calls.
          </>
        ) : stepperData?.steps.length === 3 ? (
          <>
            Yayy! you are now ready to have your first training call. Our agents
            are all prepped up
          </>
        ) : (
          <>
            Yayy! You will be ready to make your first call in just{' '}
            <strong>2 business days</strong> from the date of form submitted{' '}
            <strong>
              ({moment(stepperData?.steps[0]?.date).utc().format('MMM DD')}).
            </strong>{' '}
            Our agents are all getting prepped up 🚀
          </>
        )}
      </p>
      {!fillForm && (
        <Button
          style={{
            backgroundColor: '#2e9b72',
            color: 'white',
            borderRadius: '4px',
          }}
          onClick={(e) => {
            e.stopPropagation()
            // window.open('/onboarding', '_blank')
            history.push(`${APP_PREFIX_PATH}/onboarding`)
          }}
        >
          Start now
        </Button>
      )}
    </>
  )
  // ) : (
  //   <div style={{ marginTop: '-20px' }}>
  //     <p className="text-left" style={{ margin: '1em 0px' }}>
  //       {stepperData?.steps?.length === 3
  //         ? 'Yayy! you are now ready to have your first training call. Our agents are all prepped up'
  //         : `Yayy! You will be ready to make your first call in just 2 business days from now. Our agents are all getting prepped up 🚀`}
  //     </p>
  //     {/* <Steps
  //       direction="vertical"
  //       size="small"
  //       current={!isStepperLoading ? stepperData?.steps?.length - 1 : 0}
  //     >
  //       {!isStepperLoading
  //         ? getFormattedData(stepperData?.steps).map((s) => (
  //             <Step
  //               key={s.key}
  //               title={s.title}
  //               description={
  //                 s.date ? (
  //                   <span style={{ color: '#82868C' }}>
  //                     {moment(s.date).utc().format('MMM D')}
  //                   </span>
  //                 ) : null
  //               }
  //               icon={
  //                 <CheckCircleFilled
  //                   style={{
  //                     color:
  //                       s.key <= stepperData?.steps?.length - 1
  //                         ? '#44C13C'
  //                         : '#E3E6EB',
  //                     fontSize: '22px',
  //                   }}
  //                 />
  //               }
  //             />
  //           ))
  //         : '...Loading'}
  //     </Steps> */}
  //     {/* {stepperData?.steps?.length === 3 ? (
  //       <Button
  //         disabled={stepperData?.steps?.length !== 3 || !createdCampaign}
  //         style={{
  //           backgroundColor:
  //             stepperData?.steps?.length !== 3 || !createdCampaign
  //               ? '#E3E6EB'
  //               : '#2e9b72',
  //           color: 'white',
  //           borderRadius: '4px',
  //           margin: '10px 0px',
  //         }}
  //         onClick={(e) => {
  //           e.stopPropagation()
  //           history.push(`${APP_PREFIX_PATH}/training/campaigns`)
  //         }}
  //       >
  //         Make your first call
  //       </Button>
  //     ) : (
  //       <TrialCallButton
  //         callsLeft={callsLeft}
  //         trialCampaignId={trialCampaignId}
  //         enableTextBelow
  //         trialCallCampaign={() => trialCallCampaign()}
  //       />
  //     )} */}
  //   </div>
}

export default FillFormStep
FillFormStep.propTypes = {
  fillForm: PropTypes.bool,
  stepperData: PropTypes.object,
  // isStepperLoading: PropTypes.bool.isRequired,
  // callsLeft: PropTypes.number,
  // trialCampaignId: PropTypes.number,
  // trialCallCampaign: PropTypes.func.isRequired,
  // createdCampaign: PropTypes.bool,
}
FillFormStep.defaultProps = {
  // trialCampaignId: 0,
  // createdCampaign: false,
  fillForm: false,
  // callsLeft: 5,
  stepperData: {},
}
