/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useSelector } from 'react-redux'
import request from 'auth/FetchInterceptor'
import { useQuery, useMutation, useInfiniteQuery } from 'react-query'
import { queryClient } from 'react-query-client'
import moment from 'moment'
import { dispatch } from 'redux/store'
import { changeOnboardingMenuStatus } from 'redux/preferences'

const getTimezones = () =>
  request({ url: `${process.env.REACT_APP_V2_API}/timezone` })

export const useGetTimezones = () =>
  useQuery('timezones', getTimezones, {
    retry: false,
    refetchOnWindowFocus: false,
  })

// get onboading status

const getOnboardingStatus = () =>
  request({
    url: `${process.env.REACT_APP_V2_API}/company-detail/checklist`,
  })

export const useGetOnboardingStatus = () => {
  const { isOwner } = useSelector((state) => state.authentication)
  return useQuery('onboarding-status', getOnboardingStatus, {
    retry: false,
    refetchOnWindowFocus: true,
    enabled: !!isOwner,
    // enabled: (!store.getState().authentication.onboardingComplete && store.getState().authentication.isOwner)
  })
}

// get onboading Progression+Stepper Status

const getOnboardingStepperStatus = () =>
  request({
    url: `${process.env.REACT_APP_V2_API}/typeform/onboarding-stepper`,
  })

export const useGetOnboardingStepperStatus = () => {
  const { isOwner } = useSelector((state) => state.authentication)
  return useQuery('onboarding-stepper-status', getOnboardingStepperStatus, {
    enabled: !!isOwner,
  })
}

// submit typeform

function submitTypeform(formData) {
  // console.log('test', data)
  // const temp = data
  // const files = temp.fileList
  // delete temp.fileList
  // delete temp[
  //   'Please upload few call recordings of your quota crushing sales reps.'
  // ]
  // console.log('files', files)
  // const formData = new FormData()
  // formData.append('files', files)
  // formData.append('data', JSON.stringify(temp))
  // console.log('after', formData)
  return request({
    url: `${process.env.REACT_APP_V2_API}/typeform/onboarding-questionnaire`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    method: 'POST',
    successMessage: 'Typeform submitted successfully',
  })
}

export const useSubmitTypeform = (redirect, setLoadingModal) =>
  useMutation(submitTypeform, {
    retry: false,
    onSuccess: () => {
      redirect()
      dispatch(changeOnboardingMenuStatus(true))
    },
    onError: () => {
      setLoadingModal(false)
    },
  })

// fetch notifications

const fetchNotifications = (page, limit, uncleared) => {
  // to get notifications which are not cleared
  if (uncleared) {
    return request({
      url: `/sales-rep-notification?page=${page}&limit=${limit}&uncleared=${uncleared}`,
    })
  }
  return request({
    url: `/sales-rep-notification?page=${page}&limit=${limit}`,
  })
}

export const useFetchNotifications = (page = 1, limit = 5, uncleared = false) =>
  useQuery(
    ['notifications', `page-${page}:limit-${limit}:uncleared-${uncleared}`],
    () => fetchNotifications(page, limit, uncleared)
  )

export const useFetchInfinityNotifications = (limit = 5, uncleared = false) =>
  useInfiniteQuery(
    ['infinite-notifications'],
    ({ pageParam = 1 }) => fetchNotifications(pageParam, limit, uncleared),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.meta?.pageCount <= allPages.length) return undefined

        return allPages.length + 1
      },
    }
  )

// mark notification as read

const markNotificationAsRead = (notificationIds) =>
  request({
    url: `/sales-rep-notification/mark-read`,
    method: 'POST',
    data: { notificationIds },
  })

export const useMarkNotificationAsRead = (callback) =>
  useMutation(markNotificationAsRead, {
    onMutate: async (notificationIds) => {
      await queryClient.cancelQueries('notifications')
      await queryClient.cancelQueries('infinite-notifications')
      await queryClient.cancelQueries('unread-notifications-number')

      const previousNotifications = queryClient.getQueriesData('notifications')
      const previousInfiniteNotifications = queryClient.getQueriesData(
        'infinite-notifications'
      )
      const previousUnreadNotificationsNumber = queryClient.getQueriesData(
        'unread-notifications-number'
      )

      const optimisticallyUpdatedNotifications = previousNotifications
      optimisticallyUpdatedNotifications?.data?.data.forEach((notification) => {
        if (notificationIds.includes(notification._id)) {
          notification.readAt = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          notification.readAtTz = moment(new Date()).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        }
      })
      queryClient.setQueryData('notifications', (oldQueryData) => {
        const optimicallyUpdatedQueryData = oldQueryData

        optimicallyUpdatedQueryData?.data?.data.forEach((notification) => {
          if (notificationIds.includes(notification._id)) {
            notification.readAt = moment(new Date()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            notification.readAtTz = moment(new Date()).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          }
        })

        return optimicallyUpdatedQueryData
      })

      queryClient.setQueryData('infinite-notifications', (oldQueryData) => {
        const optimicallyUpdatedQueryData = oldQueryData

        optimicallyUpdatedQueryData.pages.forEach((page) => {
          page.data.data.forEach((notification) => {
            if (notificationIds.includes(notification._id)) {
              notification.readAt = moment(new Date()).format(
                'YYYY-MM-DD HH:mm:ss'
              )
              notification.readAtTz = moment(new Date()).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            }
          })
        })

        return optimicallyUpdatedQueryData
      })

      queryClient.setQueryData(
        'unread-notifications-number',
        (oldQueryData) => {
          const optimicallyUpdatedQueryData = oldQueryData

          optimicallyUpdatedQueryData.data.count -= notificationIds.length

          if (optimicallyUpdatedQueryData.data.count > 0)
            return optimicallyUpdatedQueryData

          return 0
        }
      )

      return {
        previousNotifications,
        previousInfiniteNotifications,
        previousUnreadNotificationsNumber,
      }
    },
    onError: (_error, _notificationIds, context) => {
      queryClient.setQueryData('notifications', context.previousNotifications)
      queryClient.setQueryData(
        'infinite-notifications',
        context.previousInfiniteNotifications
      )
      queryClient.setQueryData(
        'unread-notifications-number',
        context.previousUnreadNotificationsNumber
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('infinite-notifications')
      queryClient.invalidateQueries('unread-notifications-number')
    },
    onSuccess: () => {
      if (callback) callback()
    },
  })

// clear notification

const clearNotification = (notificationIds) =>
  request({
    url: `/sales-rep-notification/clear`,
    method: 'POST',
    data: { notificationIds },
  })

export const useClearNotification = () =>
  useMutation(clearNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
      queryClient.invalidateQueries('infinite-notifications')
      queryClient.invalidateQueries('unread-notifications-number')
    },
  })

// get no unread notifications

const getNumberOfNotifications = () =>
  request({
    url: '/sales-rep-notification/unread/count',
  })

export const useGetNumberOfNotifications = () =>
  useQuery('unread-notifications-number', getNumberOfNotifications)
