import request from 'auth/FetchInterceptor'
import { useQuery, useMutation } from 'react-query'
import { queryClient } from 'react-query-client'

// get active assessments
const getActiveAssessments = (role) => {
  if (role !== 2) {
    return request({
      url: `${process.env.REACT_APP_V2_API}/assessment?status=active`,
    })
  }
  return null
}

export const useGetActiveAssessments = (role) =>
  useQuery('active-assessments', () => getActiveAssessments(role), {
    retry: false,
    refetchOnWindowFocus: false,
  })

// get assessment by id
const getAssessmentById = (id) =>
  request({ url: `${process.env.REACT_APP_V2_API}/assessment/${id}` })

export const useGetAssessmentById = (id) =>
  useQuery(['get-assessment-by-id', id], () => getAssessmentById(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

// get expired assessments
const getExpiredAssessments = (role) => {
  if (role !== 2) {
    return request({
      url: `${process.env.REACT_APP_V2_API}/assessment?status=completed`,
    })
  }
  return null
}

export const useGetExpiredAssessments = (role) =>
  useQuery('expired-assessments', () => getExpiredAssessments(role), {
    retry: false,
    refetchOnWindowFocus: false,
  })

// create assessment

const createAssessment = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment`,
    data: data,
    method: 'POST',
    showSuccessNotification: true,
    successMessage: 'Assessment created successfully',
  })

export const useCreateAssessment = (callback) =>
  useMutation(createAssessment, {
    retry: false,
    onSuccess: () => callback(),
  })

// edit Assessment

const editAssessment = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/${data.id}`,
    data,
    method: 'PUT',
    showSuccessNotification: true,
    successMessage: 'Assessment successfully updated',
  })

export const useEditAssessment = (callback) =>
  useMutation(editAssessment, {
    retry: false,
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries('active-assessments')
      queryClient.invalidateQueries('expired-assessments')
    },
  })

// delete Assessment by id

const deleteAssessment = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/${id}`,
    method: 'DELETE',
    showSuccessNotification: true,
    successMessage: 'Assessment successfully deleted',
  })

export const useDeleteAssessment = (callback) =>
  useMutation(deleteAssessment, {
    retry: false,
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries('active-assessments')
      queryClient.invalidateQueries('expired-assessments')
    },
  })

// get all candidates in a assessment

const getCandidates = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/candidates/${id}`,
  })

export const useGetCandidates = (id) =>
  useQuery(['assessment-candidates', id], () => getCandidates(id), {
    enabled: !!id,
    onSuccess: () => {
      queryClient.invalidateQueries('available-credits')
    },
  })

// delete candidate from assessment
const deleteCandidate = (assessmentMappingId) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessments-mapping/${assessmentMappingId}`,
    method: 'delete',
    showSuccessNotification: true,
    successMessage: 'Candidate successfully removed from candidate list',
  })

export const useDeleteCandidate = (handleCancel, id) =>
  useMutation(deleteCandidate, {
    retry: false,
    onSuccess: () => {
      handleCancel()
      queryClient.invalidateQueries(['assessment-candidates', id])
      queryClient.invalidateQueries(['get-assessment-by-id', id])
    },
  })

// add candidates to assessment

const addCandidates = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessments-mapping/${data.assessmentId}`,
    data: { candidates: data.candidates },
    method: 'POST',
    showSuccessNotification: true,
    successMessage:
      data.candidates.length > 1
        ? 'Candidates added successfully'
        : 'Candidate added successfully',
  })

export const useAddCandidates = (id, callback) =>
  useMutation(addCandidates, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['assessment-candidates', id])
      queryClient.invalidateQueries(['get-assessment-candidates-id', id])
      callback()
    },
  })

// get assessment analytics insights

const getAssessmentInsights = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/analytics/overview/${id}`,
  })

export const useGetAssessmentInsights = (id) =>
  useQuery(
    ['get-assessment-insights-id', id],
    () => getAssessmentInsights(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )

// get assessment analytics candidates

const getAssessmentCandidates = (id, status) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/analytics/candidates/${id}?status=${status}`,
  })

export const useGetAssessmentCandidates = (id, status) =>
  useQuery(
    ['get-assessment-candidates-id', id, status],
    () => getAssessmentCandidates(id, status),
    { enabled: !!id }
  )

// get candidate call ratings

const getCandidateRatings = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/analytics/candidatesRating/${id}`,
  })

export const useGetCandidateRatings = (id) =>
  useQuery(['candidate', id], () => getCandidateRatings(id), {
    enabled: !!id,
  })

// get script of assessemnt
const getAssessmentScript = (id) =>
  request({ url: `${process.env.REACT_APP_V2_API}/script/assessment/${id}` })

export const useGetAssessmentScript = (id, setFormData) =>
  useQuery(['script', id], () => getAssessmentScript(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!id,
    onSuccess: (data) => {
      setFormData((formData) => ({ ...formData, script: data?.data?.script }))
    },
  })

// resend link to candidate
const resendCandidateMail = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessments-mapping/resend/${id}`,
    method: 'POST',
    retry: false,
    showSuccessNotification: true,
    successMessage: 'Mail sent to candidate successfully',
  })

export const useResendCandidateMail = (callback) =>
  useMutation(resendCandidateMail, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(['get-assessment-by-id'])
      queryClient.invalidateQueries(['get-assessment-candidates-id'])
      queryClient.invalidateQueries(['assessment-candidates'])
      queryClient.invalidateQueries('available-credits')
      callback()
    },
  })

// send test email
const sendTestEmail = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/assessment/send-test-mail`,
    data,
    method: 'POST',
  })

export const useSendTestEmail = () =>
  useMutation(sendTestEmail, {
    retry: false,
  })
