import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  plans: [],
  isSubscribed: null,
  isTrailActive: null,
  myPlan: null,
  teamMembers: 1,
  selectedPlan: null,
  loading: null,
  available: {},
}

const paymentSlice = createSlice({
  initialState,
  name: 'payment',
  reducers: {
    setLoading: (state, action) => ({ ...state, loading: action.payload }),
    setPlans: (state, action) => ({ ...state, plans: action.payload }),
    setMyPlan: (state, action) => ({ ...state, myPlan: action.payload }),
    setTeamMembers: (state, action) => ({
      ...state,
      teamMembers: action.payload,
    }),
    setSelectedPlan: (state, action) => ({
      ...state,
      selectedPlan: action.payload,
    }),
    setSubscription: (state, action) => ({
      ...state,
      isSubscribed: action.payload,
    }),
    setTrail: (state, action) => ({ ...state, isTrailActive: action.payload }),
    setAvailable: (state, action) => ({ ...state, available: action.payload }),
  },
})

export const {
  setPlans,
  setMyPlan,
  setTeamMembers,
  setSelectedPlan,
  setSubscription,
  setTrail,
  setLoading,
  setAvailable,
} = paymentSlice.actions

export default paymentSlice.reducer
