import request from 'auth/FetchInterceptor'
import { useQuery, useMutation } from 'react-query'
import { queryClient } from 'react-query-client'

// get all campaigns

const getAllCampaigns = (role) => {
  if (role !== 2) {
    return request({ url: `${process.env.REACT_APP_V2_API}/campaign` })
  }
  return null
}

export const useGetAllCampaigns = (role) =>
  useQuery('all-campaigns', () => getAllCampaigns(role), {
    retry: false,
    refetchOnWindowFocus: false,
  })

// get user specific campaign

const getUserSpecificCampaigns = () =>
  request({ url: `${process.env.REACT_APP_V2_API}/campaign-mapping` })

export const useGetUserSpecificCampaigns = () =>
  useQuery('user-specific-campaigns', getUserSpecificCampaigns, {
    retry: false,
    refetchOnWindowFocus: false,
  })

// get campaign analytics

const getCampaignAnalytics = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign-mapping/${id}/analytics`,
  })

// get salesrep's campaign

const getSalesrepCampaignAnalytics = (campaignId, salesrepId) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign/${campaignId}/sales-rep/${salesrepId}/analytics`,
  })

// get all salesrep's campaign analytics of specific campaign

const getAllSalesrepCampaignAnalytics = (campaignId) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign/${campaignId}/sales-rep/analytics`,
  })
// export const useGetCampaignAnalytics = (id) =>
//   useQuery(['campaign-analytics', id], () => getCampaignAnalytics(id), {
//     retry: false,
//     enabled: !!id,
//   })

// export const useGetSalesrepCampaignAnalytics = (campaignId, salesrepId) =>
//   useQuery(
//     ['salesrep-campaign-analytics', `${campaignId}:${salesrepId}`],
//     () => getSalesrepCampaignAnalytics(campaignId, salesrepId),
//     {
//       retry: false,
//       enabled: !!(campaignId && salesrepId),
//     }
//   )

// export const useGetAllSalesrepCampaignAnalytics = (campaignId) =>
//   useQuery(
//     ['all-salesrep-campaign-analytics', campaignId],
//     () => getAllSalesrepCampaignAnalytics(campaignId),
//     {
//       retry: false,
//       enabled: !!campaignId,
//     }
//   )

export const useGetCampaignAnalytics = (
  campaignId,
  salesrepId,
  adminAnalytics
) =>
  useQuery(
    ['campaign-analytics', campaignId, salesrepId, adminAnalytics],
    () => {
      if (adminAnalytics) {
        if (salesrepId !== 0) {
          return getSalesrepCampaignAnalytics(campaignId, salesrepId)
        }
        return getAllSalesrepCampaignAnalytics(campaignId)
      }
      return getCampaignAnalytics(campaignId)
    },
    { retry: false, enabled: !!campaignId }
  )

// get assigned salesrep to a campaign

const getAssignedUsers = (id, assigned) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign/${id}/sales-rep?assigned=${assigned}`,
  })

export const useGetAssignedUsers = (id) =>
  useQuery(
    ['assigned-user-to-campaign', id],
    () => getAssignedUsers(id, true),
    {
      retry: false,
      enabled: !!id,
    }
  )

export const useGetUnassignedUsers = (id) =>
  useQuery(
    ['unassigned-user-to-campaign', id],
    () => getAssignedUsers(id, false),
    {
      retry: false,
      enabled: !!id,
    }
  )

// create campaign

const createCampaign = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign`,
    data: data,
    method: 'POST',
    showSuccessNotification: true,
    successMessage: 'Campaign created successfully',
  })

export const useCreateCampaign = (callback) =>
  useMutation(createCampaign, {
    retry: false,
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries('onboarding-status')
      queryClient.invalidateQueries('all-campaigns')
    },
  })

// get parameters

const getParameters = () =>
  request({ url: `${process.env.REACT_APP_V2_API}/training-parameter` })

export const useGetParameters = () =>
  useQuery(['parameters'], getParameters, {
    retry: false,
  })

// get campaign by id

const getCampaignById = (id) =>
  request({ url: `${process.env.REACT_APP_V2_API}/campaign/${id}` })

export const useGetCampaignById = (id) =>
  useQuery(['get-campaign-by-id', id], () => getCampaignById(id), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  })

// delete campaign by id

const deleteCampaign = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign/${id}`,
    method: 'DELETE',
    showSuccessNotification: true,
    successMessage: 'Campaign successfully deleted',
  })

export const useDeleteCampaign = (callback) =>
  useMutation(deleteCampaign, {
    retry: false,
    onSuccess: () => {
      callback()
      queryClient.invalidateQueries('all-campaigns')
      queryClient.invalidateQueries('user-specific-campaigns')
    },
  })

// adding team members to already existing campaign

const assignCampaign = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign-mapping`,
    data,
    method: 'POST',
    showSuccessNotification: true,
    successMessage: 'Campaign assigned successfully',
  })

export const useAssignCampaign = (callback, setVisible) =>
  useMutation(assignCampaign, {
    retry: false,
    onSuccess: () => {
      callback()
      setVisible(false)
      queryClient.invalidateQueries('user-specific-campaigns')
      queryClient.invalidateQueries('assigned-user-to-campaign')
      queryClient.invalidateQueries('unassigned-user-to-campaign')
    },
  })

// edit campaign

const editCampaign = (data) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/campaign/${data.id}`,
    data,
    method: 'PUT',
    showSuccessNotification: true,
    successMessage: 'Campaign successfully updated',
  })

export const useEditCampaign = (callback) =>
  useMutation(editCampaign, {
    retry: false,
    onSuccess: () => {
      callback()
    },
  })

// get script of a particualar campaigns

const getScript = (id) =>
  request({ url: `${process.env.REACT_APP_V2_API}/script/campaign/${id}` })

export const useGetScript = (id, setFormData) =>
  useQuery(['script', id], () => getScript(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!id,
    onSuccess: (data) => {
      if (setFormData) {
        setFormData((formData) => ({ ...formData, script: data?.data?.script }))
      }
    },
  })

// precheck

const preCheck = (data) =>
  request({ url: `call-detail/pre-check`, data, method: 'POST' })

export const usePrecheck = () => useMutation(preCheck)
