/* eslint-disable no-multi-assign */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef, useRef, useEffect } from 'react'
import './Transcript.css'
import PropTypes from 'prop-types'
import TrackPlayer from './TrackPlayer'
// import MenuBox from './MenuBox'
import TranscriptHeader from './TranscriptHeader'
// import TranscriptBody from "./TranscriptBody";

const Transcript = forwardRef((props, ref) => {
  const { searchText, setSearchText } = props
  console.log('props of transcript', props)

  // let clicked = false
  // let index1 = -1
  // let index2 = -1
  // const [show, setShow] = useState(false);
  // let menubox
  let currentSpan
  let prevSpan
  const { transcriptRef } = ref.current
  let currentSpanBottom
  const transcriptDivBottom = useRef()
  const transcriptDivTop = useRef()
  // let current_clicked_span
  // let previous_clicked_span
  // const commentAudioRef = useRef({ commentBoxRef, audioPlayerRef })

  // console.log(props.mentionedWords)
  // console.log(props.transcript.length)

  // code for right click options
  // document.addEventListener('auxclick', (e) => console.log(e))
  useEffect(() => {
    // transcript_div_bottom represents the bottom of the transcript div
    const dimensions = transcriptRef.current.getBoundingClientRect()
    transcriptDivBottom.current = dimensions.bottom
    transcriptDivTop.current = dimensions.top
    // console.log(transcript_div_bottom)
  })

  // function changeDisplay(view) {
  //   menubox.style.display = view
  // }

  // document.addEventListener('click', () => {
  //   // console.log("outside the span clicked",index1,index2);
  //   // console.log("event listener clicked")

  //   if (clicked) {
  //     if (index1 !== -1) {
  //       changeDisplay('none')
  //       current_clicked_span.target.style.background = 'white'
  //     }
  //     clicked = false
  //     index1 = index2 = -1
  //   }
  // })

  // function clickHandler(e) {
  //   // console.log("previous",index1,index2,clicked);
  //   console.log('span clicked', e)

  //   current_clicked_span = e

  //   if (!clicked) {
  //     clicked = true
  //     current_clicked_span.target.style.background = '#e9ecef'
  //     previous_clicked_span = current_clicked_span
  //     index1 = index2 = e.target.dataset.start
  //     // console.log(typeof(index1),index2);
  //     if (index1) {
  //       menubox = document.querySelector(`div[data-menu-index="${index1}"]`)
  //       changeDisplay('block')
  //     }
  //   } else {
  //     index2 = e.target.dataset.start
  //     // console.log(typeof(index1),index2)
  //     if (index2 && index2 !== index1) {
  //       changeDisplay('none')
  //       previous_clicked_span.target.style.background = 'white'
  //       current_clicked_span.target.style.background = '#e9ecef'
  //       previous_clicked_span = current_clicked_span
  //       menubox = document.querySelector(`div[data-menu-index="${index2}"]`)
  //       changeDisplay('block')
  //     }
  //     index1 = index2
  //   }
  //   // console.log("after span clicked", index1,index2,clicked)
  //   e.stopPropagation()
  // }
  // function myClickHandler(e) {
  //   console.log('last most span', e)
  //   e.target.style.background = '#e9ecef'
  //   const menubox = e.target.firstElementChild
  //   menubox.style.display = 'block'
  // }
  let offset = 0
  let wordIndex = -1

  function removeHighlight(element) {
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      element.style.background = 'white'
      // eslint-disable-next-line no-param-reassign
      element.style.color = 'inherit'
    }, 200)
  }

  function startHighlighting(time) {
    // console.log("audio player current time " + time);
    currentSpan = document.querySelector(
      `span[data-start="${parseFloat(time.toFixed(1)).toFixed(9)}"]`
    )

    if (prevSpan) removeHighlight(prevSpan)
    if (currentSpan) {
      currentSpan.style.background = '#2e9b72'
      currentSpan.style.color = 'white'
      // console.log(current_span.parentElement.previousElementSibling)
      if (prevSpan && prevSpan.parentElement !== currentSpan.parentElement)
        prevSpan.parentElement.previousElementSibling.classList.remove(
          'speaker_highlight'
        )
      currentSpan.parentElement.previousElementSibling.classList.add(
        'speaker_highlight'
      )

      // this logic is for auto scrolling the span to the top while highlighting the words in transcript
      currentSpanBottom = currentSpan.getBoundingClientRect()

      // getBoundingClientRect() returns an object which has properties such as top,left,right and bottom of an element
      // w.r.t. window viewport. If a span has bottom more than the transcript container then it is out of the view in
      // the transcript container
      // console.log('test', currentSpanBottom)
      currentSpanBottom = currentSpanBottom.bottom

      // console.log(audioPlayer_div_top)
      // console.log("test",transcriptDivBottom)
      // the logic below is for scrolling the span into the scrollView
      // 1 is for the border width of controls div
      if (
        currentSpanBottom > transcriptDivBottom.current - 40 ||
        currentSpanBottom < transcriptDivTop.current + 20
      ) {
        currentSpan.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      prevSpan = currentSpan
    }
    // else console.log("Didn't found any span");
  }

  // console.log(transcript_ref.current)

  return (
    <div
      className="d-flex flex-column border bg-white shadow-sm rounded-sm mb-4"
      style={{
        height: '75%',
        scrollBehavior: 'smooth',
      }}
    >
      <TranscriptHeader
        customerName={props.customerName}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div
        style={{
          overflowY: 'auto',
          padding: '20px 40px',
          maxHeight: '430px',
        }}
        ref={transcriptRef}
      >
        {props.transcript.map((obj, index) => {
          const tempOffset = offset
          return (
            <div
              id={`row-${index}`}
              key={obj.timeStamp}
              data-track={obj.track}
              data-offset={tempOffset}
              data-start={parseFloat(obj.monologue[0].startTime).toFixed(9)}
              style={{
                gap: '20px',
                display: 'flex',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                <p
                  style={{
                    color: obj.track === 1 ? '#f090d2' : ' #d2b9fa',
                    marginBottom: '0px',
                  }}
                >
                  {obj.person}
                </p>
                <span className="text-muted">{obj.timeStamp}</span>
              </div>
              <div
                style={{
                  width: '80%',
                }}
                // onMouseDown={(e) =>
                //   console.log(
                //     'mouseclicked',
                //     e.target.getAttribute('data-start')
                //   )
                // }
                // onMouseUp={(e) => {
                //   console.log(
                //     'mouseclicked complete',
                //     e.target.getAttribute('data-start')
                //   )
                //   myClickHandler(e)
                // }}
              >
                {obj.monologue.map((obj1) => {
                  // console.log(obj1);
                  offset += obj1.word.length + 1
                  wordIndex += 1
                  return (
                    <span
                      key={obj1.timeStamp}
                      data-start={obj1.startTime.toFixed(9)}
                      data-end={obj1.endTime.toFixed(9)}
                      id={`w-${wordIndex}`}
                      style={{
                        // color: props.mentionedWords.includes(obj1.startTime)
                        //   ? 'orange'
                        //   : 'black',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                      data-offset={tempOffset}
                    >
                      {obj1.word}{' '}
                      {/* <MenuBox
                        ref={commentAudioRef}
                        dataIndex={obj1.startTime}
                        wordIndex={wordIndex}
                        playRecording={(d) => props.playRecording(d)}
                      /> */}
                    </span>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <TrackPlayer
        ref={ref}
        duration={props.duration}
        datetime={props.datetime}
        wordHighlighter={startHighlighting}
        audioUrl={props.audioUrl}
        moveMarkerWithTime={props.moveMarkerWithTime}
      />
    </div>
  )
})

export default Transcript
Transcript.propTypes = {
  customerName: PropTypes.string.isRequired,
  transcript: PropTypes.array.isRequired,
  // playRecording: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  datetime: PropTypes.string.isRequired,
  audioUrl: PropTypes.string.isRequired,
  moveMarkerWithTime: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  // mentionedWords: PropTypes.array.isRequired,
}
