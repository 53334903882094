import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from 'react-redux'

function LoaderRedirect() {
  const { wasLoggedIn, isAdmin } = useSelector((state) => state.authentication)

  if (wasLoggedIn && isAdmin) {
    return (
      <Redirect
        from={`${AUTH_PREFIX_PATH}`}
        to={`${APP_PREFIX_PATH}/dashboard`}
      />
    )
  }

  if (wasLoggedIn && !isAdmin) {
    return (
      <Redirect
        from={`${AUTH_PREFIX_PATH}`}
        to={`${APP_PREFIX_PATH}/training/campaigns`}
      />
    )
  }

  return (
    <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
  )
}

export function AppViews() {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          exact
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./Login`))}
        />
        <Route
          exact
          path={`${AUTH_PREFIX_PATH}/signup`}
          component={lazy(() => import('./Register'))}
        />
        <Route
          exact
          path={`${AUTH_PREFIX_PATH}/landing-page-signup`}
          component={lazy(() => import('./SignupFromLanding'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import('./ForgotPassword'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/change-password`}
          component={lazy(() => import('./ChangePassword'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/create-profile`}
          component={lazy(() => import('./Invite'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/verify-email`}
          component={lazy(() => import('./VerifyEmail'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/impersonate-profile`}
          component={lazy(() => import('./ImpersonateProfile'))}
        />
        <LoaderRedirect />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
