import { Button, Input } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

function TranscriptHeader(props) {
  const { customerName, setSearchText, searchText } = props
  const history = useHistory()
  return (
    <div className="d-flex justify-content-between align-items-center border-bottom p-2 ">
      <div>
        <Button
          onClick={() => history.goBack()}
          icon={<ArrowLeftOutlined />}
          type="text"
          shape="circle"
          size="small"
        />
        <span>{customerName}&apos;s training call</span>
        {/* <br />
        <span>JustCall number 447723406595</span> */}
      </div>

      <div
        className="d-flex flex-row align-items-center"
        style={{ gap: '5px', marginRight: '10px' }}
      >
        {/* <div className="m-1 d-flex flex-column justify-items-center text-center">
          <img
            src="https://cdn.justcall.io/app/assets/images/callscore-icon.png"
            alt="rating"
            style={{ height: '14px' }}
          />
          <span style={{ fontSize: '10px' }}>91%</span>
        </div> */}

        <Input
          size="small"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {/* <MoreOutlined style={{ fontSize: '22px' }} /> */}
      </div>
    </div>
  )
}

export default TranscriptHeader
TranscriptHeader.propTypes = {
  customerName: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
}
