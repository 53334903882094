import { useRef, forwardRef, useEffect, useState, useCallback } from 'react'
import WaveSurfer from 'wavesurfer.js'
import {
  BsVolumeDownFill,
  BsVolumeUpFill,
  BsVolumeOffFill,
} from 'react-icons/bs'
import { MdReplay5, MdForward5 } from 'react-icons/md'
import Icon, {
  PauseOutlined,
  LeftOutlined,
  RightOutlined,
  CaretRightOutlined,
} from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

function ReplayIcon(props) {
  return <Icon {...props} component={MdReplay5} />
}
function ForwardIcon(props) {
  return <Icon {...props} component={MdForward5} />
}
function VolumeUpIcon(props) {
  return <Icon {...props} component={BsVolumeUpFill} />
}
function VolumeDownIcon(props) {
  return <Icon {...props} component={BsVolumeDownFill} />
}
function VolumeOffIcon(props) {
  return <Icon {...props} component={BsVolumeOffFill} />
}

const TrackPlayer = forwardRef((props, ref) => {
  // console.log('props of track', props)
  const slowButton = useRef(null)
  const fastButton = useRef(null)
  const indicator = useRef(null)
  const volumeBarRef = useRef(null)
  // let volumeContainerRef = useRef(null);
  const duration = useRef(props.duration)
  const waveformRef = useRef(null)
  const currentTimeRef = useRef(null)

  // this ref is from CenterContainer which has audioRef and transcript ref
  const wavesurfer = ref.current.audioPlayerRef
  // console.log("this is the wavesurfer ref",wavesurfer)
  const [isPaused, setIsPaused] = useState(true)
  const [volumeLevel, setVolumeLevel] = useState(100)

  const [volOpen, setVolOpen] = useState(false)

  function changePlaybackRate(s) {
    // console.log(typeof(s));

    if (s === 0.5) {
      wavesurfer.current.setPlaybackRate(s)
      indicator.current.textContent = `${s}x`
      slowButton.current.disabled = true
    } else if (s === 2) {
      wavesurfer.current.setPlaybackRate(s)
      indicator.current.textContent = `${s}x`
      fastButton.current.disabled = true
    } else if (s > 0.5 && s < 2) {
      wavesurfer.current.setPlaybackRate(s)
      indicator.current.textContent = `${s}x`
      slowButton.current.disabled = false
      fastButton.current.disabled = false
    }
    // console.log(wavesurfer.current.getPlaybackRate());
  }

  function getSpeed() {
    let speed
    const text = indicator.current.textContent
    if (text.length === 2) {
      speed = parseFloat(text[0])
    } else {
      speed = parseFloat(text[0] + text[1] + text[2])
    }
    return speed
  }

  function slowDownPBR() {
    // console.log("slow button clicked");
    // console.log(speed);
    changePlaybackRate(getSpeed() - 0.5)
  }

  function fastPBR() {
    // console.log("fast button clicked");
    // console.log(speed);
    changePlaybackRate(getSpeed() + 0.5)
  }

  function playButtonClickHandler() {
    // console.log('vds', wavesurfer.current.backend)
    wavesurfer.current.playPause()
    setIsPaused(!isPaused)
    // console.log(isPaused)
  }
  function convertTimetoDailer(t) {
    let time = t
    if (time < 0) return '00: 00: 00'
    const hrs = Math.floor(time / 3600)
    time -= hrs * 3600

    const mins = Math.floor(time / 60)
    time -= mins * 60

    let seconds = time
    seconds = Math.trunc(seconds)
    return `${hrs.toString().length === 1 ? `0${hrs}` : `${hrs}`}:
            ${mins.toString().length === 1 ? `0${mins}` : `${mins}`}:
            ${seconds.toString().length === 1 ? `0${seconds}` : `${seconds}`}`
  }
  const showCurrentTime = useCallback(
    (currentTime) => {
      const totalTime = convertTimetoDailer(props.duration)
      if (currentTime < 0)
        currentTimeRef.current.textContent = `00: 00: 00/${totalTime}`
      else {
        currentTimeRef.current.textContent = `${convertTimetoDailer(
          currentTime
        )}/${totalTime}`
      }
    },
    [props.duration]
  )
  function rewindHandler() {
    // console.log('from rewind handler',isPaused);
    const currentTime = wavesurfer.current.getCurrentTime()
    if (currentTime < 5) {
      showCurrentTime(0)
      wavesurfer.current.skip(-1 * currentTime)
      props.moveMarkerWithTime(duration.current)
      return
    }
    if (isPaused) {
      // console.log(wavesurfer.current.getCurrentTime())
      showCurrentTime(currentTime - 5)
      props.moveMarkerWithTime(currentTime - 5, duration.current)
    }
    wavesurfer.current.skip(-5)
  }

  function forwardHandler() {
    // console.log('from forward handler',isPaused)
    const currentTime = wavesurfer.current.getCurrentTime()
    if (duration.current - currentTime < 5) {
      showCurrentTime(duration.current)
      wavesurfer.current.skip(duration.current - currentTime)
      props.moveMarkerWithTime(duration.current, duration.current)
      return
    }
    // console.log(typeof(current_time_))
    if (isPaused) {
      // console.log(wavesurfer.current.getCurrentTime())
      showCurrentTime(currentTime + 5)
      props.moveMarkerWithTime(currentTime + 5, duration.current)
    }
    wavesurfer.current.skip(5)
  }

  function volumeChangeHandler() {
    const volume = parseFloat(volumeBarRef.current.value)
    wavesurfer.current.setVolume(volume)
    setVolumeLevel(volume)
    // console.log(wavesurfer.current.getVolume());
  }

  function volumeToggleMute() {
    if (volumeLevel) {
      setVolumeLevel(0)
      wavesurfer.current.setVolume(0)
    } else {
      setVolumeLevel(1)
      wavesurfer.current.setVolume(1)
    }
  }

  // console.log(props.audioUrl)

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      backend: 'MediaElement',
      mediaType: 'audio',
      height: '20px',
    })
    showCurrentTime(0)
    // console.log(wavesurfer, props.audioUrl, 'created')
    // put props.audioUrl in the load function
    if (props.audioUrl) {
      // console.log('this is audio url', props.audioUrl)
      wavesurfer.current.load(props.audioUrl)
      wavesurfer.current.on('ready', console.log('ready'))
      wavesurfer.current.on('audioprocess', (time) => {
        // console.log(time);
        // console.log("it is from getcurrenttime method",wavesurfer.current.getCurrentTime())
        showCurrentTime(parseInt(time, 10))
        props.wordHighlighter(time)
        props.moveMarkerWithTime(time, duration.current)
      })
      // console.log(wavesurfer, props.audioUrl, 'after loading')
      // wavesurfer.current.on('loading',(percent_loaded) => {
      //     console.log(percent_loaded);
      //     wavesurfer.current.empty();
      // })
      wavesurfer.current.on('ready', () => {
        duration.current = wavesurfer.current.getDuration()
        // console.log('duration from wavesurfer', duration.current)
      })
      // removing the waveform
      waveformRef.current.children[1].setAttribute('crossorigin', 'anonymous')
      // volumeBarRef.current.value = volumeLevel;
      wavesurfer.current.on('finish', () => {
        setIsPaused(true)
      })
      wavesurfer.current.on('play', () => {
        // console.log('i am playing the audio')
        setIsPaused(false)
      })
      wavesurfer.current.on('seek', () => {
        // console.log('seeked')
      })
    }
    // console.log(wavesurfer.current)

    // console.log(waveformRef);

    return () => wavesurfer.current.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.audioUrl])

  const volumeIconDesider = () => {
    if (volumeLevel >= 0.5) {
      return <VolumeUpIcon style={{ fontSize: 20 }} />
    }
    if (volumeLevel === 0) {
      return <VolumeDownIcon style={{ fontSize: 20 }} />
    }
    return <VolumeOffIcon style={{ fontSize: 20 }} />
  }

  return (
    <Row
      style={{
        // width: "100%",
        borderTop: '1px solid #f2f3f5',
        backgroundColor: 'white',
      }}
      justify="space-around"
      align="middle"
      ref={ref.current.audioPlayerDiv}
    >
      <Col span={6}>
        <div id="waveform" ref={waveformRef} style={{ display: '' }} />
        <span>{props.datetime}</span>
      </Col>
      <Col span={12}>
        <Row align="middle">
          <Col>
            <Button
              type="text"
              shape="circle"
              icon={
                isPaused ? (
                  <CaretRightOutlined style={{ fontSize: 20 }} />
                ) : (
                  <PauseOutlined style={{ fontSize: 20 }} />
                )
              }
              onClick={() => playButtonClickHandler()}
            />
          </Col>
          <Col>
            <Button
              type="text"
              shape="circle"
              icon={<ReplayIcon style={{ fontSize: 20 }} />}
              onClick={() => rewindHandler()}
            />
          </Col>
          <Col>
            <Button
              type="text"
              shape="circle"
              icon={<ForwardIcon style={{ fontSize: 20 }} />}
              onClick={() => forwardHandler()}
            />
          </Col>
          <Col>
            <Button
              type="text"
              shape="circle"
              onClick={() => volumeToggleMute()}
              onMouseEnter={() => setVolOpen(!volOpen)}
              icon={volumeIconDesider()}
            />
          </Col>
          <Col>
            <motion.div
              initial={{ originX: 0 }}
              animate={{
                scaleX: volOpen ? 1 : 0,
                display: volOpen ? '' : 'none',
              }}
              transition={{ duration: 1, type: 'spring' }}
            >
              <input
                type="range"
                min={0}
                max={1}
                step={0.1}
                ref={volumeBarRef}
                onInput={() => volumeChangeHandler()}
                value={volumeLevel}
                style={{ accentColor: '#2e9b72' }}
              />
            </motion.div>
          </Col>
          <Col>
            <span ref={currentTimeRef} style={{ marginLeft: '10px' }} />
          </Col>
        </Row>
      </Col>
      <Col span={3} className="d-flex align-items-center">
        <Button
          type="text"
          shape="circle"
          icon={<LeftOutlined style={{ fontSize: 20 }} />}
          ref={slowButton}
          onClick={() => slowDownPBR()}
        />
        <span ref={indicator} className="font-weight-bolder">
          1x
        </span>
        <Button
          type="text"
          shape="circle"
          icon={<RightOutlined style={{ fontSize: 20 }} />}
          ref={fastButton}
          onClick={() => fastPBR()}
        />
      </Col>
    </Row>
  )
})

export default TrackPlayer

TrackPlayer.propTypes = {
  duration: PropTypes.number.isRequired,
  moveMarkerWithTime: PropTypes.func.isRequired,
  audioUrl: PropTypes.string.isRequired,
  wordHighlighter: PropTypes.func.isRequired,
  datetime: PropTypes.string.isRequired,
}
