import { Row, Col } from 'antd'
import logo from 'assets/images/Logo/big-logo.svg'
import signup1 from 'assets/images/signup1.png'
import signup2 from 'assets/images/signup2.png'
import headset from 'assets/images/headset.svg'
import { JustCallForm3 } from './JustCallForm3'

function InviteJustCallTeamMembers() {
  return (
    <div className="auth-container">
      <div className="h-100">
        <Row justify="center" className="align-items-stretch h-100">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            style={{
              boxShadow: '3px 0px 65px 29px rgba(0, 0, 0, 0.05)',
              background: 'white',
              borderRight: '1px solid #e0dddd',
            }}
          >
            <Row justify="space-around" align="stretch" className="h-100">
              <Col span={20} style={{ marginBottom: 48 }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: '200px',
                    marginTop: '24px',
                  }}
                />
              </Col>
              <JustCallForm3 />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={0} lg={12} xl={12}>
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                left: '150px',
              }}
            >
              <Row>
                <div className="d-flex flex-column justify-content-center">
                  <div className="text-left">
                    <h1 style={{ marginBottom: 0, lineHeight: 1.4 }}>
                      Hone your sales skill with
                    </h1>
                  </div>
                  <div className="text-left">
                    <h1 style={{ marginBottom: 0, lineHeight: 1.4 }}>
                      Dialworks
                    </h1>
                  </div>
                  <div className="text-left" style={{ marginTop: 24 }}>
                    Get continuous coaching and personalized feedback.
                  </div>
                </div>
              </Row>
              <Row className="mt-5">
                <Col xs={0} sm={0} md={0} lg={20}>
                  <Row>
                    <Col lg={12} md={12}>
                      <img
                        src={signup1}
                        alt=""
                        style={{
                          marginBottom: '-170px',
                          marginLeft: '-10px',
                        }}
                      />
                    </Col>
                    <Col lg={12} md={12}>
                      <img
                        src={signup2}
                        alt=""
                        style={{
                          marginBottom: '-400px',
                          marginLeft: '-87px',
                        }}
                      />
                    </Col>
                    <Col lg={12} md={12}>
                      <img
                        src={headset}
                        alt=""
                        style={{ marginLeft: '58px' }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default InviteJustCallTeamMembers
