import { Layout } from 'antd'
import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant'
import { Scrollbars } from 'react-custom-scrollbars'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useGetAvailableCredits } from 'services/credit-services'
import { useLocation } from 'react-router-dom'
import MenuContent from './MenuContent'
import OnboardingStatus from '../../onboarding-components'
import AvailableCredits from './AvailableCredits'

const { Sider } = Layout

function SideNav({ routeInfo }) {
  const { isOwner, onboardingComplete, allowOnboardingFeature } = useSelector(
    (state) => state.authentication
  )
  const { data, isLoading } = useGetAvailableCredits()
  const location = useLocation()

  return (
    <Sider className="side-nav" width={SIDE_NAV_WIDTH}>
      <Scrollbars autoHide>
        <MenuContent routeInfo={routeInfo} data={data?.data} />
        {allowOnboardingFeature &&
          isOwner &&
          !onboardingComplete &&
          !location.pathname.includes('hiring') && <OnboardingStatus />}
        <AvailableCredits data={data?.data} isLoading={isLoading} />
      </Scrollbars>
    </Sider>
  )
}

export default SideNav
SideNav.propTypes = {
  routeInfo: PropTypes.object,
}
SideNav.defaultProps = { routeInfo: {} }
