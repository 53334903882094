import { Button, Layout, Tag, Tooltip } from 'antd'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useLayout } from 'contexts/LayoutContext'
import {
  useGetOnboardingStatus,
  // useGetOnboardingStepperStatus,
} from 'services/personlised-services'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import RocketIcon from '../../../assets/images/onboarding/rocketicon.svg'
import Logo from './Logo'
import NavProfile from './NavProfile'
import NavNotification from './NavNotification'

const { Header } = Layout

function CtaButton({ trialCallCampaign }) {
  return (
    <div
      style={{
        padding: '1px',
        borderRadius: '4px',
        marginRight: '10px',
        background: 'linear-gradient(91.89deg, #DB4E82 0%, #FF9777 100%)',
      }}
    >
      <Button
        style={{
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => trialCallCampaign()}
      >
        <img src={RocketIcon} style={{ marginRight: '5px' }} alt="rocketicon" />
        <span style={{ fontSize: '13px', fontWeight: '600', color: '#FF6B93' }}>
          See how training call works
        </span>
      </Button>
    </div>
  )
}
CtaButton.propTypes = {
  trialCallCampaign: PropTypes.func.isRequired,
}

export function PageHeader() {
  const history = useHistory()
  const { onboardingComplete, user } = useSelector(
    (state) => state.authentication
  )
  const { isNavCollapsed } = useLayout()
  const { data: trialCallData } = useGetOnboardingStatus()
  // const { data: stepperData } = useGetOnboardingStepperStatus()

  function trialCallCampaign() {
    return history.push(
      `${APP_PREFIX_PATH}/training/call/${trialCallData?.data?.trialCampaign.campaignId}?mapping=${trialCallData?.data?.trialCampaign.id}`
    )
  }

  const ctaButtonDecider = () => {
    if (!onboardingComplete && trialCallData?.data?.emailVerified) {
      if (!trialCallData?.data?.call) {
        if (trialCallData?.data?.trialCallLeft > 0) {
          return <CtaButton trialCallCampaign={trialCallCampaign} />
        }
      }
    }
    return undefined
  }

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <Logo isNavCollapsed={isNavCollapsed} />
        <div className="nav" style={{ width: '100%' }}>
          {/* // stepperData?.data?.steps.length === 3 || */}
          {/* // trialCallData?.data?.trialCallLeft > 0 ? ( */}
          <div className="nav-right">
            <div className="d-flex align-items-center justify-content-center">
              {ctaButtonDecider()}
              {/* {data ? (
                <Tag
                  style={{ cursor: 'default' }}
                  className="d-flex flex-column"
                >
                  {data?.data?.available > 0 ? (
                    <>
                      <div className="d-flex align-items-center">
                        <div style={{ marginRight: '3px' }}>
                          <FieldTimeOutlined />{' '}
                        </div>
                        <div>{`${data?.data?.label}`} Remaining</div>
                      </div>

                      <div style={{ fontSize: '11px', fontStyle: 'italic' }}>
                        Plan expires on{' '}
                        {moment(data?.data?.endDateTz).format('Do MMM')}
                      </div>
                    </>
                  ) : (
                    <>Your plan is expired</>
                  )}
                </Tag>
              ) : null} */}
            </div>
            {user?.mode === 2 && (
              <div>
                <Tooltip
                  title="User not allowed to make training calls"
                  placement="bottom"
                >
                  <Tag
                    color="orange"
                    style={{
                      lineHeight: '16px',
                      fontSize: 10,
                      cursor: 'pointer',
                      fontStyle: 'normal',
                      marginLeft: 8,
                    }}
                  >
                    LITE
                  </Tag>
                </Tooltip>
              </div>
            )}
            <NavNotification />
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  )
}

export default PageHeader
