import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Row, Col } from 'antd'
import { useGetTrainingCallTranscript } from 'services/iq-services'
import RightSidebar from './RightSidebar'
import CenterContainer from './CenterContainer'
import NoTranscription from './NoTranscription'
import Timeline from './Timeline'
// import LeftSidebar from './LeftSidebar'

function MainContainer() {
  const transcriptRef = useRef(null)
  const audioPlayerRef = useRef(null)
  const commentBoxRef = useRef(null)
  // this is timeline marker and timeline ref
  const markerRef = useRef(null)
  const timelineRef = useRef(null)
  const { id } = useParams()
  const { data, isLoading } = useGetTrainingCallTranscript(id)

  // this is the ratio of timeline width and duration of audio which we recieve from the CallPlayer component
  let ratioOfWidthAndDuration
  let leftFromTimeline
  let timelineDimensions

  const combinedRef = useRef({
    transcriptRef,
    audioPlayerRef,
    commentBoxRef,
    markerRef,
  })

  // combined ref of above refs
  const timeline = useRef({ markerRef, timelineRef })

  const audioCommentRef = useRef({ audioPlayerRef, commentBoxRef })
  const [searchText, setSearchText] = useState('')
  const [searchArray, setSearchArray] = useState([])
  const [centerData, setCenterData] = useState({
    transcript: [],
    audioUrl: '',
    timeset_customer: [],
    timeset_agent: [],
    agentName: 'Customer Agent',
    customerName: '',
    callDuration: 100,
    datetime: '',
  })
  // const [transcriptionDetails, setTranscriptionDetails] = useState({
  //   transcript: '',
  //   agentName: '',
  //   customerName: '',
  //   // people: [],
  // })
  // const [people, setPeople] = useState([])
  const handleClose = () => {
    setSearchText('')
  }

  // const [mentionedWords, setMentionedWords] = useState([])

  // function showNotification(type, text) {
  //   console.log('showNotification', type, text)
  // }

  // function getCompleteTranscript(d) {
  //   // console.log('came', d)
  //   // setTranscriptionDetails(d)
  //   // setPeople(d.people)
  // }

  function playRecording(playTime) {
    // console.log('test', audioPlayerRef.backend, playTime)
    audioPlayerRef.current.play(parseFloat(playTime))
  }

  function getTimeMentioned(timeArray) {
    console.log('i have the list of mentioned words', timeArray)
    // setMentionedWords(timeArray)
  }

  function setCursor(ele) {
    // Creates range object
    const setpos = document.createRange()

    // Creates object for selection
    const set = window.getSelection()
    // console.log(ele, 'ddad')

    const len = ele.childNodes.length
    const node = ele.childNodes[len - 1]

    if (node.nodeType === Node.TEXT_NODE) {
      setpos.setStart(node, node.textContent.length)
    } else {
      setpos.setStart(node, 1)
    }

    setpos.collapse(false)

    set.removeAllRanges()

    set.addRange(setpos)

    ele.focus()
  }

  const getTimeLineDimensions = () => {
    timelineDimensions = timelineRef.current.getBoundingClientRect()
    // console.log(`timeline_dimensions`,timeline_dimensions.left, timeline_dimensions.width)
    return timelineDimensions
  }

  // function for getting seconds at the position where timeline thumb is moved by dragging
  function onDragStopHandler() {
    // console.log(newPosition,e)
    // console.log("marker is dragged");
    // console.log(marker_ref.current)
    // console.log(marker_ref.current.getBoundingClientRect(),timeline_ref.current.getBoundingClientRect());

    ratioOfWidthAndDuration = 100 / centerData.callDuration
    // console.log("ratio of width and duration from drag marker",ratioOfWidthAndDuration)

    const markerDimensions = markerRef.current.getBoundingClientRect()
    // console.log(marker_dimensions.left);

    // if(e.clientX>=timeline_dimensions.right-timeline_dimensions.left){
    //     left_from_timeline=timeline_dimensions.width;
    //     audioPlayerRef.current.stop();
    // }
    // else
    getTimeLineDimensions()
    leftFromTimeline = markerDimensions.left - timelineDimensions.left
    const percent = (leftFromTimeline / timelineDimensions.width) * 100
    // console.log(percent)
    const seconds = percent / ratioOfWidthAndDuration
    // console.log(seconds)
    markerRef.current.style.left = `${ratioOfWidthAndDuration * seconds}%`
    markerRef.current.style.transform = 'translate(0px,0px)'
    audioPlayerRef.current.play(seconds)
  }

  function pauseAudioWhileDragging() {
    audioPlayerRef.current.pause()
  }

  function onStartHandler() {
    // console.log(newPosition)
    markerRef.current.style.transform = 'translate(0px,0px)'
  }

  function timelineSpanClickHandler(e) {
    // console.log(e);

    // console.log("time from span", e.target.dataset.start)

    // console.log("e.clientX and timeline left",e.clientX, timeline_dimensions.left);
    markerRef.current.style.left = `${
      ((e.clientX - timelineDimensions.left) / timelineDimensions.width) * 100
    }%`

    const seconds = parseFloat(e.target.dataset.start)
    // console.log(seconds)
    audioPlayerRef.current.play(seconds)
  }

  return (
    // <div style={{ display: 'flex', height: 'calc(100vh - 70px)' }}>
    // {/* <LeftSidebar
    //   transcriptionDetails={transcriptionDetails}
    //   ref={combinedRef}
    // /> */}
    <Row gutter={[16, 0]}>
      <Col xs={searchText !== '' ? 18 : 24}>
        <Spin spinning={isLoading}>
          {data?.data?.transcriptionDetails ? (
            <CenterContainer
              setSearchText={setSearchText}
              setSearchArray={setSearchArray}
              searchText={searchText}
              centerData={centerData}
              setCenterData={setCenterData}
              // onFetch={(d) => getCompleteTranscript(d)}
              ref={combinedRef}
              playRecording={(d) => playRecording(d)}
              transcriptionDetails={data?.data?.transcriptionDetails}
              callDetails={data?.data?.callDetails}
              // mentionedWords={mentionedWords}
            />
          ) : (
            <NoTranscription />
          )}
        </Spin>
      </Col>
      {searchText !== '' && (
        <Col xs={6}>
          <RightSidebar
            // showNotification={(x, y) => showNotification(x, y)}
            handleClose={handleClose}
            searchText={searchText}
            searchArray={searchArray}
            // people={people}
            ref={audioCommentRef}
            playRecording={playRecording}
            getTimeMentioned={getTimeMentioned}
            setCursor={setCursor}
          />
        </Col>
      )}
      {data?.data?.transcriptionDetails && (
        <Col xs={24}>
          <Timeline
            customerTime={centerData.timeset_customer}
            agentTime={centerData.timeset_agent}
            customerName={centerData.customerName}
            agentName={centerData.agentName}
            totalTime={centerData.callDuration}
            onDragStop={onDragStopHandler}
            onDrag={pauseAudioWhileDragging}
            onStart={onStartHandler}
            ref={timeline}
            getTimeLineDimensions={getTimeLineDimensions}
            spanClickHandler={timelineSpanClickHandler}
          />
        </Col>
      )}
    </Row>
    // </div>
  )
}

export default MainContainer
