/* eslint-disable no-unsafe-optional-chaining */
import { InfoCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HIRING_PAYMENT, TRAINING_PAYMENT } from 'constants/PlanConstants'

function AvailableCredits(props) {
  const { data, isLoading } = props
  const { isAdmin, isOwner } = useSelector((state) => state.authentication)

  const styles = {
    main: {
      position: 'fixed',
      textAlign: 'left',
      bottom: 16,
      left: 16,
      maxWidth: 225,
      minWidth: 225,
    },
    bg: {
      backgroundColor: '#F6F7FA',
      padding: '8px 4px',
      borderRadius: 16,
      textAlign: 'center',
    },
    text: {
      fontSize: 12,
      margin: '4px 0px',
    },
  }

  return (
    <div style={styles.main}>
      <div className="mt-2">
        <div style={styles.bg}>
          {data?.hiringEndDate && isAdmin && (
            <div style={styles.text} className="mb-2">
              Hiring credits{' '}
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <Tooltip
                    title={
                      <p style={{ fontSize: '13px', color: 'white' }}>
                        Hiring plan expire
                        {moment(data?.hiringEndDateTz).utc().isSameOrBefore()
                          ? 'd'
                          : 's'}{' '}
                        on{' '}
                        {moment(data?.hiringEndDateTz)
                          .utc()
                          .format('Do MMM YYYY')}
                      </p>
                    }
                  >
                    <InfoCircleFilled
                      style={{
                        fontSize: 13,
                        color: '#646464',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                  {isOwner && (
                    <Link
                      to={{
                        pathname: '/app/billing/buy-credits',
                        state: { activeTab: HIRING_PAYMENT },
                      }}
                      style={{ marginLeft: '5px' }}
                    >
                      (+Add)
                    </Link>
                  )}
                  {` ${data?.assessmentCandidatesCount} candidates`}
                </>
              )}
            </div>
          )}
          {data?.endDate && (
            <div style={styles.text}>
              Training credits{' '}
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <Tooltip
                    title={
                      <p style={{ fontSize: '13px', color: 'white' }}>
                        Training plan expire
                        {moment(data?.endDateTz).utc().isSameOrBefore()
                          ? 'd'
                          : 's'}{' '}
                        on {moment(data?.endDateTz).utc().format('Do MMM YYYY')}
                      </p>
                    }
                  >
                    <InfoCircleFilled
                      style={{
                        fontSize: 13,
                        color: '#646464',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                  {isOwner && (
                    <Link
                      to={{
                        pathname: '/app/billing/buy-credits',
                        state: { activeTab: TRAINING_PAYMENT },
                      }}
                      style={{ marginLeft: '5px' }}
                    >
                      (+Add)
                    </Link>
                  )}
                  {` ${
                    data?.available > 0 ? Math.floor(data?.available / 60) : 0
                  } mins`}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AvailableCredits
AvailableCredits.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
}
AvailableCredits.defaultProps = {
  data: {},
}
