import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import createCampaign from '../../assets/images/onboarding/createCampaign.svg'

function CampaignStep(props) {
  const { createdCampaign } = props
  const history = useHistory()
  return (
    <>
      <img src={createCampaign} alt="createCampaign" />
      <p className="text-left" style={{ margin: '1em 0px' }}>
        You have come this far 😇. Now create one or many campaigns for your
        team to help them train better. Get going!
      </p>
      {!createdCampaign && (
        <Button
          style={{
            backgroundColor: '#2e9b72',
            color: 'white',
            borderRadius: '4px',
          }}
          onClick={(e) => {
            e.stopPropagation()
            history.push(`${APP_PREFIX_PATH}/training/campaigns/new-campaign`)
          }}
        >
          Create campaign
        </Button>
      )}
    </>
  )
}

export default CampaignStep

CampaignStep.propTypes = {
  createdCampaign: PropTypes.bool,
}

CampaignStep.defaultProps = {
  createdCampaign: false,
}
