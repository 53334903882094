import { createSlice } from '@reduxjs/toolkit'
import { notification } from 'antd'
import utils from 'utils'

const initialState = {
  token: null,
  call: null,
  device: null,
  // twilio device
  isDeviceRegistered: false,
  isDeviceConfiguring: false,
  isDeviceConfigured: false,
  isDeviceError: false,
  deviceErrorMessage: '',
  // call
  isCallError: false,
  callErrorMessage: '',
  selectedCampaign: null,
  isCallLoading: false,
  wasCallPicked: false,
  isCallOngoing: false,
  isCallMuted: false,
  duration: null,
  wasCallIntrupted: false,
  isCallDisconnected: false,
  isCallFlowCompleted: false, // showing rating only when this happens
  callDetails: {
    dcName: null,
    dcGender: null,
  },
  // input output device
  mediaDevices: {
    currInputDeviceId: null,
    currOutputDeviceId: null,
    inputDevices: [],
    outputDevices: [],
  },
}

const callSlice = createSlice({
  name: 'call',
  initialState: initialState,
  reducers: {
    setToken: (state, action) => ({ ...state, token: action.payload }),
    setDevice: (state, action) => ({ ...state, device: action.payload }),
    deviceRegistered: (state) => ({
      ...state,
      isDeviceRegistered: true,
    }),
    deviceUnregistered: (state) => ({
      ...state,
      isDeviceRegistered: false,
    }),
    deviceConfigurationStarted: (state) => ({
      ...state,
      isDeviceConfiguring: true,
    }),
    deviceConfigured: (state) => ({
      ...state,
      isDeviceConfiguring: false,
      isDeviceConfigured: true,
    }),
    deviceError: (state, action) => ({
      ...state,
      isDeviceError: true,
      deviceErrorMessage: action.payload,
    }),
    callInitiated: (state, action) => {
      utils.hideHelpwiseWidget()
      return {
        ...state,
        isCallLoading: true,
        call: action.payload,
      }
    },
    callStarted: (state, action) => ({
      ...state,
      isCallLoading: false,
      isCallOngoing: true,
      wasCallPicked: true,
      callDetails: {
        ...state.callDetails,
        ...action.payload,
      },
    }),
    // use this when user clicks disconnect before getting connected
    callIntrupted: (state) => {
      localStorage.setItem('is-call-ongoing', false)
      return {
        ...state,
        isCallLoading: false,
        isCallDisconnected: true,
        isCallOngoing: false,
        wasCallIntrupted: true,
      }
    },
    callDisconnected: (state) => {
      localStorage.setItem('is-call-ongoing', false)
      utils.showHelpwiseWidget()
      if (state.wasCallPicked) {
        return {
          ...state,
          isCallLoading: false,
          isCallDisconnected: true,
          isCallFlowCompleted: true,
          isCallOngoing: false,
        }
      }
      if (!state.wasCallIntrupted) {
        notification.error({
          message: 'Error',
          description:
            'Sorry all our partners are busy at the moment, please try again later.',
          placement: 'bottomLeft',
        })
      }
      return {
        ...state,
        isCallLoading: false,
        isCallDisconnected: true,
        isCallOngoing: false,
      }
    },
    callError: (state, action) => ({
      ...state,
      isCallError: true,
      callErrorMessage: action.payload,
    }),
    setCampaign: (state, action) => ({
      ...state,
      selectedCampaign: action.payload,
    }),
    updateCallMuteStatus: (state, action) => ({
      ...state,
      isCallMuted: action.payload,
    }),
    setInputDeviceId: (state, action) => ({
      ...state,
      mediaDevices: {
        ...state.mediaDevices,
        currInputDeviceId: action.payload,
      },
    }),
    setOutputDeviceId: (state, action) => ({
      ...state,
      mediaDevices: {
        ...state.mediaDevices,
        currOutputDeviceId: action.payload,
      },
    }),
    setInputDevices: (state, action) => ({
      ...state,
      mediaDevices: {
        ...state.mediaDevices,
        inputDevices: action.payload,
      },
    }),
    setOutputDevices: (state, action) => ({
      ...state,
      mediaDevices: {
        ...state.mediaDevices,
        outputDevices: action.payload,
      },
    }),
    resetCallStates: (state) => ({
      token: null,
      call: null,
      device: null,
      // twilio device
      isDeviceRegistered: false,
      isDeviceConfiguring: false,
      isDeviceConfigured: false,
      isDeviceError: false,
      deviceErrorMessage: '',
      // call
      isCallError: false,
      callErrorMessage: '',
      selectedCampaign: state.selectedCampaign,
      isCallLoading: false,
      wasCallPicked: false,
      isCallOngoing: false,
      isCallMuted: false,
      duration: null,
      wasCallIntrupted: false,
      isCallDisconnected: false,
      isCallFlowCompleted: false, // showing rating only when this happens
      callDetails: {
        dcName: null,
        dcGender: null,
      },
      // input output device
      mediaDevices: {
        currInputDeviceId: null,
        currOutputDeviceId: null,
        inputDevices: [],
        outputDevices: [],
      },
    }),
  },
})

export const {
  setToken,
  setDevice,
  deviceRegistered,
  deviceUnregistered,
  deviceConfigurationStarted,
  deviceConfigured,
  deviceError,
  callInitiated,
  callStarted,
  callIntrupted,
  callDisconnected,
  callError,
  setCampaign,
  updateCallMuteStatus,
  setInputDeviceId,
  setOutputDeviceId,
  setInputDevices,
  setOutputDevices,
  resetCallStates,
} = callSlice.actions

export default callSlice.reducer
