/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { queryClient } from 'react-query-client'

const initialState = {
  user: {},
  isAdmin: localStorage.getItem('is-admin')
    ? JSON.parse(localStorage.getItem('is-admin'))
    : false,
  isOwner: false,
  accessToken: null,
  wasLoggedIn: localStorage.getItem('was-logged-in')
    ? JSON.parse(localStorage.getItem('was-logged-in'))
    : false,
  typeformCheck: false,
  allowOnboardingFeature: null,
  onboardingComplete: localStorage.getItem('onboarding')
    ? localStorage.getItem('onboarding')
    : false,
  isImpersonating: false,
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login: (state, action) => {
      const {
        user,
        accessToken,
        typeformCheck,
        allowOnboardingFeature,
        isImpersonating = false,
      } = action.payload

      const isAdmin = !!(user?.role === 0 || user?.role === 1)
      const isOwner = user?.role === 0
      localStorage.setItem('was-logged-in', true)
      localStorage.setItem('is-admin', isAdmin)
      return {
        ...state,
        isOwner,
        user: user,
        accessToken: accessToken,
        isAdmin,
        wasLoggedIn: true,
        typeformCheck,
        allowOnboardingFeature,
        isImpersonating,
      }
    },
    logout: () => {
      localStorage.clear()
      queryClient.clear()
      return initialState
    },
    updateTypeformStatus: (state, action) => ({
      ...state,
      typeformCheck: action.payload,
    }),
    updateAccessToken: (state, action) => ({
      ...state,
      accessToken: action.payload,
    }),
    updateUser: (state, action) => {
      const { user, accessToken, isImpersonating = false } = action.payload

      const isAdmin = !!(user?.role === 0 || user?.role === 1)
      const isOwner = user?.role === 0
      localStorage.setItem('was-logged-in', true)
      localStorage.setItem('is-admin', isAdmin)
      return {
        ...state,
        isOwner,
        user,
        accessToken,
        isAdmin,
        wasLoggedIn: true,
        typeformCheck: true,
        allowOnboardingFeature: false,
        isImpersonating,
      }
    },
  },
})

export const {
  login,
  logout,
  updateUser,
  updateTypeformStatus,
  updateAccessToken,
} = authSlice.actions
export const selectCurrentUser = (state) => state.authentication.user
export const selectCurrentAccessToken = (state) =>
  state.authentication.accessToken

export default authSlice.reducer
