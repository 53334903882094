import { useEffect, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import Transcript from './Transcript'

const CenterContainer = forwardRef((props, ref) => {
  const {
    transcriptionDetails,
    callDetails,
    // onFetch,
    setSearchText,
    setSearchArray,
    searchText,
    centerData,
    setCenterData,
  } = props
  console.log('center props', props)

  const { playRecording } = props

  // console.log(props.mentionedWords)

  // this ref is for the audio player which has wavesurfer
  const { audioPlayerRef, transcriptRef, commentBoxRef, markerRef } =
    ref.current
  // console.log("this is the audio ref",audioPlayerRef)

  // this ref is for audio player div
  const audioPlayerDiv = useRef(null)

  // combined refs
  const audioMarkerRef = useRef({
    audioPlayerRef,
    audioPlayerDiv,
    transcriptRef,
    markerRef,
    commentBoxRef,
  })

  // this is centre container ref
  const centreContainerRef = useRef(null)

  function scrollHandler() {
    centreContainerRef.current.classList.remove('off-scroll')
    setTimeout(() => {
      centreContainerRef.current.classList.add('off-scroll')
    }, 1200)
  }

  function moveMarker(time) {
    // console.log(time,duration);
    if (callDetails?.recordingDuration) {
      markerRef.current.style.left = `${
        (100 / callDetails.recordingDuration) * time
      }%`
    }
  }

  useEffect(() => {
    const dataFetchingfunction = () => {
      const result = []
      // const data = jsonData.transcript.transcription
      const data = transcriptionDetails?.transcription
      const lenData = data.length

      data.sort((obj1, obj2) => obj1.results[0].sno - obj2.results[0].sno)

      const customerTrack = []
      const agentTrack = []
      let monologueLength

      for (let i = 0; i < lenData; i += 1) {
        const output = []
        let monologueSentence = ''
        let j = i
        let startTime
        let endTime
        let time = data[i].results[0].alternatives[0].words[0].startTime.seconds

        const h = Math.floor(time / 3600)
        const hh = h.toString().length === 1 ? `0${h}` : `${h}`
        time -= h * 3600

        const m = Math.floor(time / 60)
        const mm = m.toString().length === 1 ? `0${m}` : `${m}`
        time -= m * 60

        const ss = time.toString().length === 1 ? `0${time}` : `${time}`

        // 1 for agent and 0 for customer
        if (data[i].results[0].track === 1) {
          for (; j < lenData && data[j].results[0].track === 1; j += 1) {
            monologueLength = 0
            startTime =
              data[j].results[0].alternatives[0].words[0].startTime.seconds +
              data[j].results[0].alternatives[0].words[0].startTime.nanos /
                1000000000

            // eslint-disable-next-line no-loop-func
            data[j].results[0].alternatives[0].words.forEach((obj) => {
              monologueLength += 1
              output.push({
                word: obj.word,
                startTime:
                  obj.startTime.seconds + obj.startTime.nanos / 1000000000,
                endTime: obj.endTime.seconds + obj.endTime.nanos / 1000000000,
              })
              monologueSentence += `${obj.word} `
            })
            endTime =
              data[j].results[0].alternatives[0].words[monologueLength - 1]
                .endTime.seconds +
              data[j].results[0].alternatives[0].words[monologueLength - 1]
                .endTime.nanos /
                1000000000
            agentTrack.push([startTime, endTime])
          }
          i = j - 1
          result.push({
            person: 'Customer Agent',
            timeStamp: `${hh}:${mm}:${ss}`,
            monologue: output,
            track: 1,
            monologueSentence: monologueSentence,
          })
        } else {
          for (; j < lenData && data[j].results[0].track === 0; j += 1) {
            monologueLength = 0
            startTime =
              data[j].results[0].alternatives[0].words[0].startTime.seconds +
              data[j].results[0].alternatives[0].words[0].startTime.nanos /
                1000000000
            // eslint-disable-next-line no-loop-func
            data[j].results[0].alternatives[0].words.forEach((obj) => {
              monologueLength += 1
              output.push({
                word: obj.word,
                startTime:
                  obj.startTime.seconds + obj.startTime.nanos / 1000000000,
                endTime: obj.endTime.seconds + obj.endTime.nanos / 1000000000,
              })
              monologueSentence += `${obj.word} `
            })
            endTime =
              data[j].results[0].alternatives[0].words[monologueLength - 1]
                .endTime.seconds +
              data[j].results[0].alternatives[0].words[monologueLength - 1]
                .endTime.nanos /
                1000000000
            customerTrack.push([startTime, endTime])
          }
          i = j - 1
          result.push({
            person: callDetails.salesRep.lName
              ? `${callDetails.salesRep.fName} ${callDetails.salesRep.lName}`
              : callDetails.salesRep.fName,
            timeStamp: `${hh}:${mm}:${ss}`,
            monologue: output,
            track: 0,
            monologueSentence: monologueSentence,
          })
        }
      }
      // console.log(jsonData.completetranscription)
      // if (transcriptionDetails && callDetails) {
      //   onFetch({
      //     transcript: transcriptionDetails.completetranscription,
      //     agentName: 'Customer Agent',
      //     customerName: callDetails.salesRep.lName
      //       ? `${callDetails.salesRep.fName} ${callDetails.salesRep.lName}`
      //       : callDetails.salesRep.fName,
      //     // people: jsonData.tributeValue,
      //   })
      // }
      // console.log(customer_track,agent_track)
      setCenterData({
        transcript: result,
        audioUrl: callDetails?.recordedUrl,
        timeset_customer: customerTrack,
        timeset_agent: agentTrack,
        agentName: 'Customer Agent',
        // agentName: jsonData.agentdetails.displayname,
        customerName: callDetails.salesRep.lName
          ? `${callDetails.salesRep.fName} ${callDetails.salesRep.lName}`
          : callDetails.salesRep.fName,
        callDuration: callDetails.recordingDuration,
        datetime: callDetails?.timeInitiated,
      })
    }
    if (transcriptionDetails) {
      dataFetchingfunction()
    }
    // console.log(centerData, 'centerData')
  }, [])

  useEffect(() => {
    const searchResultArr = []
    if (searchText !== '' && centerData.transcript.length > 0) {
      centerData.transcript.forEach((sentence) => {
        let wordindex = 0
        if (
          sentence.monologue.some((w, i) => {
            wordindex = i
            return w.word.toLowerCase().includes(searchText)
          })
        ) {
          searchResultArr.push({ ...sentence, wordIndex: wordindex })
        }
      })
    }
    setSearchArray(searchResultArr)
  }, [centerData.transcript, searchText, setSearchArray])

  // console.log(data)

  return (
    <div onScroll={scrollHandler} ref={centreContainerRef}>
      <Transcript
        transcript={centerData.transcript}
        audioUrl={centerData.audioUrl}
        customerName={centerData.customerName}
        agentName={centerData.agentName}
        moveMarkerWithTime={(x, y) => moveMarker(x, y)}
        playRecording={(d) => playRecording(d)}
        duration={centerData.callDuration}
        datetime={centerData.datetime}
        ref={audioMarkerRef}
        setSearchText={setSearchText}
        // mentionedWords={props.mentionedWords}
      />
    </div>
  )
})

export default CenterContainer
CenterContainer.propTypes = {
  playRecording: PropTypes.func.isRequired,
  callDetails: PropTypes.object.isRequired,
  transcriptionDetails: PropTypes.object.isRequired,
  // onFetch: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setSearchArray: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  centerData: PropTypes.object.isRequired,
  setCenterData: PropTypes.func.isRequired,
}
