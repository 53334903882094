/* eslint-disable no-underscore-dangle */
import { createContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Pusher from 'pusher-js/with-encryption'
import { notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useMarkNotificationAsRead } from 'services/personlised-services'
import { queryClient } from 'react-query-client'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const NotificationContext = createContext()

export function NotificationProvider({ children }) {
  const { user } = useSelector((state) => state.authentication)

  const { mutate: markAsRead } = useMarkNotificationAsRead()

  const history = useHistory()

  const pusher = useRef()

  useEffect(() => {
    if (user?.userHash) {
      console.log('LOGGEDIN ====> subscribing user', user?.userHash)

      pusher.current = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        authEndpoint: `${process.env.REACT_APP_V2_API}/sales-rep-notification/pusher/auth`,
        encrypted: true,
      })

      const channel = pusher?.current?.subscribe(
        `private-encrypted-${user?.userHash}`
      )

      channel.bind('notification', (data) => {
        queryClient.invalidateQueries('notifications')
        queryClient.invalidateQueries('infinite-notifications')
        queryClient.invalidateQueries('unread-notifications-number')

        const notificationParam = {
          placement: 'bottomLeft',
          message: data?.title ?? 'New Notification',
          description: data?.subtitle,
        }

        if (data?.actionURL) {
          notificationParam.onClick = () => {
            const url = data?.actionURL.split('/app/')

            history.push(`${APP_PREFIX_PATH}/${url[1]}`)
            markAsRead([data?._id])
          }
        } else {
          notificationParam.onClick = () => {
            markAsRead([data?._id])
          }
        }

        if (data) {
          notification.info(notificationParam)
        }
      })
    }
    return () => {
      console.log('LOGGEDOUT ====> unsubscribing user', user?.userHash)
      pusher?.current?.unsubscribe(`private-encrypted-${user?.userHash}`)
    }
  }, [history, markAsRead, user?.userHash])

  return (
    <NotificationContext.Provider value={pusher}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
