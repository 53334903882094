/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, useEffect } from 'react'
import Draggable from 'react-draggable'
import './Timeline.css'
import PropTypes from 'prop-types'

const Timeline = forwardRef((props, ref) => {
  const {
    customerName,
    agentName,
    customerTime,
    agentTime,
    spanClickHandler,
    onStart,
    onDrag,
    onDragStop,
  } = props
  // console.log('props of timeline', props)
  const duration = props.totalTime
  const ratio = 100 / duration

  // console.log(ratio);

  const { markerRef, timelineRef } = ref.current
  // let timelineDimensions

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const timelineDimensions = props.getTimeLineDimensions()
    console.log(timelineDimensions)
    // console.log(marker_ref.current.style)
    // marker_ref.current.style.left="800px"
    // console.log(timeline_ref.current.getBoundingClientRect());
  })

  return (
    <div
      className="CallPlayer"
      style={{
        border: '1px solid #f2f3f5',
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      }}
    >
      <div className="timelines" ref={timelineRef}>
        <Draggable
          axis="x"
          bounds="parent"
          onStop={onDragStop}
          onDrag={onDrag}
          onStart={onStart}
          defaultPosition={{ x: 0, y: 0 }}
          scale={2}
          nodeRef={markerRef}
          // onStop={() => props.onDragStop(ratio)}
        >
          <div className="marker" ref={markerRef}>
            <div className="marker-container">
              <div className="arrow" />
              <div className="bar" />
            </div>
          </div>
        </Draggable>
        <div className="agent">
          <p style={{ marginBottom: 6 }}>{agentName}</p>
          <div className="agent_track">
            {agentTime.map((interval) => (
              <span
                data-start={`${interval[0]}`}
                style={{
                  left: `${ratio * interval[0]}%`,
                  width: `${ratio * (interval[1] - interval[0])}%`,
                }}
                data-end={`${interval[1]}`}
                className="active_conversation agent_saying"
                onClick={(e) => spanClickHandler(e)}
              />
            ))}
          </div>
        </div>
        <div className="customer mt-2">
          <p style={{ marginBottom: 6 }}>{customerName}</p>
          <div className="customer_track">
            {customerTime.map((interval) => (
              <span
                data-start={`${interval[0]}`}
                style={{
                  left: `${ratio * interval[0]}%`,
                  width: `${ratio * (interval[1] - interval[0])}%`,
                }}
                data-end={`${interval[1]}`}
                className="active_conversation customer_saying"
                onClick={(e) => spanClickHandler(e)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Timeline
Timeline.propTypes = {
  totalTime: PropTypes.number.isRequired,
  getTimeLineDimensions: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  customerTime: PropTypes.array.isRequired,
  agentTime: PropTypes.array.isRequired,
  customerName: PropTypes.string.isRequired,
  agentName: PropTypes.string.isRequired,
  spanClickHandler: PropTypes.func.isRequired,
}
