/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import {
  Menu,
  Dropdown,
  Badge,
  Avatar,
  List,
  Button,
  Skeleton,
  Tooltip,
} from 'antd'
import {
  MailOutlined,
  BellOutlined,
  ClockCircleOutlined,
  TeamOutlined,
  PercentageOutlined,
  FieldTimeOutlined,
  ReadOutlined,
  CheckOutlined,
  PieChartOutlined,
} from '@ant-design/icons'

import {
  useMarkNotificationAsRead,
  useClearNotification,
  useFetchInfinityNotifications,
  useGetNumberOfNotifications,
} from 'services/personlised-services'
import htmr from 'htmr'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'

const WAIT_TIME_OVER = 'WAIT_TIME_OVER'
const TEAM_MEMBER_INVITED = 'TEAM_MEMBER_INVITED'
const TEAM_MEMBER_JOINED = 'TEAM_MEMBER_JOINED'
const CALL_FEEDBACK = 'CALL_FEEDBACK'
const CAMPAIGN_ABOUT_TO_EXPIRE = 'CAMPAIGN_ABOUT_TO_EXPIRE'
const CAMPAIGN_ASSIGNED = 'CAMPAIGN_ASSIGNED'
const CAMPAIGN_COMPLETE = 'CAMPAIGN_COMPLETE'
const DAILY_STATS = 'DAILY_STATS'

const getIcon = (type) => {
  switch (type) {
    case WAIT_TIME_OVER:
      return <ClockCircleOutlined />
    case TEAM_MEMBER_INVITED:
      return <TeamOutlined />
    case TEAM_MEMBER_JOINED:
      return <TeamOutlined />
    case CALL_FEEDBACK:
      return <PercentageOutlined />
    case CAMPAIGN_ABOUT_TO_EXPIRE:
      return <FieldTimeOutlined />
    case CAMPAIGN_ASSIGNED:
      return <ReadOutlined />
    case CAMPAIGN_COMPLETE:
      return <CheckOutlined />
    case DAILY_STATS:
      return <PieChartOutlined />
    default:
      return <MailOutlined />
  }
}

const getNotificationBody = ({
  notification,
  isLoading,
  markAsRead,
  setVisible,
  redirect,
}) => {
  if (notification?.data?.length > 0 || isLoading) {
    return (
      <List
        size="large"
        itemLayout="horizontal"
        dataSource={notification?.data}
        responsive
        renderItem={(item) => (
          <List.Item
            style={{
              background: item.readAt ? '' : '#b5efb7',
              cursor: item?.actionURL ? 'pointer' : 'default',
            }}
            onClick={
              item?.actionURL
                ? () => {
                    if (!item?.readAt) markAsRead([item._id])
                    if (item?.actionURL) redirect(item?.actionURL)
                    setVisible(false)
                  }
                : null
            }
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                className={`${item?.actionURL ? 'cursor-pointer' : ''}`}
                avatar={
                  <Avatar
                    className={`ant-avatar-${item.type}`}
                    icon={getIcon(item.type)}
                  />
                }
                title={htmr(item.title)}
                description={htmr(item.subtitle)}
              />
              <div
                className="d-flex flex-column align-items-end"
                style={{ marginLeft: '3px' }}
              >
                <small>
                  {moment(item.createdAtTz).utc().format('hh:mm a')}
                </small>
                <small
                  onClick={() => markAsRead([item._id])}
                  // eslint-disable-next-line prettier/prettier
                  className={`text-primary cursor-pointer ${
                    item.readAt ? 'invisible' : ''
                  }`}
                >
                  Read
                </small>
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
    )
  }

  return (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have read all notifications</p>
    </div>
  )
}

export function NavNotification() {
  const [visible, setVisible] = useState(false)

  const { data: unreadNotifications } = useGetNumberOfNotifications()

  const { mutate: markAsRead } = useMarkNotificationAsRead()

  const { mutate: clearNotifications } = useClearNotification()

  const history = useHistory()

  const { fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, data } =
    useFetchInfinityNotifications(5, true)

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  const getFlattenedData = (pages) => {
    const flattenedData = {
      data: [],
      meta: {},
    }

    if (pages) {
      pages.forEach((page) => {
        flattenedData.meta = page?.data?.meta
        flattenedData.data.push(...page?.data?.data)
      })

      return flattenedData
    }

    return null
  }

  const clearAll = () => {
    if (data) {
      const ids = []

      const flattenedData = getFlattenedData(data?.pages)

      flattenedData?.data?.forEach((fd) => {
        ids.push(fd._id)
      })
      markAsRead(ids)
      clearNotifications(ids)
    }
  }

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const redirect = (Url) => {
    const url = new URL(Url)
    let location = url.pathname
    location += `?${url.searchParams.toString()}`
    console.log({ location })
    history.push(location)
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notifications</h4>
        <Button
          className="text-primary"
          type="text"
          onClick={clearAll}
          size="small"
        >
          Mark all as read
        </Button>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody({
          notification: getFlattenedData(data?.pages),
          isLoading,
          markAsRead,
          redirect,
          setVisible,
        })}
      </div>
      {hasNextPage && (
        <div className="nav-notification-footer d-flex justify-content-center align-items-center">
          <a
            className="d-block"
            href="#/"
            onClick={isFetchingNextPage ? null : loadMore}
          >
            {isFetchingNextPage ? 'Loading...' : 'Load More'}
          </a>
        </div>
      )}
      {data?.pages ? (
        <div className="nav-notification-footer">
          <Link
            to="/app/notifications"
            onClick={() => {
              setVisible(false)
            }}
          >
            View all
          </Link>
        </div>
      ) : null}
    </div>
  )

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Tooltip title="Notifications" placement="bottom">
            <Button
              type="text"
              shape="circle"
              icon={
                <Badge count={unreadNotifications?.data?.count}>
                  <BellOutlined className="nav-icon mx-auto" />
                </Badge>
              }
            />
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

export default NavNotification
