import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

function NoTranscription() {
  const history = useHistory()
  return (
    <Result
      status="warning"
      title="Transcription is not available for this call at this moment"
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.goBack()
          }}
        >
          Back
        </Button>
      }
    />
  )
}

export default NoTranscription
