import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authentication'
import callReducer from './calling'
import preferencesReducer from './preferences'
import paymentReducer from './payment'

const store = configureStore({
  reducer: {
    authentication: authReducer,
    calling: callReducer,
    preferences: preferencesReducer,
    payments: paymentReducer,
  },
  // todo -> disable for specific actions paths
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.REACT_APP_TYPE !== 'production',
})

export const { dispatch } = store

export default store
