import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userExperiencePreferences: {
    currentCampaignPanel: 'my-campaign',
    onboardingMenuStatus: true,
    stepNumber: 1,
  },
  paymentPopups: {
    showAssessmentFeatureAd: false,
    showAssessmentExpiredPopup: false,
    showAssessmentCreditsExpiredPopup: false,
    showTrainingFeatureAd: false,
    showTrainingExpiredPopup: false,
    showTrainingCreditsExpiredPopup: false,
  },
}

export const preferenceSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    resetPreferences: () => initialState,
    setCurrentCampaignPanel: (state, action) => {
      const currState = {
        ...state,
        userExperiencePreferences: {
          ...state.userExperiencePreferences,
          currentCampaignPanel: action.payload,
        },
      }
      localStorage.setItem(
        'userExperiencePreferences',
        JSON.stringify(currState)
      )
      return currState
    },
    changeOnboardingMenuStatus: (state, action) => ({
      ...state,
      userExperiencePreferences: {
        ...state.userExperiencePreferences,
        onboardingMenuStatus: action.payload,
      },
    }),
    changeStepCount: (state, action) => ({
      ...state,
      userExperiencePreferences: {
        ...state.userExperiencePreferences,
        stepNumber: action.payload,
      },
    }),
    updateAssessmentFeatureAd: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showAssessmentFeatureAd: action.payload,
      },
    }),
    updateAssessmentExpiredPopup: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showAssessmentExpiredPopup: action.payload,
      },
    }),
    updateAssessmentCreditsExpiredPopup: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showAssessmentCreditsExpiredPopup: action.payload,
      },
    }),
    updateTrainingFeatureAd: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showTrainingFeatureAd: action.payload,
      },
    }),
    updateTrainingExpiredPopup: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showTrainingExpiredPopup: action.payload,
      },
    }),
    updateTrainingCreditsExpiredPopup: (state, action) => ({
      ...state,
      paymentPopups: {
        ...state.paymentPopups,
        showTrainingCreditsExpiredPopup: action.payload,
      },
    }),
  },
})

export const {
  resetPreferences,
  setCurrentCampaignPanel,
  changeOnboardingMenuStatus,
  changeStepCount,
  updateAssessmentFeatureAd,
  updateAssessmentExpiredPopup,
  updateAssessmentCreditsExpiredPopup,
  updateTrainingFeatureAd,
  updateTrainingExpiredPopup,
  updateTrainingCreditsExpiredPopup,
} = preferenceSlice.actions

export const selectUserExperiencePreferences = (state) =>
  state.preferences.userExperiencePreferences

export const selectPaymentPopupsState = (state) =>
  state.preferences.paymentPopups

export default preferenceSlice.reducer
