// import { Button } from 'antd'
import createAccount from '../../assets/images/onboarding/createAccount.svg'

function CreateProfileStep() {
  return (
    <>
      <img src={createAccount} alt="createAccount" />
      <p className="text-left" style={{ margin: '1em 0px' }}>
        Yayy! you have successfully signed up. Complete the onboarding
        questionaire to get going!
      </p>
      {/* <Button
        style={{
          backgroundColor: '#2e9b72',
          color: 'white',
          borderRadius: '4px',
        }}
        onClick={(e) => {
          e.stopPropagation()
          window.open('/onboarding', '_blank')
        }}
      >
        Take me There!
      </Button> */}
    </>
  )
}

export default CreateProfileStep
