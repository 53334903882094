import { Button } from 'antd'
import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant'
import { useSelector } from 'react-redux'
import { RightOutlined, CheckCircleFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import PropTypes from 'prop-types'
import TrialCallButton from './TrialCallButton'

function OnboardingSideCard(props) {
  const {
    callsLeft,
    trialCallCampaign,
    isLoading,
    isStepperLoading,
    showDrawer,
    setPanelState,
    stepperData,
    data,
  } = props
  const { typeformCheck } = useSelector((state) => state.authentication)

  const history = useHistory()
  return (
    <div>
      {typeformCheck && !isLoading && !isStepperLoading ? (
        <div
          className="pt-3"
          style={{
            width: SIDE_NAV_WIDTH - 20,
            marginLeft: '10px',
            marginTop: '50px',
            marginBottom: '150px',
            textAlign: 'center',
            boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08)',
            border: '1px solid #E3E6EB',
            borderRadius: '8px',
          }}
        >
          <p
            className="text-left"
            style={{
              margin: '0px 10px 10px',
              fontWeight: '700',
              color: '#203251',
            }}
          >
            {stepperData?.steps?.length === 3
              ? `Yayy! you are now ready to have your first training call.`
              : `🚀 We will be ready for you in 2 business days. We promise!`}
          </p>
          <Button
            className="mb-4"
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0px auto',
            }}
            icon={
              <CheckCircleFilled
                style={{
                  color: '#44C13C',
                  fontSize: '18px',
                }}
              />
            }
            onClick={() => {
              showDrawer()
              setPanelState('fillForm')
            }}
          >
            {stepperData?.steps?.slice(-1)[0]?.title} <RightOutlined />
          </Button>

          {stepperData?.steps.length === 3 ? (
            <Button
              disabled={stepperData?.steps?.length !== 3 || !data.campaign}
              style={{
                backgroundColor:
                  stepperData?.steps?.length !== 3 || !data.campaign
                    ? '#E3E6EB'
                    : '#2e9b72',
                color: 'white',
                borderRadius: '4px',
                margin: '-10px 0px 10px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                history.push(`${APP_PREFIX_PATH}/training/campaigns`)
              }}
            >
              Make your first call
            </Button>
          ) : (
            <div
              className="p-2"
              style={{
                background: 'rgba(245, 245, 247, 0.57)',
                borderRadius: '8px',
                // marginBottom: "10px",
              }}
            >
              {callsLeft !== 0 ? (
                <h3 style={{ marginTop: '10px' }}>Hate waiting?</h3>
              ) : null}
              <TrialCallButton
                callsLeft={callsLeft}
                trialCampaignId={data?.trialCampaign?.id}
                enableTextBelow
                trialCallCampaign={() => trialCallCampaign()}
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default OnboardingSideCard
OnboardingSideCard.propTypes = {
  callsLeft: PropTypes.number.isRequired,
  trialCallCampaign: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isStepperLoading: PropTypes.bool.isRequired,
  showDrawer: PropTypes.func.isRequired,
  setPanelState: PropTypes.func.isRequired,
  stepperData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}
