import SideNav from 'components/layout-components/SideNav'
import Loading from 'components/shared-components/Loading'
import PageHeader from 'components/layout-components/Header'
import AppViews from 'views/app-views'

import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'

import navigationConfig from 'configs/NavigationConfig'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import utils from 'utils'

import { useLayout } from 'contexts/LayoutContext'
import { useSelector } from 'react-redux'

import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from 'constants/ThemeConstant'

import Banners from './Banners'

const { Content } = Layout

function AppLayout() {
  const location = useLocation()

  const isFullScreen =
    location.pathname.includes('email-not-verified') ||
    location.pathname.includes('onboarding')

  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  )
  const { isNavCollapsed } = useLayout()
  const { status } = useThemeSwitcher()
  const { isCallOngoing, isCallLoading } = useSelector((state) => state.calling)

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  const getLayoutGutter = () =>
    isNavCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH

  const getLayoutDirectionGutter = () => {
    if (isCallOngoing || isCallLoading) {
      return { paddingLeft: 0 }
    }
    return {
      paddingLeft: getLayoutGutter(),
    }
  }
  const getAppContentPadding = () => {
    if (location.pathname.includes('jciq')) {
      return {
        padding: '0px',
      }
    }
    return null
  }

  if (isFullScreen) {
    return (
      <div className="auth-container">
        <AppViews />
      </div>
    )
  }

  return (
    <Layout>
      <PageHeader
        display={currentRouteInfo?.breadcrumb}
        title={currentRouteInfo?.title}
      />
      <Layout className="app-container">
        {isCallOngoing || isCallLoading ? null : (
          <SideNav
            routeInfo={currentRouteInfo}
            hideRoutes={isCallLoading || isCallOngoing}
          />
        )}

        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className="app-content" style={getAppContentPadding()}>
            <Content>
              <Banners />
              <AppViews />
            </Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AppLayout
