/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
import moment from 'moment-timezone'
import business from 'moment-business'

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    const initials = name.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree
    }
    let route
    for (const p in navTree) {
      if (
        Object.prototype.hasOwnProperty(navTree, p) &&
        typeof navTree[p] === 'object'
      ) {
        route = this.getRouteInfo(navTree[p], path)
        if (route) {
          return route
        }
      }
    }
    return route
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return 'dark'
    }
    function cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h
    }
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16)
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16)
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16)
    }
    const threshold = 130
    const hRed = hexToR(hex)
    const hGreen = hexToG(hex)
    const hBlue = hexToB(hex)

    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
    if (cBrightness > threshold) {
      return 'dark'
    }
    return 'light'
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16)
    let G = parseInt(color.substring(3, 5), 16)
    let B = parseInt(color.substring(5, 7), 16)
    R = parseInt((R * (100 + percent)) / 100, 10)
    G = parseInt((G * (100 + percent)) / 100, 10)
    B = parseInt((B * (100 + percent)) / 100, 10)
    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)
    return `#${RR}${GG}${BB}`
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, '')
    const inParts = rgba.substring(rgba.indexOf('(')).split(',')
    const r = parseInt(trim(inParts[0].substring(1)), 10)
    const g = parseInt(trim(inParts[1]), 10)
    const b = parseInt(trim(inParts[2]), 10)
    const a = parseFloat(
      trim(inParts[3].substring(0, inParts[3].length - 1))
    ).toFixed(2)
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ]

    outParts.forEach((part, i) => {
      if (part.length === 1) {
        outParts[i] = `0${part}`
      }
    })
    return `#${outParts.join('')}`
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive
    }
    if (number < 0) {
      return negative
    }
    return null
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key]
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      const aTemp = a[key].toLowerCase()
      const bTemp = b[key].toLowerCase()
      if (aTemp > bTemp) {
        return -1
      }
      if (bTemp > aTemp) {
        return 1
      }
      return 0
    }
    return null
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list
    if (list) {
      data = list.filter((item) => item[key] === value)
    }
    return data
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list
    if (list) {
      data = list.filter((item) => item[key] !== value)
    }
    return data
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (const key in item) {
        // if (item[key] == null) {
        //   continue
        // }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true
        }
      }
    }
    const filteredList = list.filter((value) => searchText(value))
    return filteredList
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    const breakpoints = []
    for (const key in screens) {
      // var hasProperty-
      if (Object.prototype.hasOwnProperty(screens, key)) {
        const element = screens[key]
        if (element) {
          breakpoints.push(key)
        }
      }
    }
    return breakpoints
  }

  /**
   * Get Date Format for api calls
   * @param {Date}
   * @return {Date} Formatted date format to be use in api calls.
   */

  static getNoiceDateFormat(date) {
    const strDate = String(date)
    const month = strDate.slice(4, 7)
    const dateNum = strDate.slice(8, 10)
    const year = strDate.slice(13, 15)
    let extra
    if (dateNum[1] === '1') {
      extra = 'st'
    }
    if (dateNum[1] === '2') {
      extra = 'nd'
    }
    if (dateNum[1] === '3') {
      extra = 'rd'
    }
    extra = 'th'

    return `${dateNum}${extra} ${month} ${year}`
  }

  static convertDateTime(newmongoDate) {
    const mongoDate = new Date(newmongoDate)
    const time = mongoDate.toLocaleTimeString('en', {
      timeStyle: 'short',
      hour12: true,
      timeZone: 'IST',
    })
    const year = mongoDate.getFullYear()
    const monthNumber = mongoDate.getMonth() + 1
    const newdate = mongoDate.getDate()
    let month

    switch (monthNumber) {
      case 1:
        month = 'Jan'
        break
      case 2:
        month = 'Feb'
        break
      case 3:
        month = 'Mar'
        break
      case 4:
        month = 'Apr'
        break
      case 5:
        month = 'May'
        break
      case 6:
        month = 'Jun'
        break
      case 7:
        month = 'Jul'
        break
      case 8:
        month = 'Aug'
        break
      case 9:
        month = 'Sep'
        break
      case 10:
        month = 'Oct'
        break
      case 11:
        month = 'Nov'
        break
      case 12:
        month = 'Dec'
        break
      default:
        month = 'none'
    }

    return ` ${time}, ${newdate} ${month} ${year}`
  }

  /**
   * Get whether the current time is valid for calls or not.
   */

  static isTimeValid(companyId) {
    // check for weekends

    if (companyId === 1) return true
    if (process.env.REACT_APP_TYPE === 'development') return true
    if (business.isWeekendDay(moment().tz('America/New_York'))) {
      return false
    }
    const format = 'HH:mm:ss'
    // check for weekends

    const est = moment().tz('America/New_York').format(format)
    const currentTime = moment(est, format)
    const startTime = moment('09:00:00', format)
    const endTime = moment('18:00:00', format)

    return currentTime.isBetween(startTime, endTime)
  }

  static isHoliday() {
    const format = 'DD/MM/YYYY'
    const currentTime = moment().tz('America/New_York')
    const startTime = moment('27/12/2022', format).tz('America/New_York')
    const endTime = moment('31/12/2022', format).tz('America/New_York')
    return currentTime.isBetween(startTime, endTime)
  }

  static showHelpwiseWidget() {
    const x = document.getElementById('helpwise-widget-launcher')
    if (x) {
      x.style.display = 'block'
    }
  }

  static hideHelpwiseWidget() {
    const x = document.getElementById('helpwise-widget-launcher')
    if (x) {
      x.style.display = 'none'
    }
  }

  static openHelpwiseWidget() {
    const x = document.getElementById('helpwise-widget-launcher')
    if (x) {
      x.click()
    }
  }
}

export default Utils
