import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import PropTypes from 'prop-types'
import moment from 'moment'
import firstCall from '../../assets/images/onboarding/firstCall.svg'
import TrialCallButton from './TrialCallButton'

function FirstCallStep(props) {
  const {
    fillForm,
    stepperDataLength,
    createdCampaign,
    callsLeft,
    trialCampaignId,
    trialCallCampaign,
    stepperData,
  } = props
  const history = useHistory()
  const mainContent = () => {
    if (!fillForm) {
      return 'You will be able to make training calls 2 business days after completing the onboarding questionnaire.'
    }
    return stepperDataLength === 3 ? (
      'Yayy! you are now ready to have your first training call. Our agent are all prepped up 🚀'
    ) : (
      <>
        Yayy! You will be ready to make your first call in just{' '}
        <strong>2 business days</strong> from the date of form submitted{' '}
        <strong>
          ({moment(stepperData?.steps[0]?.date).utc().format('MMM DD')}).
        </strong>{' '}
        Our agents are all getting prepped up 🚀
      </>
    )
  }

  return (
    <>
      <img src={firstCall} alt="firstCall" />
      <p className="text-left" style={{ margin: '1em 0px' }}>
        {mainContent()}
      </p>

      {stepperDataLength === 3 && fillForm ? (
        <Button
          disabled={stepperDataLength !== 3 || !createdCampaign}
          style={{
            backgroundColor:
              stepperDataLength !== 3 || !createdCampaign
                ? '#E3E6EB'
                : '#2e9b72',
            color: 'white',
            borderRadius: '4px',
            margin: '10px 0px',
          }}
          onClick={(e) => {
            e.stopPropagation()
            history.push(`${APP_PREFIX_PATH}/training/campaigns`)
          }}
        >
          Make your first call
        </Button>
      ) : (
        <TrialCallButton
          callsLeft={callsLeft}
          trialCampaignId={trialCampaignId}
          enableTextBelow
          trialCallCampaign={() => trialCallCampaign()}
        />
      )}
    </>
  )
}

export default FirstCallStep
FirstCallStep.propTypes = {
  stepperDataLength: PropTypes.number,
  createdCampaign: PropTypes.bool,
  fillForm: PropTypes.bool,
  callsLeft: PropTypes.number,
  trialCampaignId: PropTypes.number,
  trialCallCampaign: PropTypes.func.isRequired,
  stepperData: PropTypes.array,
}
FirstCallStep.defaultProps = {
  stepperDataLength: 0,
  fillForm: false,
  createdCampaign: false,
  trialCampaignId: 0,
  callsLeft: 5,
  stepperData: [],
}
