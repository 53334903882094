import InvoiceViews from 'views/invoice-view'
import Loading from 'components/shared-components/Loading'
import { useThemeSwitcher } from 'react-css-theme-switcher'

function InvoiceLayout() {
  const { status } = useThemeSwitcher()

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  return (
    <div className="auth-container">
      <InvoiceViews />
    </div>
  )
}

export default InvoiceLayout
