import request from 'auth/FetchInterceptor'
import { useQuery } from 'react-query'

const getTrainingCallTranscript = (id) =>
  request({
    url: `${process.env.REACT_APP_V2_API}/transcription/training/${id}`,
  })

export const useGetTrainingCallTranscript = (id) =>
  useQuery(
    ['training-call-transcript', id],
    () => getTrainingCallTranscript(id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
