// import { useResendVerificationMail } from 'services/auth-services'
// import { useSelector } from 'react-redux'
// import { Button } from 'antd'
// import PropTypes from 'prop-types'
import verifyEmail from '../../assets/images/onboarding/verifyEmail.svg'

function EmailVerfiedStep() {
  // const { emailVerified } = props
  // const { email } = useSelector((state) => state.authentication.user)
  // const { mutate: resendEmail, isLoading: resendEmailLoading } =
  // useResendVerificationMail()
  return (
    <>
      <img src={verifyEmail} alt="verifyEmail" />
      <p className="text-left" style={{ margin: '1em 0px' }}>
        Please check your email and click on the link provided.
        {/* If you */}
        {/* haven&apos;t received the email, click on the button. */}
      </p>
      {/* <Button
        loading={resendEmailLoading}
        disabled={!!emailVerified}
        style={{
          color: 'white',
          borderRadius: '4px',
          backgroundColor: emailVerified ? '#E3E6EB' : '#2e9b72',
        }}
        onClick={(e) => {
          e.stopPropagation()
          resendEmail({ email })
        }}
      >
        Send Verification link
      </Button> */}
    </>
  )
}

export default EmailVerfiedStep
EmailVerfiedStep.propTypes = {
  // emailVerified: PropTypes.bool,
}
EmailVerfiedStep.defaultProps = {
  emailVerified: false,
}
